import { Text } from '@chakra-ui/react';
import React from 'react';
interface TextMessageProps {
  message: string;
}

export const TextMessage: React.FC<TextMessageProps> = ({ message }) => {
  const texto = message;
  const regexLinks = /https?:\/\/\S+/gi;
  const fixText = texto.split(regexLinks);

  const links = texto.match(regexLinks) || [];

  return (
    <Text w={{ base: '120px', sm: '300px', md: '400px' }}>
      {fixText.map((text, index) => {
        const link = links[index];
        return (
          <React.Fragment key={index}>
            {text}
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            )}
          </React.Fragment>
        );
      })}
    </Text>
  );
};
