import { useTranslation } from 'react-i18next';
import { ActivityVolunteer } from '../../../../../interfaces/activity';

interface props {
  data: ActivityVolunteer[];
  loading: boolean;
}

const Volunteers = ({ data, loading }: props) => {
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <span className="mt-5">Buscando...</span>
      ) : (
        <>
          {!data.length ? (
            <p className="mt-5">{t('activities.activity_teamNull')}</p>
          ) : (
            <ul className="list-group">
              {data.map((user, index) => (
                <li key={`user-${index}`} className="list-group-item">
                  {user.name}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default Volunteers;
