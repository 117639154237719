import { useState } from 'react';
import styled from 'styled-components';
import { apiCall } from '../../../../../crud/api.crud';
import Loading from '../../../../molecules/Loading';
import TextEditor from './TextEditor';

const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  padding-top: 3rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.5s ease-out;
`;

const MainModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.08);
  width: 500px;
`;

type PropsModalNewCard = {
  setShowModal: (x: any) => void;
};

const CommunityModal = ({ setShowModal }: PropsModalNewCard) => {
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [photoFile, setPhotoFile] = useState<{ data }>();
  const [photoName, setName] = useState();
  const [loading, setLoading] = useState(false);

  // const formData = new FormData();
  // const reader = new FileReader();

  // const onPhotoFileChange = (e) => {
  //     if (e.target.files[0]) {
  //         reader.readAsDataURL(e.target.files[0]);
  //     }
  //         reader.onload = (readerEvent) => {
  //             if(readerEvent.target){
  //                 const asd = readerEvent.target.result?.toString();
  //                 formData.append("image", asd ?? "");
  //             }
  //     };
  //     console.log(e.target.files[0].name, formData., 'data');
  // };

  const onPhotoFileChange = (e) => {
    setPhotoFile({ data: e.target.files[0] });
    setName(e.target.files[0].name);
  };

  const save = async () => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('short_description', shortDescription);
    formData.append('long_description', longDescription);
    if (photoFile) {
      formData.append('photo_path', photoFile.data, photoFile.data.name);
    }

    setLoading(true);
    await apiCall(`community`, formData, 'POST');

    setShowModal(false);
  };

  return (
    <Overlay>
      {loading ? (
        <Loading />
      ) : (
        <MainModal>
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Crear Novedad</h4>
                <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="message-text bold " className="col-form-label text-dark">
                    Titulo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />

                  <label htmlFor="message-text " className="col-form-label text-dark">
                    Descripción corta
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setShortDescription(e.target.value)}
                    value={shortDescription}
                  />

                  <label htmlFor="message-text " className="col-form-label text-dark">
                    Descripción larga
                  </label>
                  {/* @ts-ignore */}
                  <TextEditor longDescription={longDescription} setLongDescription={setLongDescription} />
                  <div className="custom-file mt-2">
                    <input
                      type="file"
                      className="custom-file-input"
                      accept="image/png, image/jpeg"
                      onChange={onPhotoFileChange}
                    />
                    <label className="custom-file-label col-form-label" htmlFor="inputGroupFile01">
                      {photoName ? photoName : 'Cargar foto'}
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button type="button" className="btn btn-primary" onClick={save} disabled={!title.length}>
                  Publicar
                </button>
              </div>
            </div>
          </div>
        </MainModal>
      )}
    </Overlay>
  );
};

export default CommunityModal;
