import Guide from 'modules/classroom/components/tabs/Guide';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { isAquiEstoy } from 'utils/contants';
import config from '../../../../../config';
import { apiCall } from '../../../../../crud/api.crud';
import { Activity, ActivityVolunteer } from '../../../../../interfaces';
import useStore from '../../../../../store/zustand/store';
import { ActionsLog, AddVolunteer, Description, Recordings, Volunteers } from './index';

interface props {
  activity: Activity;
}

const Span = styled.span`
  font-size: 0.8rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

/**
 * 2 = Seleccionado
 * 5 = Culminado
 * 7 = Certificado
 */
const showTabWithStatus = [2, 5, 7, 8];

interface Tab {
  id: number;
  title: string;
  content: JSX.Element;
  show: boolean;
}

const Tabs = ({ activity }: props) => {
  const [data, setData] = useState<ActivityVolunteer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasRecordings, setHasRecordings] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<Tab | undefined>(undefined);
  const { t } = useTranslation();
  const user = useStore((state) => state.user);
  // Get tab for params example: ?tab=guide
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('tab');

  useEffect(() => {
    if (activity.id) {
      apiCall(`activity/${activity.id}/volunteersAccepted`, null, 'GET')
        .then((response) => {
          setData(response.data.data);
        })
        .finally(() => setLoading(false));

      apiCall(`activity/${activity.id}/recordings`, null, 'GET').then(({ data }) => {
        setHasRecordings(!!data.length);
      });
    }
  }, []);

  // Validar si el estado del usuario en la actividad es 2, 5 o 7
  const isSelected = useMemo(() => showTabWithStatus.includes(+activity.activity_user_status), [activity]);
  const hasGuide = useMemo(() => !!activity.guide_id, [activity]);

  const allTabs = useMemo(
    () => [
      {
        id: 1,
        title: 'activities.description',
        content: <Description activity={activity} />,
        show: !config.profile_globant,
      },
      {
        id: 2,
        title: 'activities.team',
        content: <Volunteers data={data} loading={loading} />,
        show: !hasGuide && isSelected,
      },
      {
        id: 3,
        title: 'activities.actions_dates',
        content: <ActionsLog data={data} loading={loading} />,
        show: !hasGuide && isSelected,
      },
      {
        id: 4,
        title: isAquiEstoy ? 'activities.formation' : 'activities.guide',
        content: <Guide activity_id={activity.id} registered={isSelected} />,
        show: hasGuide,
      },
      {
        id: 5,
        title: 'activities.recordings',
        content: <Recordings activity_id={activity.id} />,
        show: isSelected && !!config.activities?.tabs?.recording && hasRecordings,
      },
      {
        id: 6,
        title: 'activities.add_volunteer',
        content: <AddVolunteer activityId={activity.id} />,
        show: !config.exception && user?.role_name === 'user-articulator',
      },
    ],
    [isSelected, activity, data, user, hasRecordings],
  );

  useEffect(() => {
    const guideTab = allTabs.find(({ title }) => title === 'activities.guide' || title === 'activities.formation');
    if (isAquiEstoy && guideTab) {
      setCurrentTab(guideTab);
    } else {
      setCurrentTab(allTabs.find(({ title }) => title === `activities.${tab}`) || allTabs[0]);
    }
  }, [allTabs, tab]);

  if (!currentTab) return null;

  return (
    <div>
      <ul className={`nav ${!config.profile_globant && 'nav-tabs'}`}>
        {allTabs
          .filter((tab) => tab.show)
          .map((tab) => (
            <li key={`navitem-${tab.id}`} className="nav-item">
              <Span className={`nav-link ${currentTab.id === tab.id && 'active'}`} onClick={() => setCurrentTab(tab)}>
                {t(tab.title)}
              </Span>
            </li>
          ))}
      </ul>
      <div className="tab-content">{currentTab?.content}</div>
    </div>
  );
};

export default React.memo(Tabs);
