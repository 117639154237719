import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import useBoolean from '../../../../hooks/useBoolean';
import useStore from '../../../../store/zustand/store';
import styled from 'styled-components';
import { useOutsideClick } from '@chakra-ui/react';

const LoginModal = lazy(() => import('../../../shared/LoginModal'));

const Ul = styled.ul`
  left: auto;
  right: 0px;
`;

export const AuthMenu = () => {
  const { t } = useTranslation();
  const user = useStore((state) => state.user);
  const logout = useStore((state) => state.logout);
  const location = useLocation();

  const fullName = useMemo(() => {
    if (!user) return '';
    if (user.surname?.length) return `${user.name} ${user.surname}`;

    return user.name;
  }, [user]);

  const { isOpen, onToggle, setIsOpen, onClose } = useBoolean();
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => user && onClose(),
  });

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <div className="position-relative" ref={ref}>
      <button
        type="button"
        data-toggle="dropdown"
        className="btn dropdown-toggle d-flex align-items-center newStylePerfil p-0"
        onClick={onToggle}
      >
        <BsPerson className="mr-1" size={22} />
        <span className="d-none d-lg-block"> {user ? fullName : t('auth.login')}</span>
        <span className="caret" />
      </button>
      {user && (
        <Ul className={`dropdown-menu rounded position-absolute ${isOpen && 'show'} p-2`}>
          <li className="my-2">
            <Link className="item-menu" to="/management">
              {t('header.header_msg4')}
            </Link>
          </li>
          <li className="my-2">
            <Link className="item-menu" to="/profile">
              {t('header.header_msg5')}
            </Link>
          </li>
          <hr className="m-0" />
          <li className="my-2">
            <span className="item-menu" onClick={logout}>
              {t('header.header_msg6')}
            </span>
          </li>
        </Ul>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {!user && <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      </Suspense>
    </div>
  );
};
