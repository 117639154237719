import moment from 'moment';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { apiCall } from '../crud/api.crud';
import { ActivityRoom, Admins, Message, Room } from '../interfaces';
import useStore, { User } from '../store/zustand/store';
import { scrollToBottomAnimated } from '../utils/helper';

interface ContextProps {
  client?: WebSocket;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  activities: ActivityRoom[];
  rooms: Room[];
  messages: Message[];
  isConnected: boolean;
  currentRoom?: Room;
  selectRoom: (ActivityId: number) => void;
  handleRoomMessages: (messages: Message[]) => void;
  userAdmins: Admins[];
}

const timeout = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const SocketContext = createContext({} as ContextProps);

interface Props {
  children: ReactNode;
}

export const SocketProvider: FC<Props> = ({ children }) => {
  const user = useStore((state) => state.user);
  const [activities, setActivities] = useState<ActivityRoom[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentRoom, setCurrentRoom] = useState<Room>();
  const [client, setClient] = useState<WebSocket>();
  const [isConnected, setIsConnected] = useState(false);
  const [userAdmins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(true)
  useEffect(() => {
    // setTimeout(() =>  setLoading(false), 3000)
  }, []);
  useEffect(() => {
    const getAdmins = async () => {
      const { data } = await apiCall(`admins`, null, 'GET');
      setAdmins(data.data);
    };
    getAdmins();
  }, []);

  const createClient = useCallback((user: User) => {
    const socket = new WebSocket(`wss:/chat.fonselp.com/ws?name=${user.name}&email=${user.email}&roleId=1`);
    setClient(socket);
  }, []);

  //Get Activities and Set Rooms
  useEffect(() => {
    const getActivities = async () => {
      const { data } = await apiCall(
        `user/${
          user!.id
        }/activities?sort=-deadline&filter[activities.status]=1,2&page[number]=${page}&filter[entity_origin_id]=${
          process.env.REACT_APP_ID_ENTITY
        }`,
        null,
        'GET',
      );

      setRooms([]);
      const rooms = data.data.map((activity) => ({
        value: `activity-${activity.id}`,
        title: activity.title,
        image: !!activity?.description_image && `https://app.fonselp.com/storage/${activity?.description_image}`,
        user_id: activity.user_id,
        id: activity.id,
      }));
      setActivities(rooms);
    };
    if (user) {
      getActivities();
    }
    return () => {
      setActivities([]);
    };
  }, [user, page]);

  //Create and delete client websocket
  useEffect(() => {
    if (!client && user) {
      createClient(user);
    }

    return () => {
      console.log('close connection');
      setIsConnected(false);
      client?.close();
      setRooms([]);
    };
  }, [client, user]);

  // Manage WebSocket
  useEffect(() => {
    if (client) {
      client.onopen = () => {
        console.log('WebSocket Client Connected');
        setIsConnected(true);
      };

      client.onmessage = (message) => {
        const convertStringToObject = (value: any) => JSON.parse(value.split(/\r?\n/)[0]);

        const { action, target, ...data } = convertStringToObject(message.data);
        if (action == 'room-joined') {
          setRooms((prev) => [...prev, { ...target, messages: [] }]);
        }

        if (action == 'send-message') {
          const message = {
            room_id: target.id,
            user: data.sender ? { name: data.sender.name, email: data.sender.email } : null,
            message: data.message,
            sendAt: data.send_at,
          };

          if (data.sender && (!currentRoom || target.id !== currentRoom.id)) {
            newGlobalMessage(message);
            return;
          }

          if (!currentRoom) return;

          setCurrentRoom((prev) => {
            let prevMessages = prev?.messages || [];

            const sorted = [...prevMessages, message].sort(function (left, right) {
              return moment.utc(right.sendAt).diff(moment.utc(left.sendAt));
            });

            return {
              ...currentRoom!,
              messages: sorted,
            };
          });
          scrollToBottomAnimated('scrollableDiv');
        }
      };
    }
  }, [client, currentRoom]);

  //Join to room
  useEffect(() => {
    const joinRoom = async () => {
      for (const room of activities) {
        await timeout(150);
        client!.send(JSON.stringify({ action: 'join-room', message: room.value }));
      }
    };
    if (activities && isConnected) {
      joinRoom();
    }
  }, [isConnected, activities]);

  const selectRoom = (activityId: number) => {
    const roomName = `activity-${activityId}`;
    const room = rooms.find((room) => room.name === roomName);

    if (!room) throw new Error('Room not found');

    setCurrentRoom(room);
  };

  const handleRoomMessages = (messages: Message[]) =>
    setCurrentRoom((prev) => {
      let prevMessages = prev?.messages || [];

      const sorted = [...prevMessages, ...messages].sort(function (left, right) {
        return moment.utc(right.sendAt).diff(moment.utc(left.sendAt));
      });

      return {
        ...currentRoom!,
        messages: sorted,
      };
    });

  const newGlobalMessage = (message: Message) => setMessages((prev) => [...prev, message]);

  return (
    <SocketContext.Provider
      value={{
        client,
        setPage,
        page,
        rooms,
        activities,
        messages,
        isConnected,
        currentRoom,
        selectRoom,
        handleRoomMessages,
        userAdmins,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('SocketContext must be used within a SocketProvider');
  }

  return context;
};
