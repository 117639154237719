import { useEffect, useState } from 'react';
import { apiCall } from '../crud/api.crud';

export type BannerType = {
  created_at: string;
  entity_id: number;
  id: number;
  order: string;
  redirect: string;
  updated_at: string;
  url: string;
};

export const useBanners = (): BannerType[] => {
  const [banners, setBanners] = useState<BannerType[]>([]);

  useEffect(() => {
    const getBanners = async () => {
      const { data } = await apiCall(`banners`, null, 'GET');
      setBanners(
        data?.data
          .filter((banner) => banner.url)
          .map((banner) => {
            const separator = banner.url.split('/');
            if (separator.includes('drive.google.com') && separator.includes('file')) {
              const fileId = separator.find((word) => word.length == 33);
              if (fileId) {
                // banner.url = `https://drive.google.com/uc?export=view&id=${fileId}`;
                banner.url = `https://lh3.googleusercontent.com/d/${fileId}`;
              }
            }
            return banner;
          })
          .sort((a, b) => {
            return a.order - b.order;
          }) || [],
      );
    };

    getBanners();
  }, []);

  return banners;
};
