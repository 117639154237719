import { apiCall } from 'crud/api.crud';
import { Guide, Quiz, Resource } from 'interfaces/guide.interface';
import { GuideSkeleton } from 'modules/classroom/components/GuideSkeleton';
import { EmptyGuide } from 'modules/classroom/pages/EmptyGuide';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

interface ContextProps {
  guide: Guide;
  updateResource: (resourceId: Resource['id'], updatedResourceData: Partial<Resource>) => void;
  updateQuiz: (quizId: Quiz['id'], updateQuizData: Partial<Quiz>) => void;
}

const GuideContext = createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export const GuideProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [guide, setGuide] = useState<Guide>();
  const {
    id, // activityId
  } = useParams<{ id: string }>();

  useEffect(() => {
    apiCall(`activity/${id}/guide`, null, 'GET')
      .then(({ data }) => {
        setGuide(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // Check progress
  useEffect(() => {
    if (!guide) return;
    // const { topics } = guide;

    // const lessons = topics?.flatMap((t) => t.chapters).flatMap((c) => c.lessons);

    // const resources = lessons?.flatMap((l) => l.resources);

    // const resourcesCompleted = resources?.every((resource) => resource.completed_at);
    // const quizzesApproved = lessons?.every(({ quiz }) =>
    //   quiz ? Number(quiz?.answer?.score) >= Number(config.classroom?.min_approved_score) : true,
    // );

    // if (resourcesCompleted || quizzesApproved) {
    apiCall(`activity/${id}/guide/check`, null, 'GET');
    // }
  }, [guide]);

  const updateResource = (resourceId: Resource['id'], updatedResourceData: Partial<Resource>) => {
    if (!guide) return;
    const updatedGuide = {
      ...guide,
      topics: guide.topics?.map((topic) => ({
        ...topic,
        chapters: topic.chapters.map((chapter) => ({
          ...chapter,
          lessons: chapter.lessons.map((lesson) => ({
            ...lesson,
            resources: lesson.resources.map((resource) =>
              resource.id === resourceId ? { ...resource, ...updatedResourceData } : resource,
            ),
          })),
        })),
      })),
    };
    setGuide(updatedGuide);
  };

  const updateQuiz = (quizId: Quiz['id'], updateQuizData: Partial<Quiz>) => {
    if (!guide) return;
    const updatedGuide = {
      ...guide,
      topics: guide.topics?.map((topic) => ({
        ...topic,
        chapters: topic.chapters.map((chapter) => ({
          ...chapter,
          lessons: chapter.lessons.map((lesson) => {
            return lesson.quiz?.id === quizId ? { ...lesson, quiz: { ...lesson.quiz, ...updateQuizData } } : lesson;
          }),
        })),
      })),
    };

    setGuide(updatedGuide);
  };

  if (loading) return <GuideSkeleton />;

  if (!guide) return <EmptyGuide />;

  return <GuideContext.Provider value={{ guide, updateQuiz, updateResource }}>{children}</GuideContext.Provider>;
};

export const useGuideContext = () => {
  const context = useContext(GuideContext);
  if (context === undefined) {
    throw new Error('GuideContext must be used within a GuideProvider');
  }

  return context;
};
