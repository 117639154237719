import { Grid, GridItem, Skeleton } from '@chakra-ui/react';

export function GuideSkeleton() {
  return (
    <Grid
      templateColumns="repeat(7,1fr)"
      minH="85vh"
      gap={4}
      px={{
        base: 4,
        lg: 8,
      }}
    >
      <GridItem
        colSpan={2}
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <Skeleton h="full" />
      </GridItem>

      <GridItem
        colSpan={{
          base: 7,
          lg: 5,
        }}
      >
        <Skeleton h="full" />
      </GridItem>
    </Grid>
  );
}
