import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

interface Data {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  limit?: number;
}

export const SimplePagination: React.FC<Data> = ({ page, setPage, total, limit = 30 }) => {
  const nextPage = () => {
    setPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <div className="d-flex justify-content-center w-100 align-items-center" style={{ gap: 10 }}>
      <button disabled={page <= 1} className="btn btn-sm btn-primary" onClick={() => setPage(1)}>
        <FiChevronsLeft size={18} />
      </button>
      <button disabled={page <= 1} className="btn btn-sm btn-primary" onClick={prevPage}>
        <FiChevronLeft size={18} />
      </button>
      <span className="btn btn-sm">{page}</span>
      <button disabled={total < limit} className="btn btn-sm btn-primary" onClick={nextPage}>
        <FiChevronRight size={18} />
      </button>
      <button disabled={total < limit} className="btn btn-sm btn-primary" onClick={() => setPage((prev) => prev + 2)}>
        <FiChevronsRight size={18} />
      </button>
    </div>
  );
};
