import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import './i18n/config';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'chakra/theme';

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ChakraProvider theme={theme} resetCSS={false}>
      <App store={store} />
    </ChakraProvider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;
