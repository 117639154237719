import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Guide as GuideType } from 'interfaces/guide.interface';
import { apiCall } from 'crud/api.crud';
import { Lesson } from 'modules/classroom/components/tabs/Lesson';
import useGetUserId from 'hooks/api/useGetUserId';
import { Progress } from 'modules/classroom/components/tabs/Progress';

interface CourseDetailsProps {
  registered?: boolean;
  activity_id: number;
}

const createArray = (cantidad: number): number[] => {
  return Array.from({ length: cantidad }, (_, i) => i);
};

export default function Guide({ registered = false, activity_id }: CourseDetailsProps) {
  const [guide, setGuide] = useState<GuideType | null>(null);
  const userId = useGetUserId();

  useEffect(() => {
    apiCall(`activity/${activity_id}/guide`, null, 'GET')
      .then((res) => {
        setGuide(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [activity_id, userId]);

  if (!guide) return null;

  const { topics = [] } = guide;

  return (
    <Stack spacing={8} pt={8}>
      <Progress guide={guide} />
      <Accordion defaultIndex={createArray(topics.length)} allowMultiple>
        {topics?.map((topic) => (
          <AccordionItem key={topic.id} my={2}>
            <AccordionButton border="1px solid" borderColor="gray.300">
              <Box flex="1" textAlign="left">
                <Text>{topic.title}</Text>
                <Text as="span" fontSize="sm" color="gray">
                  {topic.description}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {topic.chapters?.map((chapter) => (
                <Accordion defaultIndex={createArray(topic.chapters.length)} key={chapter.id} allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text>{chapter.title}</Text>
                        <Text as="span" fontSize="sm" color="gray">
                          {chapter.description}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Stack spacing={8}>
                        {chapter.lessons?.map((lesson) => (
                          <Lesson key={lesson.id} lesson={lesson} activity_id={activity_id} registered={registered} />
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
}
