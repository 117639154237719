import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { apiCall } from '../../../crud/api.crud';
import useGetUserId from '../../../hooks/api/useGetUserId';
import ActivityButton from '../../shared/ActivityButton';
import Detail from '../Detail';
import config from '../../../config';
import { ButtonPrimary } from '../Button';

const Card = styled.div`
  background-color: #ffffff;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  margin-bottom: 10px;

  hr {
    border: 2px solid #c4c4c4;
    border-radius: 5px;
  }

  .strong {
    font-weight: bold;
    margin: 0 5px 5px 0;
  }
  h3 {
    font-size: 20px;
  }

  &.globant {
    height: 494px;
    position: relative;
  }
`;
const Container = styled.div`
  padding: 25px;

  &.globant {
    height: 323px;
    display: flex;
    flex-direction: column;
  }
`;
const Img = styled.img`
  width: 100%;
  border-radius: 15px;

  &.globant {
    height: 128px;
    border-radius: 10px 10px 0px 0px;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  &.globant {
    align-items: flex-start;
  }
`;
const GlobantHr = styled.hr`
  border: 0.75px solid #b1b1b1 !important;
`;
const Footer = styled.div`
  background-color: #f7f7f7;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;

  &.globant {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: Hug (46px) px;
    padding: 17px 0px;
  }
`;
const ButtonDonation = styled.a`
  background: #ff4450;
  &:focus {
    border: none;
    outline: none;
  }

  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  color: #fffdfd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  //box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateY(-3px);
    text-decoration: none;
    color: #fffdfd;
  }
  &:active {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    transform: translateY(3px);
  }
  &:focus {
    outline: none;
  }
`;
const GlobantH3 = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 22px;
  font-weight: 400;
  line-height: 25.3px;
  text-align: left;
  height: 50px;
`;
const GlobantP = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  height: 87px;
  margin: 0px;
  color: #5b5b5b;
`;

const ActivitiesCard = ({ activity }) => {
  const { t } = useTranslation();
  const userId = useGetUserId();
  const handleClick = () => {
    apiCall(
      `interestedLink`,
      {
        activity_id: activity.id,
        user_id: userId,
      },
      'POST',
    ).finally(() => {
      window.open(activity.url_donar, '_blank');
    });
  };

  const now = moment().subtract(1, 'days');
  const isEnable = useMemo(() => {
    if (now <= moment(activity.deadline) || activity.continuous_activity) return true;
    return false;
  }, [activity]);
  // useGetUserId;

  return (
    <Card className={config.profile_globant ? 'globant' : ''}>
      {activity.description_image && (
        <Img
          className={config.profile_globant ? 'globant card-img-top' : 'card-img-top'}
          alt="img de actividad"
          src={`https://app.fonselp.com/storage/${activity.description_image}`}
        />
      )}
      <Container className={config.profile_globant ? 'globant' : ''}>
        <Info className={config.profile_globant ? 'globant' : ''}>
          {config.profile_globant ? <GlobantH3>{activity.title}</GlobantH3> : <h3>{activity.title}</h3>}
          {config.profile_globant ? (
            <GlobantP>{activity.short_description}</GlobantP>
          ) : (
            <p>{activity.short_description}</p>
          )}
          {!config.profile_globant && (
            <Link className="more-reactour" to={`detail/${activity.id}`} style={{ alignSelf: 'flex-end' }}>
              {t('button.button_msg5')}
            </Link>
          )}
        </Info>
        {config.profile_globant ? <GlobantHr /> : <hr />}
        <Detail activity={activity} />
      </Container>
      <Footer className={config.profile_globant ? 'globant' : ''}>
        {config.profile_globant && isEnable ? (
          <ButtonPrimary style={{ padding: '10px 0px' }} className={'button-reactour'}>
            <Link
              style={{ textDecoration: 'none', color: 'inherit', padding: '10px 30px' }}
              to={`/detail/${activity.id}`}
            >
              {t('button.button_msg9')}
            </Link>
          </ButtonPrimary>
        ) : (
          <ActivityButton activity={activity} />
        )}
        {!config.profile_globant && <span style={{ margin: '6px' }}></span>}
        {activity.url_donar && (
          <ButtonDonation onClick={handleClick}>{activity.url_donar_name || t('home.card_msg5')}</ButtonDonation>
        )}
      </Footer>
    </Card>
  );
};

export default ActivitiesCard;
