import { useState } from 'react';

import { Marker as MarkerNpm, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Activity, Location } from 'interfaces';

interface MarkerProps {
  location: Location;
  activity: Activity;
}

export const ActivityMarker = ({ location, activity }: MarkerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const openInfo = () => {
    setOpen(!open);
  };

  return (
    <MarkerNpm
      key={location.id}
      position={{
        lat: parseFloat(location.location_lat),
        lng: parseFloat(location.location_lng),
      }}
      title={activity.title}
      onClick={openInfo}
    >
      {open && (
        <InfoWindow>
          <div id="content">
            <div id="siteNotice" />
            <h1 id="firstHeading" className="firstHeading" style={{ fontSize: '1.2rem' }}>
              {activity?.title}
            </h1>
            <div id="bodyContent">
              <p>
                <Link to={`/detail/${activity?.id}`}>{t('button.button_msg5')}</Link>
              </p>
            </div>
          </div>
        </InfoWindow>
      )}
    </MarkerNpm>
  );
};
