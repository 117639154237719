import ReactQuill,{Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';

// const insertYouTube = () => {
//   const url = prompt('Ingrese la URL de YouTube');
//   if (url) {
//     const iframe = `<iframe width="560" height="315" src="${url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
//     const quill = Quill.find(document.querySelector('.ql-editor'));
//     const range = quill.getSelection();
//     quill.clipboard.dangerouslyPasteHTML(range.index, iframe);
//   }
// };

// Quill.register('modules/insertYouTube', function (quill, options) {
//   const toolbar = quill.getModule('toolbar');
//   toolbar.addHandler('insertYouTube', insertYouTube);
// });

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'code','video'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
  'video'
];

type Props = {
  placeholder: string;
  setLongDescription: (x: any) => { void };
  longDescription: string;
};

const TextEditor = ({ placeholder, longDescription, setLongDescription }: Props) => {
  return (
    <>
      <ReactQuill
        theme="snow"
        value={longDescription}
        modules={modules}
        formats={formats}
        onChange={(e) => setLongDescription(e)}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextEditor;
