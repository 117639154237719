import { GoogleMap } from '@react-google-maps/api';
import { ActivityMarker } from 'components/map/ActivityMarker';
import { UserMarker } from 'components/map/UserMarker';
import config from 'config';
import { apiCall } from 'crud/api.crud';
import { Activity, Location } from 'interfaces';
import { useEffect, useState } from 'react';

interface MapProps {
  activities?: Activity[];
  users: Location[];
}

export function Map({ activities, users = [] }: MapProps) {
  if (!activities) {
    return null;
  }
  return (
    <GoogleMap
      zoom={config.location_zoom}
      center={{
        lat: config.location_lat || 19.432608,
        lng: config.location_lng || -99.133209,
      }}
      mapContainerStyle={{ height: '250px' }}
    >
      {activities?.map((activity) =>
        activity.locations.map((location) => (
          <ActivityMarker key={`marker-${location.id}`} location={location} activity={activity} />
        )),
      )}
      {users.map((location) => (
        <UserMarker key={`user-marker-${location.id}`} location={location} />
      ))}
    </GoogleMap>
  );
}
