import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BsSearch, BsXCircle } from 'react-icons/bs';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
  .input-group {
    width: 100%;
  }
  .btn,
  input:focus {
    border: none !important;
    box-shadow: none !important;
  }
  @media (min-width: 940px) {
    .input-group {
      max-width: 350px;
    }
  }
`;

interface props {
  filterText: string;
  onFilter: (e) => void;
  onClear: () => void;
}

const TableSearch = ({ filterText, onFilter, onClear }: props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="input-group border rounded-lg">
        <div className="input-group-append">
          <button className="btn" type="button" id="button-addon2" onClick={onClear}>
            {filterText.length ? <BsXCircle /> : <BsSearch />}
          </button>
        </div>
        <input
          type="text"
          id="search"
          placeholder={t('activities.activities_msg16')}
          value={filterText}
          onChange={onFilter}
          className="form-control border-0"
          autoComplete="false"
        />
      </div>
    </Container>
  );
};

export default TableSearch;
