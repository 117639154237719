import { useTranslation } from 'react-i18next';
import Form from './AddHours/Form';

const AddHoursModal = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{t('hours.hours_msg16')}</h5>
        <button type="button" className="close" onClick={closeModal} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form closeModal={closeModal} />
      </div>
    </div>
  );
};

export default AddHoursModal;
