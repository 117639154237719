import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Filter } from '../../../../../interfaces/activity';
import Select from '../../../../molecules/Select';

const Container = styled.div`
  width: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  color: #2f3c44;
  font-size: 15px;
  padding: 0;
  button {
    font-size: 12px;
  }
  @media (min-width: 940px) {
    & {
      flex-direction: row;
    }
    button {
      font-size: 1rem;
    }
  }
`;

const generateUri = (object) => {
  const esc = encodeURIComponent;
  return Object.keys(object)
    .filter((k) => object[k])
    .map((k) => esc(k) + '=' + esc(object[k]))
    .join('&');
};

const history = createBrowserHistory();

interface props {
  filter: Filter;
  setFilter: (filter: Filter) => void;
}

interface options {
  value: number;
  label: string;
}

const ActivityFilter = ({ filter, setFilter }: props) => {
  const { t } = useTranslation();

  const optionsState: options[] = [
    { value: 0, label: t('activities.status_msg5') },
    { value: 1, label: t('activities.status_msg6') },
    { value: 2, label: t('activities.status_msg8') },
    { value: 3, label: t('activities.status_msg9') },
  ];
  const optionPostulationState: options[] = [
    { value: 0, label: t('activities.status_msg5') },
    { value: 1, label: t('activities.status_msg2') },
    { value: 2, label: t('activities.status_msg3') },
    { value: 3, label: t('activities.status_msg4') },
    { value: 4, label: t('activities.status_msg') },
    { value: 7, label: t('activities.certificate') },
  ];
  useEffect(() => {
    history.push('/actividad?' + generateUri(filter));
    localStorage.setItem('activity_filter', JSON.stringify(filter));
  }, [filter]);

  const handleChange = (option, name) => {
    setFilter({ ...filter, [name]: option.value });
  };

  return (
    <Container>
      {/* input status */}
      <div className="w-100 mx-2">
        <label htmlFor="status" className="form-label">
          {t('activities.activities_msg2')}
        </label>
        <Select
          options={optionsState}
          name="status"
          handleChange={handleChange}
          initial={optionsState.findIndex((opt) => opt.value === filter.status)}
        />
      </div>
      {/* input status_postulation */}
      <div className="w-100 mx-2">
        <label htmlFor="status_postulation" className="form-label">
          {t('activities.activities_msg3')}
        </label>
        <Select
          options={optionPostulationState}
          handleChange={handleChange}
          name="status_postulation"
          initial={optionPostulationState.findIndex((opt) => opt.value === filter.status_postulation)}
        />
      </div>
    </Container>
  );
};

export default ActivityFilter;
