import { Icon, Stack, Text } from '@chakra-ui/react';
import { CourseEnd } from 'modules/classroom/components/CourseEnd';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useGuide } from 'modules/classroom/hooks/useGuide';
import { useTranslation } from 'react-i18next';
import { RiVideoLine } from 'react-icons/ri';

export function NotFound() {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const { hasEnded } = useGuide();

  if (hasEnded) return <CourseEnd />;

  return (
    <Stack align="center" justify="center" minH="75vh">
      <Icon as={RiVideoLine} width={20} height={20} />
      <Text>{t('empty')}</Text>
    </Stack>
  );
}
