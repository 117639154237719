import { Container, Grid, GridItem, Heading } from '@chakra-ui/react';
import { SimplePagination } from 'components/SimplePagination';
import { useSocketContext } from '../../../context/SocketContext';
import { Sidebar } from '../components/Sidebar';
import { SidebartItem } from '../components/SidebartItem';

const LIMIT = 30;

interface ChatLayoutProps {
  children: React.ReactNode;
}

export function ChatLayout({ children }: ChatLayoutProps) {
  const { activities, setPage, page } = useSocketContext();

  return (
    <Container gap={2} mt={2} maxW="8xl">
      <Heading size="lg" pl={8} display={{ base: 'none', lg: 'block' }}>
        Chat
      </Heading>
      <Grid templateColumns={{ base: '1fr', lg: '400px 3fr' }} gap={4} px={{ base: 0, md: 8 }}>
        <Sidebar activities={activities} />
        <GridItem
          p={4}
          display={{ base: 'none', lg: 'block' }}
          bg="white"
          borderColor="gray.200"
          boxShadow="lg"
          rounded="lg"
          maxH="75vh"
          overflowY="scroll"
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
              height: '10px',
              background: '#E3E6EA',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#C5CAD2',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#AEB3BA',
            },
          }}
        >
          {activities?.map((activity) => (
            <SidebartItem key={activity.id} activity={activity} />
          ))}

          {activities.length >= LIMIT && <SimplePagination total={activities.length} page={page} setPage={setPage} />}
        </GridItem>
        <GridItem bg="white" borderColor="gray.200" boxShadow="lg" p={4} rounded="lg" maxH="75vh">
          {children}
        </GridItem>
      </Grid>
    </Container>
  );
}
