import config from '../config';
import { Datum } from '../crud/Types/LoginUser';

type Field = { name: string; field: string };

const fields: Field[] = [
  {
    name: 'Fecha de nacimiento',
    field: 'birthday',
  },
  {
    name: 'Género',
    field: 'gender',
  },
  {
    name: 'Tipo de identificación',
    field: 'id_type',
  },
  {
    name: 'Número de identificación',
    field: 'id_number',
  },
  {
    name: 'Estado civil',
    field: 'marital_status',
  },
  {
    name: 'Número de teléfono',
    field: 'phone_number',
  },
  {
    name: 'Linkedin',
    field: 'linkedin_url',
  },
];

const isCessi = Number(process.env.REACT_APP_ID_ENTITY) === 6911;

export const checkUser = (user: Datum): string[] => {
  if (isCessi) {
    fields.push({
      name: 'Localidad',
      field: 'geolocation_cessi_id',
    });
  }

  const noCheck = process.env.REACT_APP_ID_ENTITY === '71' ? ['birthday', 'phone_number'] : [];
  if (!user || !user.volunteeringFields) return [];
  const invalidFields: string[] = [];
  const supervielle = config.exception;

  fields.forEach((field) => {
    if (!user.volunteeringFields[field.field] && !noCheck.includes(field.field)) {
      invalidFields.push(field.name);
    }
  });

  return supervielle ? invalidFields.filter((field) => field.toLowerCase() !== 'linkedin') : invalidFields;
};
