import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import config from '../../../../../config';
import { apiCall } from '../../../../../crud/api.crud';
import useStore from '../../../../../store/zustand/store';

interface emailOption {
  label: string;
  value: string;
}

const entityID = process.env.REACT_APP_ID_ENTITY;

interface Props {
  activityId: number;
}

export const AddVolunteer: FC<Props> = ({ activityId }) => {
  const [emailList, setEmailList] = useState<emailOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<emailOption[]>([]);
  const [status, setStatus] = useState<'success' | 'error'>();
  const user = useStore((state) => state.user);

  useEffect(() => {
    const getData = async () => {
      const { data } = await apiCall(`dependentUsers/${entityID}/${user!.id}`, {}, 'GET');
      setOptions(data.data.map((item) => ({ label: item.email, value: item.id })));
    };

    getData();
  }, []);

  const onSubmit = async (e) => {
    setStatus(undefined);
    setIsLoading(true);
    e.preventDefault();
    const dependent_users = emailList.map((item) => item.value);
    try {
      await apiCall(
        'activity/postulate-dependent',
        {
          user_id: user?.id,
          activity_id: activityId,
          dependent_users,
        },
        'POST',
      );
      setStatus('success');
    } catch (error) {
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className="mt-4">
        <div className="row">
          <label htmlFor="email" className="col-12">
            Email
          </label>
          <Select options={options} onChange={setEmailList} isMulti className="col-12" />
          {status && (
            <div className={`alert col-12 mt-4 ${status === 'error' ? 'alert-warning' : 'alert-success'}`} role="alert">
              {status === 'error' ? 'Hubo un error al agregar los voluntarios' : 'Voluntarios agregados exitosamente.'}
            </div>
          )}
          <button type="submit" className="btn btn-primary w-100 mt-2" disabled={!emailList.length || isLoading}>
            {isLoading ? 'Cargando...' : 'Agregar a la actividad'}
          </button>
        </div>
      </form>
    </>
  );
};
