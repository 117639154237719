import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOsmia } from 'utils/contants';
import { apiCall } from '../../../../../crud/api.crud';
import useGetUserId from '../../../../../hooks/api/useGetUserId';
import { ButtonNotApply } from '../../../../molecules/Button';
import Modal from '../../../Modal';
import Loading from '../Loading/Loading';

const UnsubscribeButton = ({ activity, setIsSuscribed }) => {
  const { title, id } = activity;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const userId = useGetUserId();
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const save = async () => {
    setIsLoading(false);
    const payload = {
      user_id: userId,
      activity_id: id,
    };
    setIsLoading(true);
    await apiCall('activity/despostulate', payload, 'POST').then(() => {
      setIsOpen(false);
      setIsSuscribed(false);
    });
    setIsLoading(false);
  };
  return (
    <>
      <Unsubscribe openModal={openModal} />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading && (
          <div className="modal-body">
            <Loading />
          </div>
        )}
        {isLoading || (
          <div>
            <div className="modal-header">
              <h5 className="modal-title">{`${t('hours.unapply_title')} ${title}`}</h5>
            </div>
            <div className="modal-body">
              {' '}
              {t('hours.unapply_msg')}
              <span className="font-weight-bold ml-2">{title}</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal} data-dismiss="modal">
                {t('button.button_msg8')}
              </button>
              <button type="button" className="btn btn-primary" onClick={save}>
                {t('hours.unapply_msg1')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

const Unsubscribe = ({ openModal }) => {
  const { t } = useTranslation();
  return (
    <ButtonNotApply onClick={openModal} type="button" className="button-reactour">
      {isOsmia ? t('button.unenroll') : t('button.button_msg4')}
    </ButtonNotApply>
  );
};

export default UnsubscribeButton;
