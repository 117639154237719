const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;
  return (
    <div
      className="modal d-flex justify-content-center align-items-center"
      style={{
        backdropFilter: 'blur(5px)',
      }}
    >
      <div className="modal-dialog w-100 ">
        <div className="modal-content" style={{ borderRadius: '20px' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
