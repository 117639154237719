import { Dispatch, SetStateAction, useCallback, useState } from 'react';

interface UseBooleanOutput {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

function useBoolean(defaultValue?: boolean): UseBooleanOutput {
  const [isOpen, setIsOpen] = useState(!!defaultValue);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((x) => !x), []);

  return { isOpen, setIsOpen, onOpen, onClose, onToggle };
}

export default useBoolean;
