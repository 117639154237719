import LaSegundaSeguros from './107';
import Mediapila from './1284/1284';
import Transformate from './149';
import Apex from './155';
import Naves from './176/176';
import DonarOnline from './2055';
import Baufest from './22/22';
import CivicHouse from './2241/2241';
import Fonselp from './226/226';
import Honana from './2293';
import Caritas from './2387/2387';
import GrupoMexico from './2621/2621';
import MonteDePiedad from './2647';
import Idealist from './2741';
import Ada from './3221/3221';
import Apaer from './326';
import PontenciarSolidario from './3403';
import ManosAbiertas from './343';
import EducacionConsciente from './3612';
import MadreEmprededora from './3766';
import Bisblick from './3885/3885';
import VamosAZoomar from './4161/4161';
import PactoEmprendedor from './4193/4193';
import Osmia from './4755';
import ArgentinaNarrada from './492';
import Sellin from './5665';
import FundacionDeLio from './5848/5848';
import MovimientoNuevaGeneracion from './5878';
import GrupoSupervielle from './5933/5933';
import AfricaDream from './5948/5948';
import Simbiosis from './6189';
import Empujar from './633/633';
import Aquiestoy from './6341';
import SoyArquitecta from './6357';
import ChileVoluntario from './6498';
import JCIMendoza from './6552';
import TodoSuma from './6622';
import WorkerTech from './6703';
import Comunalia from './6745';
import Lap from './6746';
import Cessi from './6911';
import Nippi from './6912';
import Globant from './71/71';
import Insprirar from './7340';
import Auge from './7718';
import Bocar from './7836';
import Paradiplomacia from './7851';
import Plugit from './7859';
import Valos from './8107';
import FundacionAzteca from './8158';
import ArcosDorados from './8399';
import Nfkraut from './8470';
import BamxMorelos from './8499';
import Almacivica from './8601';
import Devlyn from './8658';
import Polis from './8660';
import Involucrate from './8694';
import Enlazando from './8777';
import Nodo from './8800';
import NoCoders from './8810';
import AcademiaBA from './8836';
import SnvColombia from './8878';
import SomosCodeis from './9049';
import CasaRonaldPeru from './9100';
import Base from './base/base.config';

const config = () => {
  const entityConfig = process.env.REACT_APP_ID_ENTITY;

  const base = Base;

  switch (entityConfig) {
    case '226':
      return { ...base, ...Fonselp };
    case '633':
      return { ...base, ...Empujar };
    case '3221':
      return { ...base, ...Ada };
    case '176':
      return { ...base, ...Naves };
    case '4161':
      return { ...base, ...VamosAZoomar };
    case '22':
      return { ...base, ...Baufest };
    case '2241':
      return { ...base, ...CivicHouse };
    case '3885':
      return { ...base, ...Bisblick };
    case '1284':
      return { ...base, ...Mediapila };
    case '2387':
      return { ...base, ...Caritas };
    //Incubate
    case '4193':
      return { ...base, ...PactoEmprendedor };
    case '71':
      return { ...base, ...Globant };
    case '343':
      return { ...base, ...ManosAbiertas };
    case '3403':
      return { ...base, ...PontenciarSolidario };
    case '492':
      return { ...base, ...ArgentinaNarrada };
    case '3766':
      return { ...base, ...MadreEmprededora };
    case '5878':
      return { ...base, ...MovimientoNuevaGeneracion };
    case '2621':
      return { ...base, ...GrupoMexico };
    case '5933':
      return { ...base, ...GrupoSupervielle };
    case '5848':
      return { ...base, ...FundacionDeLio };
    case '5948':
      return { ...base, ...AfricaDream };
    case '2741':
      return { ...base, ...Idealist };
    case '326':
      return { ...base, ...Apaer };
    case '6189':
      return { ...base, ...Simbiosis };
    case '6357':
      return { ...base, ...SoyArquitecta };
    case '155':
      return { ...base, ...Apex };
    case '2293':
      return { ...base, ...Honana };
    case '6498':
      return { ...base, ...ChileVoluntario };
    case '6552':
      return { ...base, ...JCIMendoza };
    case '6622':
      return { ...base, ...TodoSuma };
    case '6703':
      return { ...base, ...WorkerTech };
    case '6745':
      return { ...base, ...Comunalia };
    case '6746':
      return { ...base, ...Lap };
    case '6911':
      return { ...base, ...Cessi };
    case '6912':
      return { ...base, ...Nippi };
    case '6341':
      return { ...base, ...Aquiestoy };
    case '7340':
      return { ...base, ...Insprirar };
    case '107':
      return { ...base, ...LaSegundaSeguros };
    case '7836':
      return { ...base, ...Bocar };
    case '7859':
      return { ...base, ...Plugit };
    case '7851':
      return { ...base, ...Paradiplomacia };
    case '8107':
      return { ...base, ...Valos };
    case '8399':
      return { ...base, ...ArcosDorados };
    case '8470':
      return { ...base, ...Nfkraut };
    case '8601':
      return { ...base, ...Almacivica };
    case '8499':
      return { ...base, ...BamxMorelos };
    case '8660':
      return { ...base, ...Polis };
    case '8658':
      return { ...base, ...Devlyn };
    case '2055':
      return { ...base, ...DonarOnline };
    case '8694':
      return { ...base, ...Involucrate };
    case '8800':
      return { ...base, ...Nodo };
    case '8810':
      return { ...base, ...NoCoders };
    case '8836':
      return { ...base, ...AcademiaBA };
    case '8158':
      return { ...base, ...FundacionAzteca };
    case '8777':
      return { ...base, ...Enlazando };
    case '149':
      return { ...base, ...Transformate };
    case '8878':
      return { ...base, ...SnvColombia };
    case '4755':
      return { ...base, ...Osmia };
    case '5665':
      return { ...base, ...Sellin };
    case '3612':
      return { ...base, ...EducacionConsciente };
    case '2647':
      return { ...base, ...MonteDePiedad };
    case '9049':
      return { ...base, ...SomosCodeis };
    case '7718':
      return { ...base, ...Auge };
    case '9100':
      return { ...base, ...CasaRonaldPeru };
    default:
      return base;
  }
};

export default config();
