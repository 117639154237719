const createUpdateUser = (set) => ({
  updateUser: (payload) => {
    set((state) => ({
      user: {
        ...state.user,
        ...payload,
      },
    }));
  },
});

export default createUpdateUser;
