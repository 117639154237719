import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CustomDateInput, CustomTextarea } from './CustomInputs';

import useGetUserId from '../../../hooks/api/useGetUserId';

import { apiCall } from '../../../crud/api.crud';
import './forms.scss';

const HoursForm = ({ closeModal, activity_id }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    hours: 0,
    minutes: 0,
    createdOn: new Date().toJSON().slice(0, 10),
    commentary: '',
    canChargeHours: true,
  });
  const [formsSend, setFormsSend] = useState([]);

  const setAnswers = (name) => (value) => {
    setFormsSend({ ...formsSend, [name]: value });
  };

  const user_id = useGetUserId();

  useEffect(() => {
    apiCall(`activity/${activity_id}`, null, 'GET').then((response) => {
      console.log(response.data.data[0]);
      const estimated_hours = response.data.data[0].estimated_hours;
      const counterAutomatic = response.data.data[0].automate_hours_calculation;
      setInitialValues({
        ...initialValues,
        hours: estimated_hours,
        canChargeHours: estimated_hours !== 0,
      });
    });
  }, []);

  const addHours = (payload) => {
    setLoading(true);
    const data = apiCall('activityHours', payload, 'POST');
    setLoading(false);
  };

  const increment = () => {
    setCount(count + 1);
    setInitialValues({ ...initialValues, hours: count + 1 });
  };

  const decrement = () => {
    if (count <= 0) {
      return;
    }
    setCount(count - 1);
    setInitialValues({ ...initialValues, hours: count - 1 });
  };
  //
  const validationForm = Yup.object({
    minutes: Yup.number().typeError('teee').oneOf([0, 15, 30, 45], 'Opción invalida').required('Requeridosss'),
    createdOn: Yup.date('Debe ingresar una fecha válida').required('Requerido').nullable(),
    commentary: Yup.string('')
      .min(3, 'Agregar comentario')
      .max(300, 'Comentario demasiado extenso')
      .required('Requerido')
      .nullable(),
  });

  const createPayload = (values) => ({
    user_id,
    activity_id,
    hours: values.hours + values.minutes / 60,
    activity_day: values.createdOn,
    upload_type: process.env.REACT_APP_ID_ENTITY,
    commentary: values.commentary,
    formsSend: formsSend || null,
  });

  // const hoursOptions = [0, 15, 30, 45];
  // const options = hoursOptions.map((o, i) => <option key={i}>{o}</option>);

  // console.log(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationForm}
      onSubmit={(values, { setSubmitting }) => {
        const payload = createPayload(values);
        setTimeout(() => {
          addHours(payload);
          setSubmitting(loading);
          closeModal();
        }, 2000);
      }}
      enableReinitialize
    >
      {({ values, handleSubmit, handleChange, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-row">
              {!initialValues.canChargeHours && (
                <div className="col col-md-4">
                  <label htmlFor="horas">{t('hours.hours_msg4')}:</label>
                  <div className="form-row">
                    <div>
                      <button type="button" className="btn btn-primary form_buttons" onClick={decrement}>
                        -
                      </button>
                    </div>
                    <div className="col-sm-5">
                      <Field
                        type="number"
                        name="hours"
                        min={1}
                        className="form-control"
                        value={values.hours}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <button type="button" className="btn btn-primary form_buttons" onClick={increment}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col col-md-2">
                            <label htmlFor="minutes">Minutos:</label>
                            <Field
                                as="select"
                                className="form-control"
                                name="minutes"
                                onChange={handleChange}
                            >
                                {options}
                            </Field>
                        </div> */}

              <Field
                label={t('home.filter_msg8')}
                name="createdOn"
                component={CustomDateInput}
                value={values.createdOn}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <Field
              label={t('home.home_msg4')}
              name="commentary"
              component={CustomTextarea}
              onChange={handleChange}
              value={values.commentary}
            />
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal} data-dismiss="modal">
              {t('button.button_msg8')}
            </button>
            <button type="submit" className="btn btn-primary">
              {isSubmitting ? t('button.button_msg6') : t('button.button_msg11')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HoursForm;
