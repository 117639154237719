import chat from './chat.json';
import activities from './activities.json';
import auth from './auth.json';
import button from './button.json';
import tour from './common.json';
import header from './header.json';
import home from './home.json';
import hours from './hours.json';
import management from './management.json';
import profile from './profile.json';
import classroom from './classroom.json';
import community from './community.json';

const index = {
  chat,
  tour,
  activities,
  button,
  header,
  home,
  hours,
  profile,
  management,
  auth,
  classroom,
  community,
};

export default index;
