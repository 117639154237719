import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaExclamationCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { FiMail, FiXCircle } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { apiCall } from '../../../../crud/api.crud';
import Modal from '../../Modal';
import '../scss/modal.scss';
import Loading from './Loading';

const schema = yup
  .object({
    email: yup
      .string()
      .email('El correo debe tener este formato ejemplo@correo.com')
      .required('Por favor escriba su correo'),
    password: yup
      .string()
      .required('Por favor escriba su contraseña')
      .matches(/^(?=.{8,})/, 'Debe tener 8 dígitos'),
    confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas no coiciden')
      .required('Es requerido'),
  })
  .required();

const stateError = {
  email: false,
  password: false,
  confirmation: false,
  server: false,
};

const ResetPassword = ({ isOpen, setStep, setIsOpen }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [error, setError] = useState(stateError);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const changeShowPass = () => {
    setShowPass(!showPass);
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(stateError);
    setStatus(false);

    const payload = {
      email,
      password,
      password_confirmation: confirmation,
      token: id,
    };

    try {
      await apiCall('reset', payload, 'POST');
      setStatus(true);
      setError(stateError);
    } catch (err) {
      setError({ ...stateError, server: true });
    } finally {
      setLoading(false);
    }
  };

  function close() {
    setIsOpen(false);
    setStep(1);
  }

  console.log(id);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="modal-header border-0 pb-0">
          <h2 className="modal-title w-100 ml-4 text-center font-weight-bold" style={{ color: '#2f3237' }}>
            Restablecer contraseña
          </h2>
          <Link to="/" type="button" className="close" data-dismiss="modal">
            <FiXCircle onClick={close} />
          </Link>
        </div>
        <div className="modal-body">
          <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="_token" value="" />
            <div className="container is-column d-flex justify-content-center">
              <div className="form-group w-100">
                <label className="control-label">{t('auth.email')}</label>
                <div>
                  {email == '' ? (
                    <FiMail
                      style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                      color="#8f96a3"
                      size="1.2em"
                    />
                  ) : (
                    ' '
                  )}
                  <input
                    {...register('email')}
                    className="form-control input-lg rounded-pill"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    placeholder={t('home.public_msg7')}
                  />
                  <small className="text-danger">{errors.email?.message}</small>
                </div>
              </div>
              <div className="form-group w-100">
                <label className="control-label">{t('auth.password')}</label>
                <div>
                  {showPass ? (
                    <FaEyeSlash
                      className="password-eye"
                      onClick={changeShowPass}
                      style={{ margin: '3.5em 1.4em .0em .3em', position: 'absolute', top: '6em' }}
                      color="#8f96a3"
                      size="1.2em"
                    />
                  ) : (
                    <FaEye
                      className="password-eye"
                      onClick={changeShowPass}
                      style={{ margin: '3.5em 1.4em .0em .3em', position: 'absolute', top: '6em' }}
                      color="#8f96a3"
                      size="1.2em"
                    />
                  )}
                  <input
                    {...register('password')}
                    type={showPass ? 'text' : 'password'}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="form-control input-lg rounded-pill"
                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  />
                  <small className="text-danger">{errors.password?.message}</small>
                </div>
              </div>
              <div className="form-group w-100">
                <label className="control-label">Confirmar contraseña</label>
                <div>
                  {showPass ? (
                    <FaEyeSlash
                      className="password-eye"
                      onClick={changeShowPass}
                      style={{ margin: '5.9em 1.4em .0em .3em', position: 'absolute', top: '9em' }}
                      color="#8f96a3"
                      size="1.2em"
                    />
                  ) : (
                    <FaEye
                      className="password-eye"
                      onClick={changeShowPass}
                      style={{ margin: '5.9em 1.4em .0em .3em', position: 'absolute', top: '9em' }}
                      color="#8f96a3"
                      size="1.2em"
                    />
                  )}
                  <input
                    {...register('confirmation')}
                    type={showPass ? 'text' : 'password'}
                    className="form-control input-lg rounded-pill"
                    onChange={(e) => {
                      setConfirmation(e.target.value);
                    }}
                    value={confirmation}
                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  />

                  <small className="text-danger">{errors.confirmation?.message}</small>
                </div>
              </div>
              <div className="text-center ">
                {error.email && (
                  <p className="span text-danger">
                    <FaExclamationCircle size=".5em" className="mr-2 icon icon--error" />
                    {t('auth.msg_error2')}
                  </p>
                )}
                {error.server && (
                  <p className="span text-danger">
                    <FaExclamationCircle size=".5em" className="mr-2 icon icon--error"></FaExclamationCircle>
                    {t('auth.msg_error')}
                  </p>
                )}
                {status && (
                  <p className="span text-success">
                    <AiOutlineCheckCircle size=".5em" className="icon icon--ok"></AiOutlineCheckCircle>
                    Se restablecio su contraseña!
                  </p>
                )}
              </div>
            </div>
            <div className="form-group text-center">
              {loading ? (
                <Loading />
              ) : (
                <button
                  type="submit"
                  className="btn rounded-pill mt-2 p-2"
                  style={{ width: '93%', backgroundColor: '#0093E9', color: 'white' }}
                >
                  Confirmar
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer d-flex justify-content-center p-0">
          <Link
            to="/"
            className="btn btn-link btn-outline-"
            style={{
              color: '#8f96a3',
              fontSize: '.89em',
              cursor: 'pointer',
            }}
          >
            <b style={{ color: '#0093E9' }}>{t('auth.back_login')}</b>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassword;
