import styled from 'styled-components';
import config from '../../../../../config';
import { Activity } from '../../../../../interfaces/activity';
import { TextHtml } from '../../../../TextHtml';

interface props {
  activity: Activity;
}

const Box = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
`;

const Description = ({ activity }: props) => {
  return (
    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      {!config.profile_globant && <hr />}
      <h4>{activity.title}</h4>
      <TextHtml html={activity.description} />
      <Box>
        {!config.profile_globant && <hr />}
        {!config.profile_globant && (
          <>
            <h6> Términos y condiciones </h6>
            <TextHtml html={activity.terms_and_conditions} />
          </>
        )}
      </Box>
      {!config.profile_globant && <hr />}
    </div>
  );
};

export default Description;
