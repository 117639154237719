import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import config from '../../../../../config';
import Description from './Description';

const Table = ({ data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t('management.management_msg1'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('management.management_msg2'),
      selector: 'email',
      sortable: true,
      grow: 2,
    },
    {
      name: t('management.management_msg3'),
      selector: 'bussiness_name',
      sortable: true,
    },
    {
      name: t('management.management_msg4'),
      selector: 'fantasy_name',
      sortable: true,
    },
    !config.exception && {
      name: t('management.management_msg5'),
      selector: 'committed_hours',
      sortable: true,
      right: true,
    },
    !config.exception && {
      name: t('management.management_msg6'),
      selector: 'actual_hours',
      sortable: true,
      right: true,
    },
    {
      name: t('management.management_msg7'),
      selector: 'from',
      sortable: true,
    },
    {
      name: t('management.management_msg8'),
      selector: 'to',
      sortable: true,
    },
  ];

  return (
    <DataTable
      title={t('management.management_msg')}
      columns={columns}
      data={data}
      expandableRows
      expandableRowsComponent={<Description data={data} />}
    />
  );
};
export default Table;
