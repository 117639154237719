import { useEffect, useState } from 'react';
import { apiCall } from '../../../../../crud/api.crud';
import useGetUserId from '../../../../../hooks/api/useGetUserId';

// const getInfo = (userId,page) => await apiCall(`user/${user_id}/activityHours?${setPage}&sort=-created_at`)

const useActivietiesUserHours = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [meta, setMeta] = useState({});
  const user_id = useGetUserId();

  useEffect(() => {
    update();
  }, []);

  const update = async (pages = null) => {
    setLoading(true);
    const setPage = pages ? `page[number]=${pages}` : '';
    const response = await apiCall(`user/${user_id}/activityHours?${setPage}&sort=-created_at`);
    setActivities(response.data.data);
    setLoading(false);
    setMeta(response.data.meta);
  };

  return { activities, loading, error, meta, update };
};

export default useActivietiesUserHours;
