import './linear-scale.scss';

const values = [1, 2, 3, 4, 5];

const LinearScale = ({ addAnswers, id }) => {
  const change = (e) => {
    addAnswers(e.target.value);
  };

  return (
    <div className="wrap">
      <ul className="inputs">
        {values.map((value) => (
          <li key={`scale-${value}`}>
            <input type="radio" name={id} onClick={change} value={value} />
            <label>{value}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinearScale;
