import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import config from '../../../config';
import { checkUser } from '../../../utils/checkUser';
import useCustomField from '../../pages/Private/Profile/ExtraPersonalData/hook/useCustomField';
// import { withTranslation } from 'react-i18next';
// import { checkExtraField } from '../../../utils/checkExtraField';

const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 125px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.5s ease-out;
`;

const MainModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.08);
  width: 500px;
`;

const BodyModal = styled.div`
  width: 420px;
  margin: 0 40px 5px;
  padding: 1.5rem;
`;

const FooterModal = styled.footer`
  width: 420px;
  margin: 0 40px 20px;
  display: flex;
  justify-content: flex-end;
`;
const Span = styled.span`
  color: #2d66b1;
  font-weight: bold;
`;

const Reminder = ({ user, isOpenReminder, onClose, onOpen }) => {
  const { t } = useTranslation();

  const initialState = () => checkUser(user);
  const { customFields } = useCustomField(user.id);

  const [invalidField, setInvalidField] = useState(initialState);

  useEffect(() => {
    if (!invalidField.length) {
      onClose();
    } else {
      onOpen();
    }
  }, [invalidField]);

  const close = () => {
    onClose();
    setInvalidField([]);
  };

  const invalid = customFields.filter((field) => field.answers.length === 0);

  if (!isOpenReminder || !config.has_reminder) return null;
  return (
    isOpenReminder && (
      <Overlay>
        <MainModal>
          <BodyModal>
            <h5>
              {t('tour.reminder_msg')} <Span>{config.name}</Span> {t('tour.reminder_msg1')}
            </h5>
            {invalidField.map((invalidField) => (
              <li key={`invalidField-${invalidField}`}>{invalidField}</li>
            ))}
            {invalid.map((extraField) => (
              <li key={`extraField-${extraField.id}`}>{extraField.title}</li>
            ))}
          </BodyModal>
          <FooterModal>
            <button type="button" className="btn btn-secondary mr-2" onClick={close}>
              {t('tour.reminder_msg2')}
            </button>
            <Link to="/profile">
              <button type="button" className="btn btn-primary" onClick={close}>
                {t('tour.reminder_msg3')}
              </button>
            </Link>
          </FooterModal>
        </MainModal>
      </Overlay>
    )
  );
};

export default React.memo(Reminder);
