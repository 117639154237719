import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: auto;
  border-radius: 15px;
  background-color: #bee0f31f;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f3c44;
  font-size: 15px;
`;
const Box = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 30px;
  &:last-child {
    margin-left: 20px;
  }
`;
const generateUri = (object) => {
  const esc = encodeURIComponent;
  return Object.keys(object)
    .filter((k) => object[k])
    .map((k) => esc(k) + '=' + esc(object[k]))
    .join('&');
};

const history = createBrowserHistory();

const ManagementFilter = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  useEffect(() => {
    // localStorage.setItem("generate", generateUri(filter));
    history.push('/management?' + generateUri(filter));
  }, [filter]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // localStorage.setItem("filter", JSON.stringify({ ...filter, [name]: value }))
    setFilter({ ...filter, [name]: value });
  };
  const handleChangeAction = () => {
    // localStorage.setItem("filter", JSON.stringify({ ...filter, actions:!filter.actions }))
    setFilter({ ...filter, actions: !filter.actions });
  };
  return (
    <Container>
      <div className="row justify-content-around">
        {/* input status */}
        <Box>
          <label htmlFor="status" className="form-label">
            {t('activities.activities_msg2')}
          </label>
          <div className="col-xs-10">
            <select
              id="status"
              name="status"
              className="form-control form-control-sm"
              onChange={handleChange}
              value={filter.status}
            >
              <option value="">{t('activities.status_msg5')}</option>
              <option value="1">{t('activities.status_msg6')}</option>
              <option value="2">{t('activities.status_msg8')}</option>
              <option value="3">{t('activities.status_msg9')}</option>
            </select>
          </div>
        </Box>
        {/* input status_postulation */}
        <Box>
          <label htmlFor="status_postulation" className="form-label">
            {t('activities.activities_msg3')}
          </label>
          <div className="col-xs-10">
            <select
              id="status_postulation"
              name="status_postulation"
              className="form-control form-control-sm"
              onChange={handleChange}
              value={filter.status_postulation}
            >
              <option value="">{t('activities.status_msg5')}</option>
              <option value="0">{t('activities.status_msg')}</option>
              <option value="1">{t('activities.status_msg2')}</option>
              <option value="2">{t('activities.status_msg3')}</option>
              <option value="3">{t('activities.status_msg4')}</option>
              <option value="7">{t('activities.certificate')}</option>
            </select>
          </div>
        </Box>
        {/* input actions */}
        <Box>
          <div>
            <input
              type="checkbox"
              id="actions"
              className="form-check-input"
              onChange={handleChangeAction}
              value={filter.actions}
            />
            <label htmlFor="actions" className="form-check-label">
              {t('activities.activities_msg4')}
            </label>
          </div>
        </Box>

        {/* input deadline */}
        {/* <Box>
                <label htmlFor="date" className="form-label">Fecha límite</label>
                <div className="col-xs-10">
                    <input
                        id="date" name="deadline" type="date" className="form-control" onChange={handleChange}></input>
                </div>
            </Box> */}
      </div>
    </Container>
  );
};

export default ManagementFilter;
