/* eslint-disable */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import SDG1 from './sgd-1.png';
import SDG2 from './sgd-2.png';
import SDG3 from './sgd-3.png';
import SDG4 from './sgd-4.png';
import SDG5 from './sgd-5.png';
import SDG6 from './sgd-6.png';
import SDG7 from './sgd-7.png';
import SDG8 from './sgd-8.png';
import SDG9 from './sgd-9.png';
import SDG10 from './sgd-10.png';
import SDG11 from './sgd-11.png';
import SDG12 from './sgd-12.png';
import SDG13 from './sgd-13.png';
import SDG14 from './sgd-14.png';
import SDG15 from './sgd-15.png';
import SDG16 from './sgd-16.png';
import SDG17 from './sgd-17.png';

export const ods = [
  SDG1,
  SDG2,
  SDG3,
  SDG4,
  SDG5,
  SDG6,
  SDG7,
  SDG8,
  SDG9,
  SDG10,
  SDG11,
  SDG12,
  SDG13,
  SDG14,
  SDG15,
  SDG16,
  SDG17,
];
