import { useEffect, useState } from 'react';
/*
import Hours from './Hours';
import TextArea from './TextArea';
import SelectActivity from './SelectActivity';
import { ButtonPrimary } from '../../Button';
*/
import { useDispatch, useSelector } from 'react-redux';
import { default as FormTest } from '../../Form/Form';
import Input from '../../Form/components/Input';
import Select from '../../Form/components/Select';
import TextArea from '../../Form/components/TextArea';
import SubmitButton from '../../Form/components/SubmitButton';
import { apiCall } from '../../../../crud/api.crud';
import { actions as userActivitiesHoursAction } from '../../../../store/ducks/user/activitiesHours.duck';

import { actions as userActivitiesAction } from '../../../../store/ducks/user/activities.duck';
import Hours from '../../Form/components/Hours';
import useGetUserId from '../../../../hooks/api/useGetUserId';
import { useTranslation } from 'react-i18next';

const parseOptions = (data) => data.map((opt) => ({ value: opt.id, label: opt.title }));

const Form = ({ closeModal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [activitySelected, setActivitySelected] = useState(null);

  const user_id = useGetUserId();
  const { userActivities } = useSelector((state) => ({
    userActivities: state.userActivities.activities,
  }));

  useEffect(() => {
    if (!userActivities) {
      dispatch(userActivitiesAction.getActivities(user_id));
    }
  }, [user_id, userActivities, dispatch]);

  const save = async (values) => {
    const activity = userActivities.data.find((act) => act.id === values.activity_id);
    if (activity.estimated_hours !== 0) values.hours = activity.estimated_hours;
    await apiCall('activityHours', values, 'POST');
    dispatch(userActivitiesHoursAction.getHours({ user_id }));
    closeModal();
  };

  const selectActivity = (activity_id) => {
    apiCall(`activity/${activity_id}`, null, 'GET').then((response) => {
      setActivitySelected(response.data.data[0]);
    });
  };

  return (
    <FormTest
      className="form"
      defaultValue={{
        user_id,
        upload_type: process.env.REACT_APP_ID_ENTITY,
      }}
      submit={save}
    >
      <div className="row">
        <div className="col-md-12 mb-4">
          <Select
            label={t('activities.activities_msg5')}
            name="activity_id"
            validations={[{ key: 'required', val: true }]}
            options={userActivities ? parseOptions(userActivities.data) : null}
            customFunction={selectActivity}
          />
        </div>
        {activitySelected && (
          <>
            <div className="col-md-6  mb-4">
              <Hours
                label={t('hours.hours_msg4')}
                name="hours"
                validations={[{ key: 'required', val: true }]}
                estimated_hours={activitySelected.estimated_hours}
              />
            </div>
            <div className="col-md-6  mb-4">
              <Input
                label={t('hours.hours_msg13')}
                name="activity_day"
                type="date"
                validations={[
                  { key: 'required', val: true },
                  { key: 'only_numbers', val: true },
                ]}
              />
            </div>
            <div className="col-md-12 mb-4">
              <TextArea
                label={t('hours.hours_msg14')}
                name="commentary"
                type="date"
                validations={[{ key: 'required', val: true }]}
              />
            </div>
          </>
        )}
      </div>
      <SubmitButton />
    </FormTest>
  );
};

export default Form;
