import Slider from 'react-slick';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

const SimpleSlider = ({ items }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const settings = {
    dots: true,
    nextArrow: isDesktop ? <SampleNextArrow /> : <></>,
    prevArrow: isDesktop ? <SamplePrevArrow /> : <></>,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Slider {...settings}>
        {items.map((banner) => (
          <div key={`banner-${banner.id}`}>
            <a
              href={banner.redirect || '#'}
              target="_blank"
              rel="noopener noreferrer"
              className="card"
              style={{
                pointerEvents: !banner.redirect && 'none',
                backgroundColor: 'transparent',
                border: 'none',
              }}
            >
              <img src={banner.url} alt="banner" style={{ objectFit: 'contain' }} referrerPolicy="no-referrer" />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
