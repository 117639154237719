import { Button, Checkbox, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { Lesson as LessonType } from 'interfaces/guide.interface';
import { useTranslation } from 'react-i18next';
import { RiListCheck3, RiVideoLine } from 'react-icons/ri';
import { useHistory } from 'react-router';

interface LessonProps {
  lesson: LessonType;
  registered?: boolean;
  activity_id: number;
}

export function Lesson({ lesson, activity_id, registered }: LessonProps) {
  const { resources = [], description, title, quiz } = lesson;
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const history = useHistory();

  const goToGuide = (type?: 'quiz' | 'lesson', id?: number) => {
    let url = `/detail/${activity_id}/guide`;
    if (type) {
      url += `/${type}/${id}`;
    }
    history.push(url);
  };

  return (
    <>
      <Grid templateColumns="1fr auto" boxShadow="md" rounded="md" p={4} _hover={{ bg: 'gray.100' }}>
        <GridItem textAlign="start" alignSelf="center">
          <Text fontWeight="bold">{title}</Text>
          <Text>{description}</Text>
        </GridItem>
        <GridItem>
          {resources?.map((resource) => (
            <HStack key={resource.id} spacing={{ base: 2, md: 4 }}>
              <Checkbox isChecked={!!resource.completed_at} mb={0} colorScheme="brand" />
              {(resource.video_url || resource.file_path) && (
                <Button
                  leftIcon={<RiVideoLine size={16} />}
                  variant="outline"
                  isDisabled={!registered}
                  colorScheme="brand"
                  size="sm"
                  rounded="sm"
                  onClick={() => goToGuide('lesson', lesson.id)}
                >
                  {t('see_class')}
                </Button>
              )}
            </HStack>
          ))}
        </GridItem>
      </Grid>

      {quiz && (
        <Grid templateColumns="1fr auto" boxShadow="md" rounded="md" p={4}>
          <GridItem textAlign="start" alignSelf="center">
            <Text fontWeight="bold">{quiz?.name}</Text>
          </GridItem>
          <GridItem>
            <HStack spacing={{ base: 2, md: 4 }}>
              <Checkbox isChecked={!!quiz.answer && Number(quiz.answer.score) >= 70} mb={0} colorScheme="brand" />
              <Button
                leftIcon={<RiListCheck3 size={16} />}
                variant="outline"
                isDisabled={!registered}
                colorScheme="brand"
                size="sm"
                rounded="sm"
                onClick={() => goToGuide('quiz', quiz.id)}
              >
                {t('see_evaluation')}
              </Button>
            </HStack>
          </GridItem>
        </Grid>
      )}
    </>
  );
}
