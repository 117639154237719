import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa';
import { FiMail, FiXCircle } from 'react-icons/fi';
import { RiLockPasswordLine } from 'react-icons/ri';
import * as yup from 'yup';
import { apiCall } from '../../../../crud/api.crud';
import useStore from '../../../../store/zustand/store';
import '../scss/modal.scss';
import Loading from './Loading';

const schema = yup
  .object({
    email: yup
      .string()
      .email('El correo debe tener este formato ejemplo@correo.com')
      .required('Por favor escriba su correo'),
    password: yup.string().required('Por favor escriba su contraseña'),
  })
  .required();

const stateError = {
  email: false,
};

const Login = ({ setStep, setIsOpen, email, setEmail }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(stateError);
  const [loading, setLoading] = useState(false);
  const [inputPassword, setInputPassword] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loginApi = useStore((state) => state.login);

  const onSubmit = async (data) => {
    setError(stateError);
    setLoading(true);

    try {
      const response = await apiCall('login', data, 'POST');
      data = response.data.data[0];
      loginApi(data);
      setError(stateError);
    } catch (err) {
      if (err.message == 'Request failed with status code 422') {
        setError({ email: true });
      } else {
        setError({ ...stateError, server: true });
      }
    } finally {
      setLoading(false);
    }
  };

  function close() {
    setIsOpen(false);
    setStep(1);
  }

  return (
    <div>
      <div className="modal-header border-0 pb-0">
        <h2 className="modal-title w-100 ml-4 text-center font-weight-bold" style={{ color: '#2f3237' }}>
          {t('home.public_msg2')}
        </h2>
        <button type="button" className="close" data-dismiss="modal">
          <FiXCircle onClick={close} />
        </button>
      </div>
      <div className="modal-body">
        <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="_token" value="" />
          <div className="container is-column d-flex justify-content-center">
            <div className="form-group w-100">
              <label className="control-label">{t('auth.email')}</label>
              <div>
                {email == '' ? (
                  <FiMail
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                ) : (
                  ' '
                )}
                <input
                  {...register('email')}
                  className="form-control input-lg rounded-pill"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  placeholder={t('home.public_msg7')}
                />
                <small className="text-danger">{errors.email?.message}</small>
              </div>
            </div>
            <div className="form-group w-100">
              <label className="control-label">{t('auth.password')}</label>
              <div>
                {inputPassword == '' ? (
                  <RiLockPasswordLine
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                ) : (
                  ''
                )}
                <input
                  {...register('password')}
                  type="password"
                  onChange={(e) => {
                    setInputPassword(e.target.value);
                  }}
                  className="form-control input-lg rounded-pill"
                  placeholder={t('home.public_msg8')}
                />
                <small className="text-danger">{errors.password?.message}</small>
              </div>
            </div>
            <div className="text-center ">
              {error.email && (
                <p className="span text-danger">
                  <FaExclamationCircle size=".5em" className="mr-2 icon icon--error" />
                  {t('auth.msg_error2')}
                </p>
              )}
              {error.server && (
                <p className="span text-danger">
                  <FaExclamationCircle size=".5em" className="mr-2 icon icon--error"></FaExclamationCircle>
                  {t('auth.msg_error')}
                </p>
              )}
            </div>
          </div>
          <div className="form-group text-center">
            {loading ? (
              <Loading />
            ) : (
              <button
                type="submit"
                className="btn rounded-pill mt-2 p-2"
                style={{ width: '93%', backgroundColor: '#0093E9', color: 'white' }}
              >
                {t('button.button_msg17')}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center justify-content-lg-between">
        <button
          className="btn btn-link "
          onClick={() => setStep(2)}
          style={{
            color: '#8f96a3',
            fontSize: '.89em',
            cursor: 'pointer',
          }}
        >
          {t('home.public_msg0')}
          <b style={{ color: '#0093E9' }}> {t('home.public_msg1')}</b>
        </button>
        <a
          className="btn btn-link"
          onClick={() => setStep(3)}
          style={{
            color: '#8f96a3',
            fontSize: '.8em',
            cursor: 'pointer',
          }}
        >
          {t('home.public_msg6')}
        </a>
      </div>
    </div>
  );
};

export default Login;
