import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaExclamationCircle } from 'react-icons/fa';
import { FiMail, FiXCircle } from 'react-icons/fi';
import * as yup from 'yup';
import { apiCall } from '../../../../crud/api.crud';
import '../scss/modal.scss';
import Loading from './Loading';

const schema = yup
  .object({
    email: yup
      .string()
      .email('El correo debe tener este formato ejemplo@correo.com')
      .required('Por favor escriba su correo'),
  })
  .required();

const stateError = {
  email: false,
};

const ForgottenPassword = ({ setStep, setIsOpen, email, setEmail }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(stateError);
  const [status, setStatus] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(stateError);

    const payload = {
      email,
    };
    try {
      await apiCall(`sendResetLinkEmail`, payload, 'POST');
      setStatus(true);
      setError(stateError);
    } catch (err) {
      if (err.message == 'Request failed with status code 422') {
        setError({ email: true });
      } else {
        setError({ ...stateError, server: true });
      }
    } finally {
      setLoading(false);
    }
  };

  function close() {
    setIsOpen(false);
    setStep(1);
  }
  return (
    <div>
      <div className="modal-header border-0 pb-0">
        <h2 className="modal-title w-100 ml-4 text-center font-weight-bold" style={{ color: '#2f3237' }}>
          {t('auth.forgot_msg1')}
        </h2>
        <button type="button" className="close" data-dismiss="modal">
          <FiXCircle onClick={close} />
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
          <input type="hidden" name="_token" value="" />
          <div className="container is-column d-flex justify-content-center">
            <div className="form-group w-100">
              <label className="control-label">{t('auth.forgot_msg')}</label>
              <div>
                {email == '' ? (
                  <FiMail
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                ) : (
                  ''
                )}
                <input
                  {...register('email')}
                  type="text"
                  className="form-control input-lg rounded-pill"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('home.public_msg7')}
                  onKeyDown={handleOnKeyDown}
                />
              </div>
              <small className="text-danger">{errors.email?.message}</small>
            </div>
          </div>
          <div className="text-center mb-3">
            {error.server && (
              <p className="span text-danger">
                <FaExclamationCircle size=".5em" className="mr-2 icon icon--error"></FaExclamationCircle>
                {t('auth.msg_error')}
              </p>
            )}
            {error.email && (
              <p className="span text-warning">
                <FaExclamationCircle size=".5em" className="mr-2 icon icon--error" />
                {t('auth.msg_error3')}
              </p>
            )}

            {status && (
              <p className="span text-success">
                <AiOutlineCheckCircle size=".5em" className="icon icon--ok"></AiOutlineCheckCircle>
                {t('auth.success2')}
              </p>
            )}
          </div>
          {!status ? (
            <div className="form-group text-center">
              {loading ? (
                <Loading />
              ) : (
                <button
                  type="submit"
                  className="btn rounded-pill p-2 m-0"
                  style={{ width: '90%', backgroundColor: '#0093E9', color: 'white' }}
                >
                  {t('auth.next')}
                </button>
              )}
            </div>
          ) : null}
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center p-0">
        <button
          className="btn btn-link btn-outline-"
          onClick={() => setStep(1)}
          style={{
            color: '#8f96a3',
            fontSize: '.89em',
            cursor: 'pointer',
          }}
        >
          <b style={{ color: '#0093E9' }}>{t('auth.back_login')}</b>
        </button>
      </div>
    </div>
  );
};

export default ForgottenPassword;
