import { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Text from './Text';

const animatedComponents = makeAnimated();

const convertResponseToOptions = (array) => {
  if (array.length === 0) return [];
  return array.map((v) => ({ value: v.value, label: v.value }));
};

const Option = ({ options, addAnswers, isMulti = false }) => {
  const [selected, setSelected] = useState('');
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const getOther = options.find((op) => op.is_other_option) ?? { value: '' };

  const handleChange = (res) => {
    setSelected(res);

    const isValueOther = (val) => val === getOther.value;
    const parseRes = isMulti ? res.map((r) => (r.value !== getOther.value ? r.value : '')).join(',') : res && res.value;

    setIsOtherSelected(isMulti ? res.some((r) => isValueOther(r.value)) : isValueOther(res?.value));

    addAnswers(parseRes);
  };

  const handleTextChange = (res) => {
    const selectValues = isMulti
      ? selected.map((r) => (r.value !== getOther.value ? r.value : '')).join(',')
      : selected?.value || '';

    addAnswers(`${selectValues}${res}`);
  };

  const [optionsLocal, setOptionsLocal] = useState([]);
  useEffect(() => {
    setOptionsLocal(convertResponseToOptions(options));
  }, []);

  return (
    <>
      <Select
        // menuPortalTarget={document.getElementById('root')}
        isMulti={isMulti}
        options={optionsLocal}
        onChange={(res) => handleChange(res)}
        value={selected}
      ></Select>
      {isOtherSelected && (
        <>
          <label htmlFor="message-text" className="col-form-label">
            {getOther.value}
          </label>
          <Text addAnswers={handleTextChange}></Text>
        </>
      )}
    </>
  );
};

export default Option;
/*
placeholder="Objetivos de desarrollo sostenible"
components={animatedComponents}
isMulti
onChange={addFilter("inSdgs")}
options={convertResponseToOptions(response.ods, "label", "id")}
defaultValue={filter ? filter.inSdgs : []} */
