import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { apiCall } from '../../../../crud/api.crud';

const schema = Yup.string().email().required();

const ResetUser = ({ location }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState(location?.emailProp?.email || '');
  const { t } = useTranslation();

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      sendEmailReset();
    }
  };

  const sendEmailReset = async () => {
    setLoading(true);
    setError(false);
    const valid = await schema.isValid(email);
    if (!valid) {
      setLoading(false);
      setError(true);
      return;
    }

    const payload = {
      email,
    };
    try {
      await apiCall(`sendResetLinkEmail`, payload, 'POST');
      setStatus(true);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h5 className="mt-2 mb-4">{t('auth.forgot_msg')}</h5>
      <div>
        <div className="mb-5 col-12 p-0">
          <label className="pl-2">{t('auth.email')}</label>
          <input
            type="text"
            className={`input-text ${error && 'input-text--danger'}`}
            placeholder={t('auth.EJ_email')}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleOnKeyDown}
            value={email}
          />

          {error && <span className="span span--error">Revisa tu correo</span>}
          {status && (
            <div className="d-flex mt-2">
              <FaCheck className="mr-2 icon icon--ok"></FaCheck>
              <span className="msj msj--ok">{t('auth.success2')}</span>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-around w-100">
          <Link to="/" className="button button--white mt-3 mb-5" style={{ margin: '30px' }}>
            {t('auth.back')}
          </Link>
          <button className="button mt-3 mb-5" style={{ margin: '30px' }} onClick={sendEmailReset}>
            {loading ? t('auth.validate') : t('auth.next')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ResetUser;
