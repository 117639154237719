import config from 'config';
import { Lesson } from 'interfaces/guide.interface';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useCallback, useMemo } from 'react';

export function useGuide() {
  const { guide } = useGuideContext();

  const hasEnded = useMemo(() => {
    if (!guide) return false;
    if (guide?.status === 7) return true;
    /**
     * Validar si todos los recursos y las preguntas son aprobadas
     * Se comento para mostrar el certificado solo por el status 7(certificado)
     * Esto para aplicar la automatización de la capacitación
     */
    if (guide.automation_type !== 'automatic') return false;

    const { topics } = guide;

    const lessons = topics?.flatMap((t) => t.chapters).flatMap((c) => c.lessons);

    const resources = lessons?.flatMap((l) => l.resources);

    const resourcesApproved = resources?.every((resource) => resource.completed_at);
    const quizzesApproved = lessons?.every(({ quiz }) =>
      quiz ? Number(quiz?.answer?.score) >= Number(config.classroom?.min_approved_score) : true,
    );

    return resourcesApproved && quizzesApproved;
  }, [guide]);

  const lessonIsActive = useCallback(
    (lessonId: Lesson['id']) => {
      if (!guide?.is_mandatory) return true;
      const lessons = guide.topics?.flatMap((t) => t.chapters).flatMap((c) => c.lessons);

      if (!lessons) return false;

      const idx = lessons.findIndex((l) => l.id === lessonId);

      if (idx === 0) return true; // Primer clase

      if (lessons[idx]?.resources?.some((r) => r.completed_at)) return true; // Ya tiene un recurso completado

      const prevLesson = lessons[idx - 1];

      const prevCompleted = prevLesson?.resources?.some((r) => r.completed_at);
      const prevQuizApproved = prevLesson.quiz
        ? Number(prevLesson.quiz?.answer?.score) >= Number(config.classroom?.min_approved_score)
        : true;

      return prevCompleted && prevQuizApproved;
    },
    [guide],
  );

  return { hasEnded, lessonIsActive };
}
