import { useEffect, useRef } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useOutsideClick } from '@chakra-ui/react';
import styled from 'styled-components';
import useBoolean from 'hooks/useBoolean';
import { useTranslation } from 'react-i18next';
import { MdAddBox, MdCheckCircle, MdComment, MdRemoveRedEye } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import config from '../../../../../config';
import { ActivityParse, Survey } from '../../../../../interfaces/activity';
import { actions as modalAction } from '../../../../../store/ducks/modal.duck';
import { Certificate } from 'components/pages/Private/Activities/components/Certificate';
import { useLocation } from 'react-router';
const iconStyle = {
  fontSize: '18px',
};
interface props {
  row: ActivityParse;
  handleClick: (id: number) => void;
  type?: string;
}

const Ul = styled.ul`
  left: auto;
  right: 0px;
  font-size: 13px;
`;

const SHOW_COMMENTARY_VOLUNTEER = [5]; // Culminado

const ActionsDropdown = ({ row, handleClick, type = 'icon' }: props) => {
  const { isOpen, onToggle, onClose } = useBoolean();
  const location = useLocation();

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick({
    ref: ref as React.MutableRefObject<HTMLDivElement | null>,
    handler: () => onClose(),
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commentaryModal = (activity_id: number, survey: Survey | null, estimated_hours: number): void => {
    dispatch(
      modalAction.modalShow({
        modalProps: {
          open: true,
          activity_id,
          url: 'activity/addCommentary',
          survey,
          estimated_hours,
        },
        modalType: 'commentary',
      }),
    );
  };

  const hoursModal = ({ id, estimated_hours }: ActivityParse): void => {
    const title =
      estimated_hours !== 0 ? 'Completar Actividad' : config.exception ? 'Registrar acción' : 'Carga de horas';
    dispatch(
      modalAction.modalShow({
        modalProps: {
          open: true,
          title,
          activity_id: id,
        },
        modalType: 'hoursLoad',
      }),
    );
  };

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <div className="btn-group" ref={ref}>
      <button
        type="button"
        data-toggle="dropdown"
        className="btn d-flex align-items-center newStylePerfil p-8"
        onClick={onToggle}
      >
        <BsThreeDotsVertical />
      </button>

      <Ul className={`dropdown-menu rounded position-absolute ${isOpen && 'show'} p-2`}>
        <li
          className="my-2 d-flex align-items-center item-menu item-menu-action-dropdown"
          onClick={() => {
            handleClick(row.id);
            onClose();
          }}
        >
          <div className="text-primary">
            <MdRemoveRedEye style={iconStyle} />
          </div>
          <p className="mb-0 ml-2">Ver actividad</p>
        </li>
        {config.has_hours &&
          row.selection_status === t('activities.status_msg3') &&
          row.automate_hours_calculation !== true && (
            <>
              {row.estimated_hours !== 0 ? (
                <li
                  className="my-2 d-flex align-items-center item-menu item-menu-action-dropdown"
                  onClick={() => {
                    hoursModal(row);
                    onClose();
                  }}
                >
                  <div className="text-primary">
                    <MdCheckCircle />
                  </div>
                  <p className="mb-0 ml-2">Registrar acción</p>
                </li>
              ) : (
                <li
                  className="my-2 d-flex align-items-center item-menu item-menu-action-dropdown"
                  onClick={() => {
                    hoursModal(row);
                    onClose();
                  }}
                >
                  <div className="text-primary">
                    <MdAddBox style={iconStyle} />
                  </div>
                  <p className="mb-0 ml-2">Registrar acción</p>
                </li>
              )}
            </>
          )}
        {!row.commentary && SHOW_COMMENTARY_VOLUNTEER.includes(row.status_postulation) && (
          <li
            className="my-2 d-flex align-items-center item-menu item-menu-action-dropdown"
            onClick={() => {
              commentaryModal(row.id, row.survey, row.estimated_hours);
              onClose();
            }}
          >
            <div className="text-primary">
              <MdComment style={iconStyle} />
            </div>
            <p className="mb-0 ml-2">Comentar</p>
          </li>
        )}

        {row.status_postulation == 7 && !config?.activities_certificate_table && (
          <li className="my-2 item-menu item-menu-action-dropdown">
            <Certificate activityId={row.id} type="text" />
          </li>
        )}
      </Ul>
    </div>
  );
};

export default ActionsDropdown;
