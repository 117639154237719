import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { apiCall } from '../../../../../crud/api.crud';
import config from '../../../../../config';
import { useTranslation } from 'react-i18next';
import Idiom from '../../../../organisms/Navbar/components/Idiom';

const schema = Yup.string().email().required();

const StepOne = ({ next, email, setEmail }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  });

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      setNextStep();
    }
  };

  const setNextStep = async () => {
    setLoading(true);
    setError(false);
    const valid = await schema.isValid(email);
    if (!valid) {
      setLoading(false);
      setError(true);
      return;
    }

    const response = await apiCall(`user?filter[email]=${email}`, null, 'GET');

    if (!response.data.data.length) {
      setLoading(false);
      setError(true);
      return;
    }
    next(1);
  };

  return (
    <>
      <h6 style={{ fontWeight: '700', color: 'grey' }}>{config.login_message ?? ''}</h6>
      <h5 className="mt-2">{t('home.public_msg2')}</h5>
      <h5 className="mb-4">{t('home.public_msg3')}</h5>
      <div className="w-100">
        <div className="vm-100 mb-5 col-12 p-0">
          <input
            type="text"
            className={`input-text ${error && 'input-text--danger'}`}
            placeholder="usuario@email.com"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleOnKeyDown}
            value={email}
            name="email"
            ref={inputRef}
          />
          {error && <span className="span span--error">{t('home.public_msg')}</span>}
        </div>

        <button className="button mt-3 mb-5 w-100" onClick={setNextStep}>
          {loading ? t('button.button_msg10') : t('button.button_msg2')}
        </button>
      </div>
      <div className="d-flex justify-content-between w-100 align-items-center">
        <Idiom />
        <Link to="/register">{t('home.public_msg1')}</Link>
      </div>
    </>
  );
};

export default StepOne;
