import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Card = styled.div`
  background-color: #ffffff;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  margin-bottom: 10px;
  width: 100%;

  hr {
    border: 2px solid #c4c4c4;
    border-radius: 5px;
  }

  .strong {
    font-weight: bold;
    margin: 0 5px 5px 0;
  }
  h3 {
    font-size: 20px;
  }
`;
const Container = styled.div`
  padding: 25px;
`;
const Img = styled.img`
  width: 100%;
  border-radius: 15px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const Footer = styled.div`
  background-color: #f7f7f7;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;
`;

const ButtonDonation = styled.a`
  background: #ff4450;
  &:focus {
    border: none;
    outline: none;
  }

  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  color: #fffdfd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  //box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateY(-3px);
    text-decoration: none;
    color: #fffdfd;
  }
  &:active {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    transform: translateY(3px);
  }
  &:focus {
    outline: none;
  }
`;

const getCodeYoutube = (link) => {
  const urlSearchParams = new URLSearchParams(link.split('?')[1]);

  return urlSearchParams.get('v');
};
const createMarkup = (setHtml) => ({ __html: setHtml });

const setHtml = (setHtml) => <div dangerouslySetInnerHTML={createMarkup(setHtml)} />;

const TutorialsCard = ({ tutorial }) => {
  const { t } = useTranslation();

  const code = getCodeYoutube(tutorial.link);
  if (!code) return null;
  return (
    <Card>
      <Container>
        <Info>
          <h3>{tutorial.title}</h3>
          <p>{setHtml(tutorial.description)}</p>
          <iframe
            width="100%"
            height="280"
            src={`https://www.youtube.com/embed/${code}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </Info>
      </Container>
      {/* <Footer>Pendiente</Footer> */}
    </Card>
  );
};

export default TutorialsCard;
