import { Center, Heading, Spinner, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useSocketContext } from 'context/SocketContext';
import { Chat } from 'modules/chat/components/Chat';
import { ChatLayout } from 'modules/chat/layout/ChatLayout';
import { BsChatLeftText } from 'react-icons/bs';
import { Route, Switch } from 'react-router-dom';

export function ChatRouter() {
  const { activities, rooms } = useSocketContext();

  if (!activities.length || rooms.length < activities.length) {
    return (
      <Center h="75vh">
        <VStack>
          <Spinner color="brand.500" />
          <Text>Connecting</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <ChatLayout>
      <Switch>
        <Route path={`/chat/:activityId`}>
          <Chat />
        </Route>
        <Route path={`/chat`}>
          <VStack
            height={useBreakpointValue({ base: '200', md: '400px' })}
            alignItems="center"
            justify="center"
            textAlign="center"
            gap={2}
            minH="60vh"
          >
            <BsChatLeftText size={30} color="gray" />
            <Heading size="md">Seleccione una actividad</Heading>
            <Text color="gray.500">Para comenzar a chatear</Text>
          </VStack>
        </Route>
      </Switch>
    </ChatLayout>
  );
}
