/* eslint-disable */
import create, { StateCreator } from 'zustand';
import { persist, devtools, StoreApiWithPersist, PersistOptions } from 'zustand/middleware';
import createLogin from './auth/login';
import createLogout from './auth/logout';
import createUpdateUser from './profile/profile';
import createTour from './tour/tour';

// Generated by https://quicktype.io

export interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  api_tour: string;
  sdgs: Sdg[];
  volunteeringFields: VolunteeringFields;
  entities: Entity[];
  addresses: Address[];
  talent: {
    image: string;
    short_description: string;
    title: string;
  };
  role_id: string;
  role_name: string;
}

export interface Address {
  id: number;
  name: string;
  info: string;
  location_lat: number;
  location_lng: number;
}

export interface Entity {
  id: number;
  bussiness_name: string;
  fantasy_name: string;
  web_profile: string;
  facebook_profile: string;
  twitter_profile: string;
  instagram_profile: string;
  linkedin_profile: string;
  address: string;
  type_id: number;
  about_us: string;
  status: string;
  is_premium: number;
  is_donor: number;
  shares_contact_information: number;
  updated_at: string;
  location_lat: number;
  location_lng: number;
  type: Type;
}

export interface Type {
  id: number;
  name: string;
  appliable_to: AppliableTo;
}

export enum AppliableTo {
  A = 'A',
  D = 'D',
}

export interface Sdg {
  id: number;
  label: string;
  description: string;
  year: number;
}

export interface VolunteeringFields {
  user_id: number;
  marital_status: string;
  id_type: string;
  id_number: string;
  country_id: string;
  phone_number: string;
  mobile_number: string;
  linkedin_url: null;
  gender: string;
  birthday: string;
  geolocation_cessi_id: string;
  state_id: string;
}

type Store = {
  user: User | null;
  login: (payload: any) => void;
  logout: () => void;
  checkedTour: (api_tour: string) => void;
  updateUser: (payload: object) => void;
};

type MyPersist = (config: StateCreator<Store>, options: PersistOptions<Store>) => StateCreator<Store>;

const useStore = create<Store>(
  devtools(
    (persist as MyPersist)(
      (set) => ({
        ...createLogout(set),
        ...createLogin(set),
        ...createTour(set),
        ...createUpdateUser(set),
        user: null,
      }),
      {
        name: 'authUser',
      },
    ),
    'user',
  ),
);

export default useStore;
