import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { object, string } from 'yup';
import { apiCall } from '../../../../../crud/api.crud';
import useGetUserId from '../../../../../hooks/api/useGetUserId';
import useStore from '../../../../../store/zustand/store';
import { ButtonPrimary } from '../../../../molecules/Button';
import SuccessSpan from '../../../../molecules/SuccessSpan';
import WarningSpan from '../../../../molecules/WarningSpan';

const Media = styled.img`
  object-fit: cover;
  width: 220px;
  height: 220px;
`;

const STORAGE_URL =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_API_URL}/images/`
    : 'https://app.fonselp.com/storage/images/';

const schema = object({
  title: string().min(6).required(),
  short_description: string().min(6).required(),
}).required();

const CatalogTalents = () => {
  const { t } = useTranslation();
  const user = useStore((state) => state.user);
  const fileInputRef = useRef(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isSubmitted, isDirty },
    setValue,
  } = useForm({
    defaultValues: {
      title: user?.talent?.title,
      short_description: user?.talent?.short_description,
    },
    resolver: yupResolver(schema),
  });
  const [currentImage, setCurrentImage] = useState(null);
  const [isError, setIsError] = useState(false);

  const user_id = useGetUserId();

  const updateUser = useStore((state) => state.updateUser);

  const updateCatalogTalents = async ({ title, image, short_description }) => {
    setIsError(false);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    if (image) {
      formData.append('image', image, image.name);
    }
    formData.append('title', title);
    formData.append('short_description', short_description);
    formData.append('id', user_id);

    try {
      const { data } = await apiCall('profile/talents', formData, 'POST');
      updateUser(data.data[0]);
    } catch (error) {
      setIsError(true);
    }
  };

  const onFileSelected = ({ target }) => {
    if (FileReader && target.files && target.files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        setCurrentImage(fr.result);
      };
      fr.readAsDataURL(target.files[0]);
      setValue('image', target.files[0], { shouldValidate: true });
    }
  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleSubmit(updateCatalogTalents)}>
        <div className="card shadow  bg-white rounded">
          <div className="card-header">Catalogo de Talentos</div>
          <div className="card-block">
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-sm-12 col-md-4">
                  <label htmlFor="image" className="login__label">
                    <span>Foto de Perfil </span>
                  </label>
                  <div className="login__input_group flex align-items-center">
                    {user?.talent?.image && (
                      <Media
                        src={currentImage || STORAGE_URL + user.talent.image}
                        alt={user.name}
                        className="rounded-circle"
                      />
                    )}
                    <ButtonPrimary onClick={() => fileInputRef.current?.click()} className="ml-2 my-4" type="button">
                      Cargar imagen
                    </ButtonPrimary>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: 'none' }}
                      onChange={onFileSelected}
                    />

                    {errors.image && <WarningSpan msj={errors.image.message} />}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-8">
                  <div className="form-group col-12">
                    <label htmlFor="title" className="login__label is_required">
                      <span>Titulo </span>
                    </label>

                    <div className="login__input_group">
                      <input id="title" type="text" name="title" className="form-control" {...register('title')} />
                      {errors.title && <WarningSpan msj={errors.title.message} />}
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="password_confirmation" className="login__label is_required">
                      <span>Descripcion</span>
                    </label>
                    <div className="login__input_group">
                      <textarea
                        name="short_description"
                        id="short_description"
                        cols="30"
                        rows="10"
                        className="form-control"
                        {...register('short_description')}
                        maxLength="420"
                      ></textarea>
                      {errors.short_description && <WarningSpan msj={errors.short_description.message} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            {!isError && isSubmitted && <SuccessSpan msj={t('profile.talent.success')} />}
            <ButtonPrimary type="submit" disabled={(!currentImage && !isDirty) || isSubmitting}>
              {isSubmitting ? t('button.button_msg6') : t('button.button_msg7')}
            </ButtonPrimary>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CatalogTalents;
