import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiCall } from '../../../../../crud/api.crud';
import useGetUserId from '../../../../../hooks/api/useGetUserId';
import { useBreakpoint } from '../../../../../hooks/useBreakpoint';
import { invercionMatriz } from '../../../../../utils/helper';
import ActivitiesCard from '../../../../molecules/ActivitiesCard';
import Loading from '../../../../molecules/Loading';
import Modal from './FilterModal';

const converFilterToUrl = (filters) => {
  let aux = '';
  for (const filter in filters) {
    const filterData = filters[filter];
    if (!filterData) continue;
    const data = Array.isArray(filterData) ? filterData.map((v) => v.value).join() : filters[filter];
    aux += `&filter[${filter}]=${data}`;
  }
  return aux;
};

const CloseActivities = () => {
  const { t } = useTranslation();

  const [activities, setActivities] = useState(null);
  const [activitiesUser, setActivitiesUser] = useState(null);
  const [page, setPage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const user_id = useGetUserId();
  useEffect(() => {
    const pageApi = page ? `page[number]=${page}` : '';
    apiCall(
      `activity/allwithuser?${pageApi}&filter[entity_origin_id]=${process.env.REACT_APP_ID_ENTITY}&filter[view_activities.status]=3&include=locations,form.fields.options`,
      null,
      'GET',
    ).then((response) => setActivities(response.data));
  }, [page, filter]);

  useEffect(() => {
    apiCall(`user/${user_id}/activities`, null, 'GET').then((response) => setActivitiesUser(response.data));
  }, []);

  const colums = useBreakpoint();
  if (!activities || !activitiesUser) return <Loading />;

  let act = activities.data.sort((a, b) => a.deadline < b.deadline);

  const activitiesColumns = invercionMatriz(act, colums);

  const activitiesUserOnlyId = activitiesUser.data.map((ac) => ac.id);

  const now = moment().subtract(1, 'days');

  return (
    <>
      <Modal setIsOpen={setIsOpen} isOpen={isOpen} setFilter={setFilter} filter={filter} />

      <div className="container mt-4">
        <div className="card__container">
          {activitiesColumns.map((activitiesColumn, index) => (
            <div className="card__container__columns" key={`cardActivityColumn${index}`}>
              {activitiesColumn.map((activity) => (
                <ActivitiesCard
                  key={`cardActivity${activity.id}`}
                  activity={activity}
                  userId={user_id}
                  isApply={activitiesUserOnlyId.includes(activity.id)}
                  isEnable={now <= moment(activity.deadline)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CloseActivities;
