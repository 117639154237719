import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import ReactModal from 'react-modal';

interface props {
  children: JSX.Element | JSX.Element[] | SVGElement;
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
}

const Modal = ({ children, isOpen, closeModal, title }: props) => {
  return (
    <>
      <ReactModal
        closeTimeoutMS={300}
        isOpen={isOpen}
        onRequestClose={closeModal}
        overlayClassName={'overlay'}
        className="modal-content"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        ariaHideApp={false}
      >
        {title && title.length && (
          <HeaderTitle>
            <h3 className="text-primary">{title}</h3>
          </HeaderTitle>
        )}

        {children}
        <CloseButton onClick={closeModal}>
          <AiOutlineClose />
        </CloseButton>
      </ReactModal>
    </>
  );
};
export default Modal;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
  h3 {
    width: 95%;
    font-weight: 500;
    font-size: 1.3rem;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 5vh;
  right: 0;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
  &:hover {
    background: #f2f2f2;
  }
  @media (min-width: 768px) {
    & {
      right: 13vw;
    }
  }
`;
