import {
  Box,
  chakra,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useSlider,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { MdVolumeUp } from 'react-icons/md';

interface VolumeProps {
  value: number;
  onChange: (value: number) => void;
}

export default function Volume({ value, onChange }: VolumeProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as Node;
    if (buttonRef.current && buttonRef.current.contains(target)) {
      return;
    }
    if (!popoverRef.current || !popoverRef.current.contains(target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleButtonClick = () => {
    isOpen ? onClose() : onOpen();
  };

  const { actions, getInnerTrackProps, getInputProps, getRootProps, getThumbProps, getTrackProps } = useSlider({
    min: 0,
    max: 1,
    step: 0.01,
    orientation: 'vertical',
    onChange,
    value,
  });

  const { onKeyDown: onThumbKeyDown = () => {}, ...thumbProps } = getThumbProps();

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="top" closeOnBlur={false}>
      <PopoverTrigger>
        <IconButton
          ref={buttonRef}
          onClick={handleButtonClick}
          aria-label="Enter Full Screen"
          variant="unstyled"
          color="white"
          icon={<MdVolumeUp size={30} />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="20px" ref={popoverRef} onMouseLeave={onClose}>
          <PopoverBody p={0}>
            <chakra.div cursor="pointer" h="200px" {...getRootProps()}>
              <input {...getInputProps()} hidden />

              <Box w="18px" bgColor="brand.100" {...getTrackProps()}>
                <Box w="full" bgColor="brand.500" {...getInnerTrackProps()} />
              </Box>
              <Box
                top="1%"
                bgColor="white"
                boxShadow="lg"
                _focusVisible={{
                  outline: 'none',
                }}
                onKeyDown={(e) => {
                  if (e.code === 'ArrowRight') actions.stepUp(1);
                  else if (e.code === 'ArrowLeft') actions.stepDown(1);
                  else onThumbKeyDown(e);
                }}
                {...thumbProps}
              />
            </chakra.div>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
