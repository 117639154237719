import { useTranslation } from 'react-i18next';
import config from '../../../../../config';
import Contract from './Contract';

const Description = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="card shadow  bg-white rounded">
      <div className="card-block">
        <div className="card-body">
          <h4>{config.download_contract ? t('management.management_msg11') : t('management.management_msg9')}</h4>
          {data.terms_description ? data.terms_description : t('management.management_msg10')}
        </div>
        {config.download_contract && <Contract data={data} />}
      </div>
    </div>
  );
};

export default Description;
