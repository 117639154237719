import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsDownload, BsSearch, BsUpload } from 'react-icons/bs';
import { apiCall } from '../../../../../crud/api.crud';
import ModalContract from './ModalContract';

const Contract = ({ data }) => {
  const { t } = useTranslation();

  const saveFile = () => {
    apiCall(`generateContract/volunteeringId/${data.volunteering_id}`, null, 'GET', 'blob').then((response) => {
      const content = response.data;
      saveAs(content, 'Contrato.pdf');
    });
  };

  const seeFile = () => {
    apiCall(`seeContract/volunteeringId/${data.volunteering_id}`, null, 'GET', 'blob').then((response) => {
      const content = response.data;
      saveAs(content, 'MiContrato.pdf');
    });
  };

  const [uploadModal, setUploadModal] = useState(false);

  const onClose = () => setUploadModal(false);

  return (
    <div className="d-flex justify-content-end ">
      <button className="btn btn-sm btn-primary m-2" onClick={saveFile}>
        {t('management.contract_msg')}
        <br />
        <BsDownload />
      </button>

      {data.upload_status != 0 ? (
        <button className="btn btn-sm btn-secondary m-2" onClick={seeFile}>
          {t('management.contract_msg2')}
          <br />
          <BsSearch />
        </button>
      ) : (
        <button className="btn btn-sm btn-primary m-2" onClick={() => setUploadModal(true)}>
          {t('management.contract_msg1')}
          <br />
          <BsUpload />
        </button>
      )}
      <ModalContract onClose={onClose} upload={uploadModal} volunteeringId={data.volunteering_id} />
    </div>
  );
};

export default Contract;
