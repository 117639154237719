import axios from 'axios';
import { apiCall } from 'crud/api.crud';
import { saveAs } from 'file-saver';
import useGetUserId from 'hooks/api/useGetUserId';
import styled from 'styled-components';

import { useState } from 'react';
import { TbCertificate } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'components/shared/Modal';
import Loader from 'react-spinners/MoonLoader';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 2rem;

  h4 {
    text-align: center;
  }

  p {
    text-align: center;
    color: #6c757d;
  }
`;

interface CertificateProps {
  activityId: number;
  type: string;
}
type ErrorType = 'missing_data' | 'server_error';

export function Certificate({ activityId, type }: CertificateProps) {
  const user_id = useGetUserId();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const downloadCertificate = async () => {
    setOpen(true);
    setLoading(true);

    try {
      const response = await apiCall(`downloadCertificate/${user_id}/${activityId}`, null, 'GET', 'blob');
      const content = response.data;
      saveAs(content, 'Certificate.pdf');
      setOpen(false);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;

        if (status === 422) {
          setError('missing_data');
          return;
        }
      }

      setError('server_error');
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
    setError(undefined);
  };

  return (
    <>
      <div
        className="d-flex align-items-center"
        onClick={() => {
          downloadCertificate();
        }}
      >
        {type === 'icon' ? (
          <button type="button" className="btn btn-primary btn-sm">
            <TbCertificate
              style={{
                fontSize: '18px',
              }}
            />
          </button>
        ) : (
          <>
            {' '}
            <div className="text-primary">
              <TbCertificate
                style={{
                  fontSize: '18px',
                }}
              />
            </div>
            <p className="mb-0 ml-2">Descargar certificado</p>
          </>
        )}
      </div>

      <Modal isOpen={open}>
        <Container>
          {loading && (
            <>
              <h4> {t('activities.download_certificate')} </h4>
              <Loader color="#007bff" size={40} speedMultiplier={0.55} />
            </>
          )}

          {error === 'missing_data' && (
            <>
              <h4>{t('profile.missing_data')}</h4>
              <Link to="/profile" className="btn btn-primary btn-sm" onClick={onClose}>
                {t('profile.complete')}
              </Link>
            </>
          )}

          {error === 'server_error' && (
            <>
              <div>
                <h4> {t('tour.server_error')}</h4>
                <p>{t('tour.server_error_message')}</p>
              </div>
              <button className="btn btn-primary btn-sm" onClick={onClose}>
                {t('tour.close')}
              </button>
            </>
          )}
        </Container>
      </Modal>
    </>
  );
}
