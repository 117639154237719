import { apiCall } from 'crud/api.crud';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface Field {
  id: number;
  title: string;
  type: string;
  required: boolean;
  options: Array<{ label: string; value: string }>;
}

export function CustomFields() {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation();
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/custom-fields`, null, 'GET').then(({ data }) => {
      setFields(
        data.map((item) => ({
          id: item.id,
          title: item.title,
          type: item.type,
          required: !!item.isRequired,
          options: item.options?.map((option) => ({
            label: option.value,
            value: option.value,
          })),
        })),
      );
    });
  }, []);

  return (
    <div>
      {fields.map(({ id, title, type, options, required }, index) => (
        <div key={id} className="form-group">
          <label htmlFor={`customFields.${index}`} className={`${required && 'required'}`}>
            {title}
          </label>
          <Controller
            name={`customFields.${index}`}
            control={control}
            rules={{
              required,
            }}
            render={({ field }) =>
              type === 'select' ? (
                <Select
                  options={options}
                  onChange={(item) => {
                    field.onChange({
                      id,
                      value: item.value,
                    });
                  }}
                />
              ) : (
                <input
                  className="form-control"
                  onChange={(e) => {
                    field.onChange({
                      id,
                      value: e.target.value,
                    });
                  }}
                />
              )
            }
          />
          {errors.customFields && errors.customFields[index] && (
            <p className="text-danger">{t('auth.required_fields')}</p>
          )}
        </div>
      ))}
    </div>
  );
}
