import { Config } from '../../interfaces/config.interface';
import Icon from './icono.png';
import Logo from './logo.png';

const config: Config = {
  logo: Logo,
  icon: Icon,
  title: 'Voluntariado',
  location_zoom: 3,
  location_lng: -64.18877609999998,
  location_lat: -31.4200832,
  map_top: true,
  map_bottom: false,
  users_in_map: false,
  has_hours: true,
  activities_hours_table: true,
  name: 'Fonselp',
  translation: true,
  idiom: 'es-AR',
  withoutLogin: false,
  download_contract: true,
  ods_profile: true,
  has_questions_of_interest: true,
  activities_certificate_table: false,
  profile_globant: false,
  activities: {
    card: {
      weAreIn: true,
    },
    postulate: {
      comment: true,
    },
    tabs: {
      recording: true,
    },
  },
  withState: false,
  classroom: {
    min_approved_score: 70,
  },
  theme: {
    primary: '#0b94e9',
    secondary: '#00c8ff',
  },
  publicTalents: true,
};

export default config;
