import { HStack, IconButton, Text } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';
import { Admins, Message as MessageType } from '../../../interfaces';
import useStore from '../../../store/zustand/store';
import { TextMessage } from './TextMessage';

interface Props {
  message: MessageType;
  userAdmins: Admins[];
  user_id?: number;
}

const Container = styled.div`
  background-color: ${(props) => (props.sameUser ? '#E9E9E9' : '#F5F5F5')};
  margin-bottom: 0;
  color: ${(props) => (props.isAdmin ? 'text-light' : 'text-muted')};
`;

const StyledTrashIcon = styled(BsTrash)`
  color: #808080;
  cursor: pointer;
  font-size: 14px;
`;

export const Message: FC<Props> = ({ message, userAdmins, user_id }) => {
  const user = useStore((state) => state.user);
  const sameUser = useMemo(() => message.user?.email === user?.email, [user, message]);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const isAdmin = useMemo(
    () => !sameUser && userAdmins.some((admin) => admin.name == message.user?.name),
    [user, message],
  );
  const deleteMessage = async () => {
    await axios.get(`https://chat.fonselp.com/deleteMessage`, {
      params: {
        msjId: message.id,
      },
    });
    setIsDeleted(true);
  };

  if (isDeleted) return null;
  if (message.user == null) return <p className="text-center">{message.message}</p>;

  return (
    <div className={`media w-50 mb-3 ${sameUser && 'ml-auto'}`}>
      {!sameUser && (
        <img
          src="https://somebooks.es/wp-content/uploads/2018/12/Poner-una-imagen-a-la-cuenta-de-usuario-en-Windows-10-000.png"
          alt="user"
          width="50"
          className="rounded-circle"
        />
      )}
      <div className="media-body ml-3">
        <HStack
          bg={sameUser ? '#E9E9E9' : '#F5F5F5'}
          rounded="md"
          mt={0}
          color={isAdmin ? 'text-light' : 'text-muted'}
          justifyContent="space-between"
          alignItems="start"
          p={1}
        >
          <div>
            <p className="m-0 font-weight-light small">{message?.user.name}</p>
            <TextMessage message={message?.message} />
          </div>
          {(sameUser || user?.role_name === 'admin' || user_id === user?.id) && (
            <IconButton
              icon={<MdDelete />}
              aria-label="Delete message"
              onClick={deleteMessage}
              variant="ghost"
              color="#808080"
            />
          )}
        </HStack>
        {/* </Container> */}
        <Text className="small text-muted" style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
          {moment(message.sendAt).fromNow()}
        </Text>
      </div>
    </div>
  );
};
