import { useState } from 'react';

const Text = ({ addAnswers }) => {
  const [text, setText] = useState('');

  const change = (e) => {
    setText(e.target.value);
    addAnswers(e.target.value);
  };

  return <input type="text" className="form-control" onChange={(e) => change(e)} value={text} />;
};

export default Text;
