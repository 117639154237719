import Markdown from 'modules/classroom/components/file/Markdown';
import ReactGoogleSlides from 'react-google-slides';

const PAGES_WITH_IFRAME = ['docs.google', 'drive.google'];

function parseUrl(url: string): string {
  const regexDocs = /https:\/\/docs\.google\.com\/(spreadsheets|document|presentation)\/d\/([\w-]+)\//;
  const regexPDF = /https:\/\/drive\.google\.com\/file\/d\/([\w-]+)\//;

  const matchDocs = url.match(regexDocs);
  const matchPDF = url.match(regexPDF);

  if (matchDocs) {
    return matchDocs[0] + 'preview';
  } else if (matchPDF) {
    return `https://drive.google.com/file/d/${matchPDF[1]}/preview`;
  } else {
    return url;
  }
}

export function FileViewer({ url }: { url: string }) {
  const isMobile = () => window.innerWidth <= 480;

  const containerStyle = {
    height: isMobile() ? '60vh' : '80vh',
  };
  // validate url is a markdown file
  if (url.endsWith('.md')) {
    return <Markdown url={url} />;
  }

  if (PAGES_WITH_IFRAME.some((page) => url.includes(page))) {
    if (url.includes('presentation')) {
      return <ReactGoogleSlides width="100%" containerStyle={containerStyle} slidesLink={url} showControls />;
    }

    return (
      <iframe
        src={parseUrl(url)}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{
          minHeight: '100vh',
        }}
      />
    );
  }

  return null;
}
