import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { GuideLayout } from 'modules/classroom/layout/GuideLayout';
import { LessonPage } from 'modules/classroom/pages/LessonPage';
import { QuizPage } from 'modules/classroom/pages/QuizPage';

import { GuideProvider } from 'modules/classroom/context/GuideContext';
import { NotFound } from 'modules/classroom/pages/NotFound';

interface RouteMatch {
  path: string;
  params: { id: string };
}

export function GuideRouter() {
  const { path } = useRouteMatch() as RouteMatch;

  return (
    <GuideProvider>
      <GuideLayout>
        <Switch>
          <Route path={`${path}/lesson/:lessonId`}>
            <LessonPage />
          </Route>
          <Route path={`${path}/quiz/:quizId`}>
            <QuizPage />
          </Route>
          <Route path={path}>
            <NotFound />
          </Route>
        </Switch>
      </GuideLayout>
    </GuideProvider>
  );
}
