import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsBuilding } from 'react-icons/bs';
import { FaAsterisk } from 'react-icons/fa';
import { FiMail, FiUser, FiXCircle } from 'react-icons/fi';
import Select from 'react-select';
import { AUTO_REGISTER, ID_TYPES } from 'utils/contants';
import * as yup from 'yup';
import { apiCall } from '../../../../crud/api.crud';
import '../scss/modal.scss';
import Loading from './Loading';

const schema = yup
  .object({
    email: yup
      .string()
      .email('El correo debe tener este formato ejemplo@correo.com')
      .required('Por favor escriba su correo'),
    name: yup.string().required('Por favor escriba su nombre y apellido'),
    surname: yup.string().required('Por favor escriba su apellido'),
    bussiness_name: yup.string(),
    captcha: yup.string('Por favor complete el captcha').required('Por favor complete el captcha'),
    id_number: yup.string().when([], {
      is: () => AUTO_REGISTER.includes(true),
      then: yup.string().required('Por favor seleccione el tipo.'),
      otherwise: yup.string(),
    }),
    id_type: yup.string().when([], {
      is: () => AUTO_REGISTER.includes(true),
      then: yup.string().required('Por favor escriba su número de identificación'),
      otherwise: yup.string(),
    }),
  })
  .required();

function Register({ setStep, setIsOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [status, setStatus] = useState();
  const { t } = useTranslation();

  const email = watch('email');
  const name = watch('name');
  const surname = watch('surname');
  const business = watch('bussiness_name');
  const idNumber = watch('id_number');

  const onSubmit = async ({ captcha, ...formData }) => {
    setStatus(null);
    try {
      const { data } = await apiCall('sendMailRegister', formData, 'POST');

      if (!data || data?.exist) {
        setStatus({
          message: 'auth.email_error',
          success: false,
        });
        return;
      }

      setStatus({
        message: AUTO_REGISTER.includes(true) ? 'auth.success_arcos' : 'auth.success',
        success: true,
      });
    } catch (err) {
      setStatus({
        message: 'auth.msg_error',
        success: false,
      });
    }
  };

  function close() {
    setIsOpen(false);
    setStep(1);
  }

  return (
    <div>
      <div className="modal-header border-0 pb-0">
        <h2 className="modal-title w-100 ml-4 text-center font-weight-bold" style={{ color: '#2f3237' }}>
          {t('auth.create_account')}
        </h2>
        <button type="button" className="close" data-dismiss="modal">
          <FiXCircle onClick={close} />
        </button>
      </div>

      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
          <div className="container is-column d-flex justify-content-center">
            <div className="form-group w-100">
              <label className="control-label d-flex">
                {t('auth.email')}
                <FaAsterisk className="icon-required mb-3" size=".5em"></FaAsterisk>
              </label>
              <div>
                {!email?.length && (
                  <FiMail
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                )}
                <input
                  {...register('email')}
                  type="email"
                  className="form-control input-lg rounded-pill"
                  placeholder={t('auth.EJ_email')}
                />
                <small className="text-danger">{errors.email?.message}</small>
              </div>
            </div>
            <div className="form-group w-100">
              <label className="control-label d-flex">
                {t('auth.name')}
                <FaAsterisk className="icon-required mb-3" size=".5em"></FaAsterisk>
              </label>
              <div>
                {!name?.length && (
                  <FiUser
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                )}
                <input
                  {...register('name')}
                  className="form-control input-lg rounded-pill"
                  placeholder={t('auth.EJ_name')}
                />
                <small className="text-danger">{errors.name?.message}</small>
              </div>
            </div>
            <div className="form-group w-100">
              <label className="control-label d-flex">
                {t('auth.surname')}
                <FaAsterisk className="icon-required mb-3" size=".5em"></FaAsterisk>
              </label>
              <div>
                {!surname?.length && (
                  <FiUser
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                )}
                <input
                  {...register('surname')}
                  className="form-control input-lg rounded-pill"
                  type="text"
                  placeholder={t('auth.EJ_surname')}
                />
                <small className="text-danger">{errors.surname?.message}</small>
              </div>
            </div>
            {AUTO_REGISTER.includes(true) && (
              <>
                <div className="form-group w-100">
                  <label className="control-label d-flex">
                    {t('profile.profile_msg6')}
                    <FaAsterisk className="icon-required mb-3" size=".5em"></FaAsterisk>
                  </label>
                  <div>
                    <Controller
                      name="id_type"
                      control={control}
                      render={({ field }) => (
                        <Select options={ID_TYPES} onChange={(item) => field.onChange(item.value)} />
                      )}
                    />

                    <small className="text-danger">{errors.id_number?.message}</small>
                  </div>
                </div>
                <div className="form-group w-100">
                  <label className="control-label d-flex">
                    {t('profile.profile_msg7')}
                    <FaAsterisk className="icon-required mb-3" size=".5em"></FaAsterisk>
                  </label>
                  <div>
                    {!idNumber?.length && (
                      <FiUser
                        style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                        color="#8f96a3"
                        size="1.2em"
                      />
                    )}
                    <input {...register('id_number')} className="form-control input-lg rounded-pill" type="text" />
                    <small className="text-danger">{errors.id_number?.message}</small>
                  </div>
                </div>
              </>
            )}
            <div className="form-group w-100">
              <label className="control-label">{t('auth.organization')}</label>
              <div>
                {!business?.length && (
                  <BsBuilding
                    style={{ margin: '.55em .5em .5em .7em', position: 'absolute' }}
                    color="#8f96a3"
                    size="1.2em"
                  />
                )}
                <input
                  {...register('bussiness_name')}
                  type="text"
                  className="form-control input-lg rounded-pill"
                  placeholder="     Ej: Civic House"
                />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center form-group text-center">
              <Controller
                name="captcha"
                control={control}
                render={({ field }) => <ReCAPTCHA sitekey="6Le9v-8eAAAAAPRQnMKKLydeeQnlJe2WRY4oTUJB" {...field} />}
              />
              <small className="text-danger">{errors.captcha?.message}</small>
            </div>
            <div className="text-center">
              {status?.message && (
                <span className={status?.success ? 'text-success' : 'text-danger'}>{t(status?.message)}</span>
              )}
            </div>
          </div>

          <div className="form-group text-center">
            {isSubmitting ? (
              <Loading />
            ) : (
              <button
                type="submit"
                className="btn rounded-pill p-2 mt-3"
                style={{ width: '90%', backgroundColor: '#0093E9', color: 'white' }}
                disabled={isSubmitting || status?.success}
              >
                {t('auth.next')}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center p-0">
        <button
          className="btn btn-link btn-outline-"
          onClick={() => setStep(1)}
          style={{
            color: '#8f96a3',
            fontSize: '.89em',
            cursor: 'pointer',
          }}
        >
          <b style={{ color: '#0093E9' }}>{t('auth.back_login')}</b>
        </button>
      </div>
    </div>
  );
}

export default Register;
