import { useEffect, useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

interface MarkdownProps {
  url: string;
}

export default function Markdown({ url }: MarkdownProps) {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!url.startsWith('https://raw')) return;

    fetch(url)
      .then((resp) => {
        if (resp.ok) {
          return resp.text();
        }
        throw new Error('Network response was not ok');
      })
      .then((text) => setContent(text))
      .catch(() => setContent(''));
  }, []);

  return (
    <div className="px-4">
      <MarkdownPreview
        source={content}
        wrapperElement={{
          'data-color-mode': 'light',
        }}
      />
    </div>
  );
}
