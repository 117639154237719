import styled from '@emotion/styled';

import useStore from 'store/zustand/store';

const MIN_SCORE = 7;

const ResultsChallenges = () => {
  const { skills } = useStore((state) => state.user);

  const validSkills = skills.filter(({ evaluation }) => evaluation && evaluation >= MIN_SCORE);
  const pendingSkills = skills.filter(({ evaluation }) => !evaluation);
  const rejectedSkills = skills.filter(({ evaluation }) => evaluation && evaluation < MIN_SCORE);

  return (
    <section className="container mt-4">
      <div className="card shadow  bg-white rounded">
        <div className="card-header">Resultados de Validacion</div>
        <div className="card-block">
          <div className="card-body">
            <div className="row p-2">
              <div className="col-lg-4 mb-4">
                <Badge className="success">
                  Validados
                  {skills.length && (
                    <span>
                      {validSkills.length} / {skills.length}
                    </span>
                  )}
                </Badge>
                <SkillsContainer>
                  {validSkills.map(({ title, id }) => (
                    <div key={`skill-${id}`}>{title}</div>
                  ))}
                </SkillsContainer>
              </div>
              <div className="col-lg-4 mb-4">
                <Badge className="info">
                  Pendiente de validacion
                  {skills.length && (
                    <span>
                      {pendingSkills.length} / {skills.length}
                    </span>
                  )}
                </Badge>
                <SkillsContainer>
                  {pendingSkills.map(({ title, id }) => (
                    <div key={`skill-${id}`}>{title}</div>
                  ))}
                </SkillsContainer>
              </div>
              <div className="col-lg-4 mb-4">
                <Badge className="danger">
                  Rechazados
                  {skills.length && (
                    <span>
                      {rejectedSkills.length} / {skills.length}
                    </span>
                  )}
                </Badge>
                <SkillsContainer>
                  {rejectedSkills.map(({ title, id }) => (
                    <div key={`skill-${id}`}>{title}</div>
                  ))}
                </SkillsContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsChallenges;

const Badge = styled.div`
  border-radius: 0.35rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1;
  padding: 0.25rem 1rem;
  background-color: #e9ecef;
  color: #212529;
  width: fit-content;

  &.success {
    background-color: #01bd57;
    color: #fff;
  }

  &.info {
    background-color: #0837a1;
    color: #fff;
  }

  &.danger {
    background-color: #e90000;
    color: #fff;
  }

  span {
    font-weight: 600;
    font-size: 0.775rem;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;
