import { useTranslation } from 'react-i18next';
import Select from '../../../../../molecules/Form/components/Select';
import countries from '../hook/countries.json';

const nationalityTypes = countries;

const NationalitySelect = () => {
  const { t } = useTranslation();
  return (
    <Select
      label={t('profile.profile_msg9')}
      name="country_id"
      options={nationalityTypes}
      validations={[{ key: 'required', val: true }]}
    />
  );
};

export default NationalitySelect;
