import { useTranslation } from 'react-i18next';
import { MdAddBox, MdCheckCircle, MdComment, MdRemoveRedEye } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import config from '../../../../../config';
import { ActivityParse, Survey } from '../../../../../interfaces/activity';
import { actions as modalAction } from '../../../../../store/ducks/modal.duck';
import { Certificate } from 'components/pages/Private/Activities/components/Certificate';

const iconStyle = {
  fontSize: '18px',
};
interface props {
  row: ActivityParse;
  handleClick: (id: number) => void;
  type?: string;
}

const ActionsButtons = ({ row, handleClick, type = 'icon' }: props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commentaryModal = (activity_id: number, survey: Survey | null, estimated_hours: number): void => {
    dispatch(
      modalAction.modalShow({
        modalProps: {
          open: true,
          activity_id,
          url: 'activity/addCommentary',
          survey,
          estimated_hours,
        },
        modalType: 'commentary',
      }),
    );
  };

  const hoursModal = ({ id, estimated_hours }: ActivityParse): void => {
    const title =
      estimated_hours !== 0 ? 'Completar Actividad' : config.exception ? 'Registrar acción' : 'Carga de horas';
    dispatch(
      modalAction.modalShow({
        modalProps: {
          open: true,
          title,
          activity_id: id,
        },
        modalType: 'hoursLoad',
      }),
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-start justify-content-md-between w-100 px-1">
        <button className="btn btn-primary btn-sm" onClick={() => handleClick(row.id)}>
          {type === 'text' ? t('activities.activity_17') : <MdRemoveRedEye style={iconStyle} />}
        </button>
        {config.has_hours &&
          row.selection_status === t('activities.status_msg3') &&
          row.automate_hours_calculation !== true && (
            <>
              {row.estimated_hours !== 0 ? (
                <button
                  type="button"
                  className="act-hora-reactour btn btn-primary btn-sm mx-1 mx-lg-0"
                  onClick={() => {
                    hoursModal(row);
                  }}
                >
                  {type === 'text' ? 'completar' : <MdCheckCircle />}
                </button>
              ) : (
                <button
                  type="button"
                  className="act-hora-reactour btn btn-primary btn-sm mx-1 mx-lg-0"
                  onClick={() => {
                    hoursModal(row);
                  }}
                >
                  {type === 'text' ? t('activities.activity_18') : <MdAddBox style={iconStyle} />}
                </button>
              )}
            </>
          )}
        {!row.commentary &&
          row.status === t('activities.status_msg9') &&
          (row.status_postulation == 2 || row.status_postulation == 4 || row.status_postulation == 5) && (
            <>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  commentaryModal(row.id, row.survey, row.estimated_hours);
                }}
              >
                {type === 'text' ? t('activities.activity_19') : <MdComment style={iconStyle} />}
              </button>
            </>
          )}

        {row.status_postulation == 7 && !config?.activities_certificate_table && (
          <Certificate activityId={row.id} type="icon" />
        )}
      </div>
    </>
  );
};

export default ActionsButtons;
