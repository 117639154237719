import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import config from 'config';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { AiOutlineDownload } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { DownloadCertificate } from 'modules/classroom/components/DownloadCertificate';

export function CourseEnd() {
  const { guide } = useGuideContext();

  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  return (
    <Box p={4}>
      <Stack
        spacing={12}
        align="center"
        justify="center"
        minH="75vh"
        textAlign="center"
        border="2px solid"
        borderColor="brand.500"
      >
        <Heading fontWeight="semibold">{t('finish.title')} </Heading>

        <div>
          {guide.hasCertificate && (
            <Text fontWeight="semibold" fontSize="2xl">
              {t('finish.description')}{' '}
            </Text>
          )}
          <Text fontWeight="semibold" fontSize="2xl">
            {guide.title}
          </Text>
        </div>

        <Image src={config.logo} maxW="300px" />

        <DownloadCertificate leftIcon={<AiOutlineDownload />}>{t('download_certificate')}</DownloadCertificate>
      </Stack>
    </Box>
  );
}
