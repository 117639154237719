import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityHours, ActivityVolunteer } from '../../../../../interfaces/activity';

interface props {
  data: ActivityVolunteer[];
  loading: boolean;
}

const ActionsLog = ({ data, loading }: props) => {
  const { t } = useTranslation();
  const [dataParsed, setDataParsed] = useState<ActivityHours[] | []>([]);
  const now = moment();

  useEffect(() => {
    const parseData = () => {
      let dataFilter: ActivityHours[] = [];
      for (let u of data) {
        if (u.activity_hours && u.activity_hours.length) {
          dataFilter = [...dataFilter, ...u.activity_hours];
          if (dataFilter.length) setDataParsed(dataFilter.filter((u) => u.commentary.length));
        }
      }
    };
    parseData();
  }, []);

  const timestamp = (date: string, maxDif = 7): string => {
    const d = moment(date);
    const result = now.diff(d, 'days');
    if (result > maxDif || result < 0) return d.format('MMMM DD - YYYY');
    return moment(date).fromNow();
  };

  if (loading) return <span className="mt-5">Buscando...</span>;
  return (
    <>
      {!dataParsed.length ? (
        <p className="mt-5">{t('activities.activity_actionsNull')}</p>
      ) : (
        <div>
          <ul className="list-group" style={{ overflowY: 'auto', maxHeight: '600px' }}>
            {dataParsed.map((user, index) => (
              <li key={`user-${index}`} className="list-group-item pt-2 pl-2 pb-0 pr-0">
                <p className="m-0">{user.commentary}</p>
                <span className="text-muted w-100 text-right" style={{ fontSize: 12 }}>
                  {timestamp(user.created_at)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ActionsLog;
