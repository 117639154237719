import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { Player } from '../components/player/Player';
import { FileViewer } from 'modules/classroom/components/file/Viewer';
import { Lesson, Resource as ResourceType } from 'interfaces/guide.interface';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { NextLesson } from 'modules/classroom/components/NextLesson';
import { useGuide } from 'modules/classroom/hooks/useGuide';
import { useTranslation } from 'react-i18next';
import { FileDownloader } from 'modules/classroom/components/file/Downloader';

interface Params {
  lessonId: string;
  id: string;
}

export function LessonPage() {
  const { guide } = useGuideContext();
  const { lessonIsActive } = useGuide();
  const { topics = [] } = guide;
  const { id, lessonId } = useParams<Params>();
  const [lesson, setLesson] = useState<Lesson>();
  const history = useHistory();

  useEffect(() => {
    const lessons = topics?.flatMap(({ chapters }) => chapters).flatMap(({ lessons }) => lessons);

    const lesson = lessons.find((lesson) => lesson.id === Number(lessonId));

    if (!lesson) return;

    if (!lessonIsActive(Number(lessonId))) {
      history.push(`/detail/${id}/guide/lesson/${lessons[0].id}`);
      return;
    }

    setLesson(lesson);
  }, [lessonId]);

  if (!lesson) return <NotFound />;

  return (
    <Stack>
      {lesson?.resources?.map((resource) => (
        <Resource key={resource.id} resource={resource} />
      ))}
    </Stack>
  );
}

interface ResourceProps {
  resource: ResourceType;
}

function Resource({ resource }: ResourceProps) {
  const { id, video_url, file_path, completed_at } = resource;
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const [videoEnded, setVideoEnded] = useState(resource.completed_at || !resource.video_url);

  return (
    <Stack spacing={8}>
      {!!video_url?.length && (
        <Player url={video_url} resource_id={id} completed_at={completed_at} onEnd={() => setVideoEnded(true)} />
      )}
      {!!file_path?.length && (
        <Stack spacing={8} alignItems="flex-start" pb={8}>
          <FileViewer url={file_path} />
          <Flex gap={4} px={4}>
            {videoEnded && (
              <NextLesson resource_id={id} completed_at={completed_at}>
                {t('next_lesson')}
              </NextLesson>
            )}
            <FileDownloader url={file_path} />
          </Flex>
        </Stack>
      )}
    </Stack>
  );
}

function NotFound() {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  return (
    <Stack h="50vh" align="center" justify="center">
      <Heading>{t('not_found.title')}</Heading>
      <Text>{t('not_found.description')}</Text>
    </Stack>
  );
}
