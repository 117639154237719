// import "./idiom.scss";
// import {HiTranslate} from "react-icons/hi";
// import {useTranslation} from 'react-i18next'
// import config from '../../../../config';

// const Idiom = () => {

//     const { i18n } = useTranslation()

//     const changeLanguage = (language) =>{
//         i18n.changeLanguage(language)
//     }

// console.log(localStorage.getItem("i18nextLng"))
//   return (
//     <div className="dropdown">
//         <button className="dropbtn ml-3"><HiTranslate className="icon " /></button>
//         <div className="dropdown-content">
//             <a href="#" onClick={()=> changeLanguage(config.idiom)}>es</a>
//             <a href="#" onClick={()=> changeLanguage("en")}>en</a>
//         </div>
//     </div>

//   );
// };

// export default Idiom;

import React, { useEffect, useMemo, useState } from 'react';
import { MdLanguage } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

// import i18next from "i18next";i18nexti18next
import config from '../../../../config';

import './idiom.scss';

const Idiom = () => {
  const { i18n } = useTranslation();
  const i18nextLng = localStorage.getItem('i18nextLng');
  const [lng, setLng] = useState(i18nextLng || 'es-AR');

  const changeLanguage = (idiom) => {
    i18n.changeLanguage(idiom);
    setLng(idiom);
  };

  const languageName = useMemo(() => {
    if (lng === 'es-AR') return 'Español Argentina';
    if (lng === 'en') return 'English';
    if (lng === 'br') return 'Portuguese';
    if (lng === 'fr') return 'Frances';
    if (lng === 'es') return 'Español';
    return 'Español Mexico';
  }, [lng]);

  useEffect(() => {
    localStorage.setItem('i18nextLng', lng);
  }, [lng]);

  return (
    // <div className="dropdown">
    //     <button className="dropbtn ml-3"><HiTranslate className="icon"/></button>
    //     <select className="dropdown-content"
    //       id="select"
    //       onChange={changeLanguage}
    //       value={lng}
    //     >
    //         <option className="dropdown-item" value={config.idiom}>es</option>
    //         <option className="dropdown-item" value="en" >en</option>
    //     </select>
    // </div>
    <div className="dropdown">
      <button className="btn btn-ghost">
        <MdLanguage className="icon" />
        <span className="d-lg-none">{languageName}</span>
        <span className="d-none d-lg-block">{lng}</span>
      </button>
      <div className="dropdown-content">
        <span onClick={() => changeLanguage(config.idiom)}>
          Español {config.profile_globant ? '' : config.idiom === 'es-AR' ? '(Argentina)' : '(Mexico)'}
        </span>
        <span onClick={() => changeLanguage('en')}>English</span>
        {!config.profile_globant && <span onClick={() => changeLanguage('br')}>Portuguese</span>}
        {!config.profile_globant && <span onClick={() => changeLanguage('fr')}>Frances</span>}
      </div>
    </div>
  );
};

export default React.memo(Idiom);
