import {
  Box,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { RiSendPlaneFill } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as LinkRouter, useHistory, useParams } from 'react-router-dom';
import { useSocketContext } from '../../../context/SocketContext';
import { Message } from './Message';
const maxLength: number = 125;

export const Chat: FC = () => {
  const { activityId } = useParams<{ activityId: string }>();
  const history = useHistory();
  const { client, selectRoom, currentRoom, handleRoomMessages, activities, userAdmins, rooms } = useSocketContext();
  const [hasMore, setHasMore] = useState(true);
  const [lastMessage, setLastMessage] = useState();
  const [value, setValue] = useState('');

  const innerRef = useRef(null);
  const [charCount, setCharCount] = useState<number>(0);
  const activity = useMemo(() => activities.find((ac) => ac.id === parseInt(activityId)), [activityId]);

  useEffect(() => {
    try {
      selectRoom(+activityId);
    } catch (err) {
      console.log(err);

      history.push('/chat');
    }
  }, [activityId, rooms]);

  useEffect(() => {
    const getInitialMessages = async () => {
      try {
        const { data } = await axios.get('https://chat.fonselp.com/lastMessage', {
          params: {
            roomId: currentRoom?.id,
          },
        });

        if (data && data.length) {
          const dataParse = data.map((d) => ({
            message: d.value,
            room_id: d.room_id,
            sendAt: d.send_at,
            id: d.id,
            user: {
              email: d.email,
              name: d.name,
            },
          }));
          const sorted = dataParse.sort(function (left, right) {
            return moment.utc(right.sendAt).diff(moment.utc(left.sendAt));
          });
          handleRoomMessages(sorted);
          setLastMessage(sorted[sorted.length - 1].id);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        setHasMore(false);
      }
    };

    if (currentRoom?.id) {
      getInitialMessages();
    }

    return () => {
      setHasMore(true);
    };
  }, [currentRoom?.id]);

  const fetchMoreData = async () => {
    try {
      // const { data } = await axios.get(`${process.env.REACT_APP_CHAT_URL}/moreMessage`, {
      const { data } = await axios.get(`https://chat.fonselp.com/moreMessage`, {
        params: {
          msjId: lastMessage,
        },
      });

      if (data && data.length) {
        const dataParse = data.map((d) => ({
          message: d.value,
          room_id: d.room_id,
          sendAt: d.send_at,
          id: d.id,
          user: {
            email: d.email,
            name: d.name,
          },
        }));
        const sortLast = dataParse.sort(function (left, right) {
          return moment.utc(right.sendAt).diff(moment.utc(left.sendAt));
        });
        handleRoomMessages(sortLast);

        setLastMessage(sortLast[sortLast.length - 1].id);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.log('error');
      setHasMore(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (value.length && value.length <= maxLength) {
      client?.send(
        JSON.stringify({
          action: 'send-message',
          message: value,
          target: {
            id: currentRoom?.id,
            name: currentRoom?.name,
          },
        }),
      );
      setValue('');
    }
  };

  return (
    <Box w="100%">
      <Stack borderBottom="1px" borderColor="gray.200">
        <Link
          as={LinkRouter}
          to={`/detail/${activity?.id}`}
          d="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          py={4}
          _hover={{
            color: 'brand.500',
            textDecoration: 'none',
          }}
        >
          <Heading fontWeight="semibold" as="h4" size="md" textAlign="center">
            {activity?.title}
            <ArrowReturnIcon transform="scalex(-1)" size="md" fontWeight="semibold" ml={2} />
          </Heading>
        </Link>
      </Stack>
      <Box
        px={4}
        py={1}
        className="chat-box"
        bg="white"
        overflowY="auto"
        height="50vh"
        display="flex"
        flexDirection="column-reverse"
        ref={innerRef}
        id="scrollableDiv"
      >
        <InfiniteScroll
          dataLength={currentRoom?.messages.length || 0}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
          hasMore={hasMore}
          loader={<Text textAlign="center">Loading...</Text>}
          endMessage={
            <Text textAlign="center" color="gray.500">
              No encontramos más mensajes.
            </Text>
          }
          scrollableTarget="scrollableDiv"
        >
          {currentRoom?.messages.map((message, index) => (
            <Message
              key={`${message.sendAt}-${message.message}-${index}`}
              message={message}
              user_id={activity?.user_id}
              userAdmins={userAdmins}
            />
          ))}
        </InfiniteScroll>
      </Box>
      <form onSubmit={onSubmit}>
        <InputGroup my={6}>
          <Input
            placeholder="Escriba un mensaje..."
            onChange={(e) => {
              setCharCount(e.target.value.length);
              setValue(e.target.value);
            }}
            value={value}
          />
          <InputRightElement>
            <IconButton
              id="button-addon2"
              aria-label="send"
              size="sm"
              type="submit"
              disabled={value.length > maxLength}
              icon={<RiSendPlaneFill size={24} />}
            ></IconButton>
          </InputRightElement>
        </InputGroup>
        <Text
          textAlign="right"
          fontSize="sm"
          mt={2}
          pr={5}
          color={charCount > maxLength ? 'red.500' : charCount >= maxLength - 25 ? 'yellow.500' : 'gray.700'}
        >
          {charCount} de {maxLength}
        </Text>
      </form>
    </Box>
  );
};

const ArrowReturnIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M9,4 L4,9 L9,14 M18,19 L18,9 L5,9"
        transform="matrix(1 0 0 -1 0 23)"
      ></path>
    </Icon>
  );
};
