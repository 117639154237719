import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import styled from 'styled-components';

import config from '../../../../../config';
import { useIsMedium } from '../../../../../utils/queries';
import TableSearch from './TableSearch';
import { ActivityParse } from '../../../../../interfaces/activity';
import { Certificate } from 'components/pages/Private/Activities/components/Certificate';
import ActionsDropdown from './ActionsDropdown';
import './_activityTable.scss';

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 2px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 940px) {
    margin: 0;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

interface props {
  data: ActivityParse[];
  handleClick: (id: number) => void;
  filter: any;
  setFilter: any;
}

const ActivityTable = ({ data, handleClick }: props) => {
  const { t } = useTranslation();
  const isMedium = useIsMedium();

  const columns: IDataTableColumn<ActivityParse>[] = useMemo(() => {
    const array: IDataTableColumn<ActivityParse>[] = [
      {
        name: t('activities.activities_msg11'),
        selector: 'activity',
        sortable: true,
        cell: function cell(row) {
          return <Link to={row.url}>{row.activity}</Link>;
        },
      },
      {
        name: t('activities.activities_msg2'),
        selector: 'status',
        sortable: true,
        center: true,
        hide: 'md',
      },
      {
        name: t('activities.activities_msg3'),
        selector: 'selection_status',
        sortable: true,
        center: true,
        hide: 'md',
      },
      {
        name: t('activities.activities_msg6'),
        selector: 'deadline',
        sortable: true,
        center: true,
        hide: 'md',
      },
      {
        name: t('activities.activities_msg2'),
        sortable: true,
        center: true,
        omit: !isMedium,
        cell: function cell(row) {
          return (
            <div className="d-flex flex-column align-items-center justify-content-start w-100">
              <span className="text-left">{row.status}</span>
              <span className="text-left">{row.selection_status}</span>
            </div>
          );
        },
      },
    ];

    if (config.activities_hours_table) {
      array.push(
        ...[
          {
            name: t('activities.activities_msg9'),
            selector: 'hours_individual',
            sortable: true,
            center: true,
          },
          {
            name: t('activities.minutes_individual'),
            selector: 'minutes_individual',
            sortable: true,
            center: true,
          },
          {
            name: t('activities.activities_msg10'),
            selector: 'hours_total',
            sortable: true,
            center: true,
            omit: isMedium,
          },
        ],
      );
    }
    if (config?.activities_certificate_table) {
      array.push({
        name: t('activities.certificate'),
        cell: function cell(row) {
          if (row.status_postulation !== 7) return null;
          return <Certificate activityId={row.id} type="icon" />;
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      });
    }

    array.push({
      name: t('activities.activities_msg4'),
      cell: function cell(row) {
        return <ActionsDropdown row={row} handleClick={handleClick} />;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    });

    return array;
  }, [isMedium]);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data.filter(
    (item) => item.activity && item.activity.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Container>
        <TableSearch onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      data={filteredItems}
      columns={columns}
      className="data-table-activities table-style"
      highlightOnHover
      pointerOnHover
      striped
      noHeader
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
    />
  );
};

export default ActivityTable;
