import { extendTheme } from '@chakra-ui/react';

import { Button, Modal, Accordion, Drawer } from './components';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#EDF8FC',
      100: '#DBF1FA',
      200: '#AADDF3',
      300: '#75C8EB',
      400: '#2CAAE1',
      500: '#2DAAE1',
      600: '#1B8BBC',
      700: '#177BA5',
      800: '#14678A',
      900: '#0F4C67',
      950: '#0A3547',
    },
  },
  components: {
    Button,
    Accordion,
    Modal,
    Drawer,
  },
});

export { theme };
