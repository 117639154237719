import { Checkbox, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Lesson as LessonType } from 'interfaces/guide.interface';
import { MdOutlineInventory, MdOutlineMenuBook } from 'react-icons/md';
import { RiVideoLine, RiListCheck3 } from 'react-icons/ri';
import { useMemo } from 'react';
import { useGuide } from 'modules/classroom/hooks/useGuide';

interface LessonProps {
  lesson: LessonType;
}

const LessonIcon = {
  video: RiVideoLine,
  test: MdOutlineInventory,
  reading: MdOutlineMenuBook,
};

export function SidebarItem({ lesson }: LessonProps) {
  const { lessonIsActive } = useGuide();
  const { id: lessonId, resources = [], title, description, quiz } = lesson || {};
  const { pathname } = useLocation();
  const { id: activityId } = useParams<{ id: string }>();

  const isActive = lessonIsActive(lesson.id);

  const lessonActive = useMemo(() => {
    const isLesson = pathname.includes('lesson');

    if (!isLesson) return false;

    const lessonId = pathname.split('/').pop();

    return lessonId === String(lesson.id);
  }, [pathname]);

  const quizActive = useMemo(() => {
    const isQuiz = pathname.includes('quiz');

    if (!isQuiz) return false;

    const quizId = pathname.split('/').pop();

    return quizId === String(quiz?.id);
  }, [pathname]);

  return (
    <>
      <SimpleGrid
        as={isActive ? Link : 'div'}
        role="button"
        _hover={{ bg: 'gray.100' }}
        p={2}
        color="brand.500"
        columns={3}
        templateColumns="auto 1fr auto"
        gap={3}
        bgColor={lessonActive ? 'gray.100' : undefined}
        opacity={isActive ? 1 : 0.6}
        cursor={isActive ? 'pointer' : 'not-allowed'}
        {...(isActive && { to: `/detail/${activityId}/guide/lesson/${lessonId}` })}
      >
        <Stack align="start">
          {resources?.map(({ file_path, video_url }) => {
            const types: string[] = [];

            if (file_path?.length) types.push('reading');
            if (video_url?.length) types.push('video');

            return types.map((type) => {
              const Icon = LessonIcon[type];
              return <Icon key={type} width={5} height={5} />;
            });
          })}
        </Stack>

        <div>
          <Text>{title}</Text>
          <Text fontSize="xs" color="gray.500">
            {description}
          </Text>
        </div>
        <Checkbox colorScheme="brand" isChecked={resources.some((r) => r.completed_at)} />
      </SimpleGrid>

      {quiz && (
        <SimpleGrid
          as={isActive ? Link : 'div'}
          role="button"
          _hover={{ bg: 'gray.100' }}
          p={2}
          columns={2}
          templateColumns="auto 1fr auto"
          gap={3}
          color="black"
          bgColor={quizActive ? 'gray.100' : undefined}
          opacity={isActive ? 1 : 0.6}
          cursor={isActive ? 'pointer' : 'not-allowed'}
          {...(isActive && { to: `/detail/${activityId}/guide/quiz/${quiz.id}` })}
        >
          <Icon as={RiListCheck3} width={5} height={5} />
          <Text>{quiz.name}</Text>
          <Checkbox isChecked={!!quiz.answer} colorScheme={Number(quiz.answer?.score) >= 70 ? 'brand' : 'red'} />
        </SimpleGrid>
      )}
    </>
  );
}
