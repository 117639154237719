const createTour = (set) => ({
  checkedTour: (api_tour) => {
    set((state) => ({
      user: {
        ...state.user,
        api_tour,
      },
    }));
  },
});

export default createTour;
