import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import validationsConstraints from '../validations';

interface Props {
  formContext: any;
  validations: { key: string; val: boolean; optional?: any }[];
  name: string;
  value: string;
  setValue?: ((data: string) => void) | boolean;
}

interface IputValidations {
  triggerValidations: (values?: any) => IValid;
  isValid: IValid;
  setIsValid: Dispatch<SetStateAction<IValid>>;
}

interface IValid {
  error_message: string;
  valid: boolean;
}

const useInputValidations = ({ formContext, validations, name, value, setValue = false }: Props): IputValidations => {
  // manejo de estado
  const [isValid, setIsValid] = useState<IValid>({
    error_message: '',
    valid: true,
  });

  // devuelve el resultado de la validación por campo
  const triggerValidations = (values): IValid => {
    let validationResult: IValid = {
      error_message: '',
      valid: true,
    };
    if (!validations) return validationResult;
    validations.forEach(({ key, val, optional }) => {
      if (validationResult.valid) {
        validationResult = validationsConstraints(key, val, value, formContext.values, optional) as IValid;
      }
    });
    return validationResult;
  };

  // ejecuta el dispatch con la validación del input
  const dispatchValidationsResult = (validationResult) => {
    if (validationResult.valid) {
      formContext.onValidate({ type: 'valid_input', inputName: name });
    } else {
      formContext.onValidate({ type: 'invalid_input', inputName: name });
    }
  };

  // ejecuta el dispatch cada vez que haya cambios de validación del input
  useEffect(() => {
    dispatchValidationsResult(isValid);
  }, [isValid]);

  useEffect(() => {
    if (typeof value === 'object') {
      formContext.updateValueObjects(value);
    } else {
      formContext.updateValue(name, value);
    }
  }, [value]);

  useEffect(() => {
    const defaultValue = formContext.getDefaultValue(name);
    if (defaultValue === undefined || defaultValue === null) return;
    if (typeof setValue === 'function') {
      if (typeof defaultValue === 'object') {
        setValue({ ...defaultValue });
      } else {
        setValue(defaultValue);
      }
    }
  }, []);

  // escucha cuando se ejecuta el submit para disparar las validaciones
  useEffect(() => {
    if (formContext.checkSubmit) {
      setIsValid(triggerValidations(value));
      formContext.setCheckSubmit(false);
    }
    dispatchValidationsResult(triggerValidations(value));
  }, [formContext.checkSubmit, value]);

  return {
    triggerValidations,
    isValid,
    setIsValid,
  };
};

export default useInputValidations;
