import { useTranslation } from 'react-i18next';
import Select from '../../../../../molecules/Form/components/Select';

const GenderSelect = () => {
  const { t } = useTranslation();

  const genderTypes = [
    { label: t('profile.gender_msg'), value: 'Masculino' },
    { label: t('profile.gender_msg2'), value: 'Femenino' },
    { label: t('profile.gender_msg3'), value: 'No binario' },
    { label: t('profile.gender_msg4'), value: 'Prefiero no decirlo' },
    { label: t('profile.gender_msg5'), value: 'Otro' },
  ];

  return (
    <Select
      label={t('profile.profile_msg5')}
      name="gender"
      options={genderTypes}
      validations={[{ key: 'required', val: true }]}
    />
  );
};
export default GenderSelect;
