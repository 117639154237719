import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Drawer: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    closeButton: {
      border: 'none',
      outline: 'none',
      bg: 'transparent',
    },
  },
  // Two sizes: sm and md
  sizes: {},
  variants: {},
  // The default size and variant values
  defaultProps: {},
};
