import './linear-scale.scss';
const LinearScale = ({ addAnswers }) => {
  const change = (e) => {
    addAnswers(e.target.value);
  };

  return (
    <div className="wrap">
      <ul className="inputs">
        <li>
          <input type="radio" name="input" onClick={change} value="1" />
          <label>1</label>
        </li>
        <li>
          <input type="radio" name="input" onClick={change} value="2" />
          <label>2</label>
        </li>
        <li>
          <input type="radio" name="input" onClick={change} value="3" />
          <label>3</label>
        </li>
        <li>
          <input type="radio" name="input" onClick={change} value="4" />
          <label>4</label>
        </li>
        <li>
          <input type="radio" name="input" onClick={change} value="5" />
          <label>5</label>
        </li>
      </ul>
    </div>
  );
};

export default LinearScale;
