import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Sidebar } from 'modules/classroom/components/navbar/Sidebar';

import { Guide } from 'interfaces/guide.interface';
import { useTranslation } from 'react-i18next';

interface MobileSidebarProps {
  guide: Guide;
}

export function MobileSidebar({ guide }: MobileSidebarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  return (
    <>
      <Button onClick={onOpen} display={{ base: 'block', lg: 'none' }} size="sm">
        {t('content')}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('content')}</DrawerHeader>

          <DrawerBody>
            <Sidebar guide={guide} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
