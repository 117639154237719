import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { default as ReactSelect } from 'react-select';
import { useFormContext } from '../FormContext';
import useInputValidations from '../hooks/useInputValidations';

const getDefaultOptions = (options, value) => {
  if (!value) return null;
  const defaultOptions = options.find((option) => option.value === value);

  return defaultOptions;
};

interface Props {
  label: string;
  name: string;
  options: { value: string; label: string }[] | undefined;
  validations: { key: string; val: boolean }[];
  customFunction?: (value: string) => void;
  placeholder?: string;
}

interface Field {
  focus: () => void;
}

const Select = ({ label, name, validations, options, customFunction, placeholder }: Props) => {
  const { t } = useTranslation();

  const [field, setField] = useState<Field>();
  const [value, setValue] = useState('');
  const formContext = useFormContext();
  // custom hook: useInputValidations
  const { setIsValid, triggerValidations, isValid } = useInputValidations({
    formContext,
    validations,
    name,
    value,
    setValue,
  });

  const handleBlur = () => {
    setIsValid(triggerValidations());
  };

  useEffect(() => {
    if (name === formContext.focus && field) {
      field.focus();
    }
  }, [formContext.focus, name, field]);

  const addValue = (t) => {
    setValue(t.value);
    if (customFunction) customFunction(t.value);
  };
  return (
    <div className="input-container was-validated">
      {validations[0] ? (
        <label className="input-label is_required" onClick={() => field!.focus()}>
          {label}
        </label>
      ) : (
        <label className="input-label" onClick={() => field!.focus()}>
          {label}
        </label>
      )}
      {options ? (
        <ReactSelect
          menuPortalTarget={document.getElementById('root')}
          value={getDefaultOptions(options, value)}
          placeholder={placeholder}
          defaultValue
          options={options}
          onChange={addValue}
          onBlur={handleBlur}
          ref={setField}
          name={name}
          className="form-select"
        />
      ) : (
        <ReactSelect isDisabled placeholder={placeholder} />
      )}

      {!isValid.valid && (
        <div>
          <span className="error_message">{isValid.error_message}</span>
        </div>
      )}
    </div>
  );
};

export default Select;
