import { useTranslation } from 'react-i18next';
import Select from '../../../../../molecules/Form/components/Select';
import states from 'assets/states-cessi.json';
import { useState } from 'react';
import useStore from 'store/zustand/store';

interface Option {
  value: string;
  label: string;
}

interface State extends Option {
  localidades: Option[];
}

const StateOfArSelect = () => {
  const { t } = useTranslation();
  const { user } = useStore((state) => state);

  const [state, setState] = useState<State | undefined>(() => {
    const volunteeringFields = user?.volunteeringFields;
    if (!volunteeringFields) return;
    return states.find(({ value }) => value === volunteeringFields?.state_id);
  });

  const handleState = (value: string) => {
    setState(states.find((state) => state.value === value));
  };

  return (
    <div className="row">
      <div className="col-6">
        <Select
          label={t('profile.profile_msg16')}
          name="state_id"
          options={states}
          validations={[{ key: 'required', val: true }]}
          customFunction={handleState}
        />
      </div>

      <div className="col-6">
        <Select
          label={t('profile.locality')}
          name="geolocation_cessi_id"
          options={state?.localidades}
          validations={[{ key: 'required', val: true }]}
          placeholder={!state ? 'Selecciona una Provincia' : 'Selecciona una Localidad'}
        />
      </div>
    </div>
  );
};

export default StateOfArSelect;
