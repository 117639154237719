import LinearScale from './LinearScale';
import Option from './Option';
import Text from './Text';

const getComponent = (form, addAnswers) => {
  switch (form.type) {
    case 'select':
      return <Option options={form.options} addAnswers={addAnswers} />;
    case 'selectM':
      return <Option options={form.options} addAnswers={addAnswers} isMulti />;
    case 'text':
      return <Text addAnswers={addAnswers} />;
    case 'url':
      return <Text addAnswers={addAnswers} />;
    case 'range':
      return <LinearScale addAnswers={addAnswers} />;
    default:
      return null;
  }
};

const Form = ({ form, setAnswers }) => {
  const addAnswers = setAnswers(form.id);

  return (
    <div className="form-group">
      <label htmlFor="message-text" className="col-form-label">
        <span className="text-danger">{form.isRequired ? '*' : ''}</span>
        {form.name}
      </label>
      {getComponent(form, addAnswers)}
    </div>
  );
};

export default Form;
