import { Flex, IconButton, Text } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import { useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useParams } from 'react-router';

export function Likes(props: { likes: number; liked: boolean }) {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(props.liked);
  const [count, setCount] = useState(props.likes);

  const handleLike = async () => {
    try {
      setLoading(true);
      await apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/talents/${id}/like`, null, 'GET');
      setLiked((prev) => !prev);
      setCount(liked ? count - 1 : count + 1);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex align="center" gap={2}>
      <IconButton
        onClick={handleLike}
        icon={liked ? <AiFillHeart size={24} /> : <AiOutlineHeart size={24} />}
        aria-label="Like talent"
        variant="ghost"
        colorScheme="red"
        isLoading={loading}
      />
      <Text size="lg" fontWeight="light" m={0}>
        {count}
      </Text>
    </Flex>
  );
}
