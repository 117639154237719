import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiCall } from 'crud/api.crud';
import { BiPlayCircle } from 'react-icons/bi';

interface RecordingsProps {
  activity_id: number;
}

interface Recording {
  id: number;
  url: string;
  name: string;
  meeting_id: string;
  date: string;
  created_at: string;
  updated_at: string;
}

export const Recordings = ({ activity_id }: RecordingsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [recordings, setRecordings] = useState<Recording[]>([]);

  useEffect(() => {
    apiCall(`activity/${activity_id}/recordings`, null, 'GET')
      .then((response) => {
        setRecordings(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <span className="mt-5">Buscando...</span>;

  if (!recordings?.length) return <span className="mt-5">{t('activities.activity_actionsNull')}</span>;

  return (
    <ul className="list-group" style={{ overflowY: 'auto', maxHeight: '600px' }}>
      {recordings.map((recording: any) => {
        return (
          <li className="list-group-item" key={recording.id}>
            <div className="row">
              <div className="col-9">
                <p className="m-0">{recording.name}</p>
                <span className="text-muted">{moment(recording.date).format('DD/MM/YYYY')}</span>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <a
                  href={recording.url}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-secondary text-white py-1 px-2 rounded d-flex align-items-center"
                >
                  <BiPlayCircle size={22} color="white" />
                  <span className="ml-2">Ver</span>
                </a>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
