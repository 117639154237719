import { useState, useEffect, useRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import useStore from '../../../../../store/zustand/store';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { apiCall } from '../../../../../crud/api.crud';
import { useTranslation } from 'react-i18next';
import Idiom from '../../../../organisms/Navbar/components/Idiom';

const schema = Yup.string().required();

const StepTwo = ({ previous, email }) => {
  const { t } = useTranslation();

  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loginApi = useStore((state) => state.login);
  const inputRef = useRef();

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    inputRef.current.focus();
  });

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  };

  const login = async () => {
    setLoading(true);
    setError(false);
    const valid = await schema.isValid(password);
    if (!valid) {
      setLoading(false);
      setError(true);
      return;
    }

    const payload = {
      email,
      password,
    };

    try {
      const response = await apiCall('login', payload, 'POST');
      const data = response.data.data[0];
      loginApi(data);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const setPreviousStep = () => {
    previous(0);
  };

  const changeShowPass = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <h5>{t('home.public_msg4')}</h5>
      <div className="w-100 mt-4">
        <fieldset disabled>
          <input type="text" id="disabledTextInput" className="input-text" placeholder={email} />
        </fieldset>
      </div>
      <div className="password-wrapper mt-4">
        <input
          type={showPass ? 'text' : 'password'}
          className={`input-text ${error && 'input-text--danger'}`}
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleOnKeyDown}
          value={password}
          name="password"
          ref={inputRef}
        />
        {showPass ? (
          <FaEyeSlash className="password-eye" onClick={changeShowPass}></FaEyeSlash>
        ) : (
          <FaEye className="password-eye" onClick={changeShowPass}></FaEye>
        )}
      </div>
      {error && <span className="span span--error pt-1">{t('home.public_msg5')}</span>}
      <Link to={{ pathname: '/reset', emailProp: { email } }} className="link mt-2">
        {t('home.public_msg6')}
      </Link>
      <div className="d-flex justify-content-around w-100">
        <button className="button button--white mt-3 mb-5" onClick={setPreviousStep}>
          {t('button.button_msg16')}
        </button>
        <button className="button mt-3 mb-5" onClick={login}>
          {t('button.button_msg17')}
        </button>
      </div>

      <div className="d-flex justify-content-between w-100 align-items-center">
        <Idiom />
        <Link to="/register">{t('home.public_msg1')}</Link>
      </div>
    </>
  );
};

export default StepTwo;
