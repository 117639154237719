import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { News } from '..';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { apiCall } from 'crud/api.crud';

type NewsCardProps = {
  data: News;
};

const Card = styled.div`
  background-color: #ffffff;
  border: 1px solid rgb(0 0 0 / 5%);
  border-radius: 15px;
  margin-bottom: 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;

  hr {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
  }

  .strong {
    font-weight: bold;
    margin: 0 5px 5px 0;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
`;
const Container = styled.div`
  padding: 25px;
`;
const Img = styled.img`
  width: 100%;
  border-radius: 15px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  spacing: 16px;
  padding: 16px;
`;
const StyledLink = styled(Link)`
  font-size: 14px;
  color: blue.500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const CommunityCard = ({ data }: NewsCardProps) => {
  const [userName, setUserName] = useState<string>('');
  const { t } = useTranslation();
  const { id, title, photo_path, short_description, created_at, user_id } = data;

  useEffect(() => {
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/talents/${user_id}`, null, 'GET').then(({ data }) =>
      setUserName(`${data.data.name} ${data.data.surname}`),
    );
    return () => {
      setUserName('');
    };
  }, [id]);

  return (
    <Card>
      {photo_path && (
        <Img className="card-img-top" alt="img de community" src={`https://app.fonselp.com${photo_path}`} />
      )}
      <Container>
        <Info>
          <h3 className="text-info ">{title}</h3>
          <p className="text-dark text-small">{short_description}</p>
          <div className="d-flex justify-content-between">
            <VStack>
              <span className="text-black-50 pt-2">
                <small>{created_at}</small>
              </span>
              <StyledLink to={`profile/${user_id}`}>{userName}</StyledLink>
            </VStack>
            <Link
              className="btn btn-link font-weight-bold text-black-50"
              to={`community/detail/${id}`}
              style={{ alignSelf: 'flex-end' }}
            >
              {t('community.community_btn1')} <AiOutlineArrowRight></AiOutlineArrowRight>
            </Link>
          </div>
        </Info>
      </Container>
    </Card>
  );
};

export default CommunityCard;
