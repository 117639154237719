import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import config from '../../../../../config';
import { ActivityParse } from '../../../../../interfaces/activity';
import Modal from '../../../../molecules/Modal/Modal';
import Ods from '../../../../molecules/Ods';
import ActionsButtons from './ActionsButtons';

interface props {
  activityData: ActivityParse | null;
  closeModal: () => void;
  isOpen: boolean;
}

const ActivityModal = ({ activityData, closeModal, isOpen }: props) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);

  let history = useHistory();

  function handleClick(id) {
    history.push(`/detail/${id}`);
  }

  if (!activityData) return null;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title={activityData?.activity}>
      {activityData && (
        <section className="d-flex align-items-center p-3">
          <div className="container d-flex flex-column align-items-start justify-content-center p-0">
            <p className="p-0 mb-3">{activityData.description}</p>
            <Ods data={activityData} />
            <div className="row w-100">
              <div className="col-12 col-lg-10">
                <table className="table table-borderless w-100" style={{ fontSize: 14 }}>
                  <tbody>
                    <tr className="my-5">
                      <th scope="row" className="p-1 d-flex align-items-center">
                        <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg2')}</span>
                      </th>
                      <td>{activityData.status}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="p-1 d-flex align-items-center">
                        <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg3')}</span>
                      </th>
                      <td>{activityData.selection_status}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="p-1 d-flex align-items-center">
                        <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg6')}</span>
                      </th>
                      <td>{activityData.deadline}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="p-1 d-flex align-items-center">
                        <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg7')}</span>
                      </th>
                      <td>{activityData.to_entity}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="p-1 d-flex align-items-center">
                        <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg8')}</span>
                      </th>
                      <td>{activityData.from_entity}</td>
                    </tr>
                    {config.activities_hours_table && (
                      <>
                        <tr>
                          <th scope="row" className="p-1 d-flex align-items-center">
                            <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg9')}</span>
                          </th>
                          <td>{activityData.hours_individual}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="p-1 d-flex align-items-center">
                            <span className="breadcrumb m-0 d-inline">{t('activities.activities_msg10')}</span>
                          </th>
                          <td>{activityData.hours_total}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="btn-group my-2 mx-auto p-2 bg-primary rounded" role="group" aria-label="First group">
              <ActionsButtons row={activityData} handleClick={handleClick} type="text" />
            </div>
          </div>
        </section>
      )}
    </Modal>
  );
};

export default ActivityModal;
