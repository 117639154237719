import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStore from '../../../store/zustand/store';
import Button from '../../molecules/Button';
import GoActivityButton from './components/GoActivityButton';
import LoginButton from './components/LoginButton';
import SubscribeButton from './components/SubscribeButton';
import UnsubscribeButton from './components/UnsusbcribeButton';

const DISABLED_STATUS = [
  3, // Cerrada
  4, // Archivada
];

const now = moment().subtract(1, 'days');

const ActivityButton = ({ activity }) => {
  const user = useStore((state) => state.user);
  const [isSuscribed, setIsSuscribed] = useState(!!activity.activity_user_id);
  const { t } = useTranslation();
  const location = useLocation();

  const isOnActivityDetailPage = location.pathname === `/detail/${activity.id}`;

  const isEnable = useMemo(() => {
    // Si la actividad tiene este estado, no se puede inscribir
    if (DISABLED_STATUS.includes(activity.status)) return false;

    // Si todavia no paso la fecha de cierre o es una actividad continua, se puede inscribir
    if (now <= moment(activity.deadline) || activity.continuous_activity) return true;

    // no se puede inscribir
    return false;
  }, [activity]);
  if (activity.hidden_button) return null;

  if (!user) return <LoginButton />;

  if (isSuscribed && isOnActivityDetailPage) {
    return <UnsubscribeButton activity={activity} setIsSuscribed={setIsSuscribed} />;
  } else if (!isSuscribed && isOnActivityDetailPage) {
    return <SubscribeButton activity={activity} setIsSuscribed={setIsSuscribed} />;
  } else if (isSuscribed && !isOnActivityDetailPage) {
    return <GoActivityButton activity={activity} />;
  } else if (!isSuscribed && !isOnActivityDetailPage) {
    return <SubscribeButton activity={activity} setIsSuscribed={setIsSuscribed} />;
  }

  return <Button className="button-reactour">{t(!!activity.guide_id ? 'button.enroll_me' : 'button.apply')}</Button>;
};

export default ActivityButton;
