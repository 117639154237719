import { useTranslation } from 'react-i18next';
import './modal.scss';

const UnapplyModal = ({ closeModal, title, send }) => {
  const { t } = useTranslation();

  const save = () => {
    send();
    closeModal();
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{`${t('hours.unapply_title')} ${title}`}</h5>
        <button type="button" className="close" onClick={closeModal} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{t('hours.unapply_msg')}</div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeModal} data-dismiss="modal">
          {t('button.button_msg8')}
        </button>
        <button type="button" className="btn btn-primary" onClick={save}>
          {t('hours.unapply_msg1')}
        </button>
      </div>
    </div>
  );
};

export default UnapplyModal;
