import { Checkbox, CheckboxGroup, Stack, RadioGroup, Radio } from '@chakra-ui/react';
import { Option } from 'interfaces/guide.interface';

const isArcosDorados = +process.env.REACT_APP_ID_ENTITY! === 8399;

interface Props {
  options: Option[];
  onChange: () => void;
}

export function Field({ options, onChange }: Props) {
  if (isArcosDorados) {
    return (
      <RadioGroup onChange={onChange}>
        <Stack spacing={[1, 5]}>
          {options?.map((option) => (
            <Radio key={option.id} value={String(option.id)}>
              {option.answer}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    );
  }

  return (
    <CheckboxGroup onChange={onChange}>
      <Stack spacing={[1, 5]}>
        {options?.map((option) => (
          <Checkbox key={option.id} value={String(option.id)}>
            {option.answer}
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  );
}
