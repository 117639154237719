import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { Authentication } from '../../../../crud/Types/Authentication';
import { apiCall } from '../../../../crud/api.crud';
import useStore from '../../../../store/zustand/store';
import { ButtonPrimary } from 'components/molecules/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
`;

const Message = styled.h3`
  margin-bottom: 40px;
  max-width: 600px;
`;

type Params = { id: string };
// : Promise<objet>
const getMagicLink = async (id: string): Promise<Authentication> => {
  const entityId = process.env.REACT_APP_ID_ENTITY;
  const response = await apiCall('authentication', { id, entityId }, 'POST');
  const data = response.data.data[0];
  return data;
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const MagicLink = () => {
  const query = useQuery();

  const { id }: Params = useParams();
  const history = useHistory();
  const user = useStore((state) => state.user);
  const logout = useStore((state) => state.logout);
  const login = useStore((state) => state.login);
  const [message, setMessage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const magicLink = await getMagicLink(id);

        if (!magicLink) {
          setMessage('Ooops... El enlace al que está intentando acceder ha caducado.');
          return;
        }

        if (!user && magicLink) {
          logout();
          login(magicLink);
        }

        history.push('/' + (magicLink.url ? magicLink.url : query.get('path') || ''));
      } catch (error) {
        setMessage('El enlace al que está intentando acceder ha caducado o su token de autenticación no es válido.');
      }
    })();
  }, []);

  const handleGoHome = () => {
    history.push('/');
  };

  return (
    <Container>
      <Message>{message}</Message>
      <ButtonPrimary onClick={handleGoHome}>Volver al inicio</ButtonPrimary>
    </Container>
  );
};

export default MagicLink;
