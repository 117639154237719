import { Box, Flex, Heading, Image, VStack } from '@chakra-ui/react';
import { ActivityRoom } from 'interfaces';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import without_image from '../assets/without_image.jpg';

interface Props {
  activity: ActivityRoom;
}

export const SidebartItem: FC<Props> = ({ activity }) => {
  return (
    <Flex
      as={NavLink}
      to={`/chat/${activity.id}`}
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      boxShadow="md"
      bg="white"
      // minW={{ base: '300px', md: '400px' }}
      style={{ textDecoration: 'none' }}
      color="black"
      flexDirection="row"
      justifyContent="spaceBetween"
      alignItems="center"
      gap={2}
      mb={4}
      _activeLink={{ bg: 'gray.200' }}
      _hover={{ bg: 'gray.100', color: 'black' }}
    >
      <Image
        src={activity?.image || without_image}
        alt="activity image"
        objectFit="contain"
        minW="150px"
        maxH="200px"
        maxW="200px"
        width="100px"
        height="75px"
      />
      <VStack minW="250px" align="start" justifyContent="spaceBetween">
        <Box overflow="hidden">
          <Heading size="xs" fontWeight="semibold" pr={4}>
            {activity?.title}
          </Heading>
        </Box>
      </VStack>
    </Flex>
  );
};
