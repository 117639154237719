import { useState } from 'react';
import Reactour from './Reactour';
import Checkbox from './Checkbox';
import { useTranslation } from 'react-i18next';

const ActivitiesReactour = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const steps = [
    {
      selector: '.act-hora-reactour',
      content: function content() {
        return (
          <div>
            <p>{t('tour.activity_msg')}</p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
  ];

  return <Reactour steps={steps} checked={checked} section="activities" />;
};

export default ActivitiesReactour;
