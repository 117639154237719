import useGetUserId from 'hooks/api/useGetUserId';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpujar } from 'utils/contants';
import config from '../../../../../config';
import { ButtonPrimary, ButtonSecondary } from '../../../../molecules/Button';
import Pagination from '../../../../molecules/Pagination';

const Container = styled.div`
  margin-top: 35px;
  padding: 10px 10px;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 4.90798px 6.13497px rgba(0, 0, 0, 0.14), 0px 1.84049px 8.58896px rgba(0, 0, 0, 0.12),
    0px 3.06748px 3.06748px rgba(0, 0, 0, 0.2);
  width: ${(props) => (props.isClose ? '70px;' : '100%')};
  transition: all 0.3s ease 0s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    margin: 0;
  }
`;

const Options = ({ setIsOpen, setPage, meta }) => {
  const { t } = useTranslation();
  const user_id = useGetUserId();
  const openModal = () => {
    setIsOpen(true);
  };

  const newClass = config.map_bottom ? 'mt-2' : 'mt-4';

  return (
    <Container className={`${newClass} d-flex flex-wrap flex-xl-row`}>
      <Pagination meta={meta} action={setPage} withRedux={false} />
      <NavLink to="/closed-activities">
        {user_id && !isEmpujar && <ButtonSecondary className="mt-2 mt-0-xl">{t('home.home_msg5')}</ButtonSecondary>}
      </NavLink>
      <ButtonPrimary onClick={openModal} className="filter-reactour d-xl-none mt-2 mt-xl-0">
        <FaFilter />
        {t('home.home_msg2')}
      </ButtonPrimary>
    </Container>
  );
};

export default Options;
