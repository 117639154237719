import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';
import Checkbox from './Checkbox';
import Reactour from './Reactour';

const HomeReactour = (hour) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  // console.log(!hour ? "verdad" : "false")
  const steps = [
    {
      selector: '.logo-reactour',
      content: function content() {
        return (
          <div>
            <p>
              {t('tour.welcome_msg')} {config.name}
            </p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    {
      selector: '.inicio-reactour',
      content: function content() {
        return (
          <div>
            <p>
              {t('tour.welcome_msg2')} {config.name}
            </p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    {
      selector: '.filter-reactour',
      content: function content() {
        return (
          <div>
            <p>{t('tour.welcome_msg3')}</p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    {
      selector: '.button-reactour',
      content: function content() {
        return (
          <div>
            <p>{t('tour.welcome_msg4')}</p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    {
      selector: '.more-reactour',
      content: function content() {
        return (
          <div>
            <p>{t('tour.welcome_msg5')}</p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    {
      selector: '.act-reactour',
      content: function content() {
        return (
          <div>
            <p>{t('tour.welcome_msg6')}</p>
            <Checkbox checked={checked} setChecked={setChecked} />
          </div>
        );
      },
    },
    !hour
      ? ({
          selector: '.horas-reactour',
          content: function content() {
            return (
              <div>
                <p>{t('tour.welcome_msg7')}</p>
                <Checkbox checked={checked} setChecked={setChecked} />
              </div>
            );
          },
        },
        {
          selector: '.perfil-reactour',
          content: function content() {
            return (
              <div>
                <p>
                  Desde {config.name} {t('tour.welcome_msg8')}
                </p>
                <Checkbox checked={checked} setChecked={setChecked} />
              </div>
            );
          },
        })
      : {
          selector: '.perfil-reactour',
          content: function content() {
            return (
              <div>
                <p>
                  Desde {config.name} {t('tour.welcome_msg8')}
                </p>
                <Checkbox checked={checked} setChecked={setChecked} />
              </div>
            );
          },
        },
  ];

  return <Reactour steps={steps} checked={checked} section="home" />;
};

export default HomeReactour;
