// import { useEffect, useState } from "react";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { apiCall } from '../../../../../crud/api.crud';
// import { withTranslation } from 'react-i18next';
// import { checkExtraField } from '../../../utils/checkExtraField';

const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 125px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.5s ease-out;
`;

const MainModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.08);
  width: 500px;
`;

const BodyModal = styled.div`
  width: 420px;
  margin: 20px 40px 5px;
  padding: 1.5rem;
`;

const FooterModal = styled.footer`
  width: 420px;
  margin: 0 40px 20px;
  display: flex;
  justify-content: flex-end;
`;

const Info = styled.p`
  padding: 0;
  margin: 0;
`;

const Span = styled.span`
  color: grey;
`;

const ModalContract = ({ onClose, upload, volunteeringId }) => {
  const { t } = useTranslation();

  // const [selectedFile, setSelectedFile] = useState(JSON.parse(localStorage.getItem('saved')) ? (JSON.parse(localStorage.getItem('saved'))) : null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [send, setSend] = useState(false);

  const onFileChange = (e) => {
    setSelectedFile({ data: e.target.files[0] });
  };

  const onFileUpload = () => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append('file', selectedFile.data, selectedFile.data.name);
      apiCall(`contractUpload/volunteeringId/${volunteeringId}`, formData, 'POST');

      setSend(true);
    }
  };

  const sended = () => {
    if (send) {
      return (
        <div>
          <br />
          <p className="text-success">{t('management.contract_msg3')}</p>
        </div>
      );
    }
  };

  const toDate = (date) => {
    var newDate = new Date(date);

    return newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
  };

  const fileData = () => {
    if (selectedFile) {
      return (
        <div className="mt-3 ">
          <h5>{t('management.management_msg12')}</h5>
          <Info>
            {t('management.management_msg13')}: <Span>{selectedFile.data.name}</Span>
          </Info>
          <Info className="m-0 p-0">
            {t('management.management_msg14')}: <Span>{selectedFile.data.type}</Span>
          </Info>
          <Info className="m-0 p-0">
            {t('management.management_msg15')}: <Span>{toDate(selectedFile.data?.lastModified)}</Span>
          </Info>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <p className="text-danger">{t('management.management_msg16')}</p>
        </div>
      );
    }
  };

  const close = () => {
    onClose();
  };

  return (
    upload && (
      <Overlay>
        <MainModal>
          <BodyModal>
            <form className="md-form">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile01"
                  aria-describedby="inputGroupFileAddon01"
                  accept=".pdf"
                  onChange={onFileChange}
                />
                <label
                  className="custom-file-label"
                  htmlFor="inputGroupFile01"
                  data-browse={t('management.management_msg20')}
                >
                  {t('management.management_msg17')}
                </label>
              </div>
              {fileData()}
            </form>
            {sended()}
          </BodyModal>
          <FooterModal>
            <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal" onClick={close}>
              {t('management.management_msg18')}
            </button>
            <button type="button" className="btn btn-primary" onClick={onFileUpload}>
              {t('management.management_msg19')}
            </button>
          </FooterModal>
        </MainModal>
      </Overlay>
    )
  );
};

export default ModalContract;
