import { useState } from 'react';
import Select from 'react-select';

interface props {
  options: Option[];
  handleChange: (option: Option, name: string) => void;
  name: string;
  initial: number;
}

interface Option {
  value: string | number;
  label: string;
}

const index = ({ options, handleChange, name, initial }: props) => {
  const [idx, setIdx] = useState(initial === -1 ? 0 : initial);
  const selected = (value, type) => {
    const index = value ? options.findIndex((opt) => opt.value === value.value) : 0;
    setIdx(index);
    if (type.action === 'clear') {
      handleChange({ value, label: value }, type.name);
      return;
    }
    handleChange(value, type.name);
  };

  return <Select options={options} value={options[idx]} onChange={selected} isClearable={true} name={name} />;
};

export default index;
