import { Button, ButtonProps } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import useGetUserId from 'hooks/api/useGetUserId';
import { Resource } from 'interfaces/guide.interface';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

interface Params {
  id: string; // activityId
  lessonId?: string;
  quizId?: string;
}

interface NextLessonProps extends ButtonProps {
  children: React.ReactNode;
  resource_id?: Resource['id'];
  completed_at?: Resource['completed_at'];
}

export function NextLesson({ children, resource_id, completed_at, ...props }: NextLessonProps) {
  const { guide, updateResource } = useGuideContext();
  const { id: activityId, lessonId, quizId } = useParams<Params>();
  const user_id = useGetUserId();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const next = useMemo(() => {
    const lessons = guide.topics!.flatMap(({ chapters }) => chapters).flatMap(({ lessons }) => lessons);
    let lessonIndex = -2;

    // Buscar por lessonId
    if (lessonId) {
      lessonIndex = lessons?.findIndex(({ id }) => id === Number(lessonId));
    }

    // Buscar por quizId
    if (quizId) {
      lessonIndex = lessons?.findIndex(({ quiz }) => Number(quiz?.id) === Number(quizId));
    }

    const lesson = lessons[lessonIndex];

    // Si tiene quiz
    if (lesson?.quiz?.id && Number(quizId) !== lesson.quiz.id) {
      return `/detail/${activityId}/guide/quiz/${lesson.quiz?.id}`;
    }

    // Es la ultima lesson
    if (lessonIndex === lessons.length - 1) {
      return `/detail/${activityId}/guide`;
    }

    // Siguiente lesson
    return `/detail/${activityId}/guide/lesson/${lessons[lessonIndex + 1]?.id}`;
  }, [guide, lessonId, quizId]);

  const onClick = async () => {
    setIsLoading(true);

    try {
      if (resource_id && !completed_at) {
        const { data } = await apiCall(
          `resource`,
          {
            resource_id,
            user_id,
            guide_id: guide.id,
            activity_id: activityId,
          },
          'POST',
        );

        updateResource(resource_id, { completed_at: data.data.completed_at });
      }

      history.push(next);
    } catch (error) {
      alert('Hubo un error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button {...props} isLoading={isLoading} onClick={onClick}>
      {children}
    </Button>
  );
}
