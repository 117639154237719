import styled from 'styled-components';
import { ods } from './ods/icons/index';
import list from './ods/infoOds';

const Img = styled.img`
  height: 50px;
  @media (min-width: 768px) {
    height: 85px;
  }
`;

const OnlyOds = ({ id }) => (
  <a href={list.link[id - 1]} target="_blank" rel="noreferrer">
    <Img src={ods[id - 1]} />
  </a>
);
export default OnlyOds;
