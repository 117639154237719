import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { VolunteeringCustomFields } from 'interfaces';

interface Props {
  answers: VolunteeringCustomFields[];
}

export function Answers({ answers }: Props) {
  return (
    <Stack mt={12}>
      {answers.map(({ id, answer, value }) => (
        <Box key={id}>
          <Heading size="md" fontWeight="normal">
            {answer?.title}
          </Heading>
          <Text>{value}</Text>
        </Box>
      ))}
    </Stack>
  );
}
