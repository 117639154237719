import Loader from 'react-spinners/PropagateLoader';

const Loading = () => (
  <div
    className="mt-5"
    style={{
      height: '25vh',
      width: '10wh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loader size={8} color="#007bff" loading />
  </div>
);

export default Loading;
