import config from '../../../config';
import HoursForm from '../Forms/HoursForm';
import './modal.scss';

const HoursModal = ({ closeModal, title, activity_id }) => (
  <div className="modal-content">
    <div className="modal-header">
      <div>
        <h5 className="modal-title">{title}</h5>
        {config.profile_globant && (
          <p className="text-left p-0 m-0">
            <small>Ten en cuenta que las horas de voluntariado no son asignables a proyectos con cliente</small>
          </p>
        )}
      </div>
      <button type="button" className="close" onClick={closeModal} aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>{' '}
    </div>
    <div className="modal-body">
      <HoursForm closeModal={closeModal} activity_id={activity_id} />
    </div>
  </div>
);

export default HoursModal;
