import { useEffect } from 'react';

interface RefObject {
  current: HTMLDivElement | HTMLElement | null;
}
const useOutsideClick = <T extends RefObject>(ref: T, callback: () => void) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
