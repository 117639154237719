import { useTranslation } from 'react-i18next';
import { ID_TYPES } from 'utils/contants';
import Select from '../../../../../molecules/Form/components/Select';

if (process.env.REACT_APP_ID_ENTITY === '2621') {
  ID_TYPES.unshift({ label: 'INE', value: 'INE' });
}

const IdTypeSelect = () => {
  const { t } = useTranslation();
  return (
    <Select
      label={t('profile.profile_msg6')}
      name="id_type"
      options={ID_TYPES}
      validations={[{ key: 'required', val: true }]}
    />
  );
};

export default IdTypeSelect;
