import { useState } from 'react';
import StepOne from './commentaryModal/StepOne';
import StepTwo from './commentaryModal/StepTwo';
import './modal.scss';

const CommentaryModal = ({ closeModal, url, activity_id, survey, estimated_hours = null }) => {
  const [commentary, setCommentary] = useState('');
  const [participated, setParticipated] = useState('');
  const [formsSend, setFormsSend] = useState([]);

  const [step, setStep] = useState(0);
  const setAnswers = (name) => (value) => {
    setFormsSend({ ...formsSend, [name]: value });
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Encuesta de satisfacción</h5>
        <button type="button" className="close" onClick={closeModal} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {!step ? (
        <StepOne
          commentary={commentary}
          setCommentary={setCommentary}
          participated={participated}
          setParticipated={setParticipated}
          closeModal={closeModal}
          setStep={setStep}
          survey={survey}
          setAnswers={setAnswers}
          formsSend={formsSend}
        />
      ) : (
        <StepTwo
          commentary={commentary}
          participated={participated}
          setStep={setStep}
          url={url}
          activity_id={activity_id}
          closeModal={closeModal}
          formsSend={formsSend}
          estimated_hours={estimated_hours}
        />
      )}
    </div>
  );
};

export default CommentaryModal;
