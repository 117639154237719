import { Checkbox, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useGuide } from 'modules/classroom/hooks/useGuide';
import { useTranslation } from 'react-i18next';
import { TbCertificate } from 'react-icons/tb';
import { useHistory, useParams } from 'react-router';

export function CertificateButton() {
  const { id: activityId } = useParams<{ id: string }>();
  const history = useHistory();
  const { guide } = useGuideContext();
  const { hasEnded } = useGuide();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  if (!guide.hasCertificate) return null;

  return (
    <SimpleGrid
      onClick={() => {
        if (hasEnded) {
          history.push(`/detail/${activityId}/guide`);
        }
      }}
      _hover={{ bg: 'gray.100' }}
      opacity={hasEnded ? 1 : 0.5}
      cursor={hasEnded ? 'pointer' : 'not-allowed'}
      p={2}
      columns={2}
      templateColumns="auto 1fr auto"
      gap={3}
      color="black"
    >
      <Icon as={TbCertificate} width={5} height={5} />
      <div>
        <Text>{t('certificate')}</Text>
        <Text fontSize="xs" color="gray.500">
          {t('download_certificate')}
        </Text>
      </div>
      <Checkbox colorScheme="brand" isChecked={hasEnded} />
    </SimpleGrid>
  );
}
