import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiCall } from '../../../../crud/api.crud';
import useGetUserId from '../../../../hooks/api/useGetUserId';
import { actions as userActivitiesAction } from '../../../../store/ducks/user/activities.duck';

const StepTwo = ({ commentary, setStep, activity_id, closeModal, url, formsSend, participated, estimated_hours }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user_id = useGetUserId();

  const [isLoading, setIsLoading] = useState(false);
  const [response, setIsResponse] = useState(false);

  useEffect(() => {
    if (response) {
      dispatch(userActivitiesAction.getActivities(user_id));
      closeModal();
    }
  }, [response, closeModal, dispatch, user_id]);

  const save = async () => {
    const payload = {
      activity_id,
      user_id,
      commentary,
      participated: participated.value,
      formsSend,
      estimated_hours,
    };
    setIsLoading(true);
    setIsResponse(await apiCall(url, payload, 'POST'));
  };
  return (
    <>
      <div className="modal-body">
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            {t('button.button_msg15')}
          </label>
          <p>
            {participated.label} participe.
            <br />
            {commentary}.
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={() => setStep(0)} data-dismiss="modal">
          {t('button.button_msg8')}
        </button>
        <button type="button" className="btn btn-primary" disabled={!commentary.length} onClick={save}>
          {isLoading ? t('button.button_msg6') : t('button.button_msg14')}
        </button>
      </div>
    </>
  );
};

export default StepTwo;
