import Loader from 'react-spinners/PropagateLoader';

const Loading = () => (
  <div
    className="mt-5"
    style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Loader size={5} color="#007bff" loading />
  </div>
);

export default Loading;
