import config from '../config';

const entityID = process.env.REACT_APP_ID_ENTITY;

export const STORAGE_URL =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_API_URL}/images`
    : 'https://app.fonselp.com/storage/images';

export const isPactoEmprendedor = entityID == '4193';
export const isAda = entityID == '3221';
export const isCessi = entityID === '6911';
const isDev = process.env.NODE_ENV === 'development';

export function showTalent(user): boolean {
  if (isDev) return true;

  // check if user is captain
  if (user?.role_name && user?.role_name === 'captain') return true;

  return !!config.catalogTalents;
}

export function sortItems<T extends { order: number }>(items?: T[]): T[] {
  return (
    items?.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    }) || []
  );
}
