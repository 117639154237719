import { saveAs } from 'file-saver';
import Modal from 'components/shared/Modal';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiCall } from 'crud/api.crud';
import { useHistory, useParams } from 'react-router';
import useGetUserId from 'hooks/api/useGetUserId';
import Loader from 'react-spinners/MoonLoader';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 2rem;

  h4 {
    text-align: center;
  }

  p {
    text-align: center;
    color: #6c757d;
  }
`;

type ErrorType = 'missing_data' | 'server_error';

export function DownloadCertificate() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorType>();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const user_id = useGetUserId();

  useEffect(() => {
    const downloadCertificate = async () => {
      try {
        const { data } = await apiCall(`downloadCertificate/${user_id}/${id}`, null, 'GET', 'blob');
        saveAs(data, 'Certificate.pdf');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status;

          if (status === 422) {
            setError('missing_data');
            return;
          }
        }

        setError('server_error');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      downloadCertificate();
    }
  }, [id]);

  const onClose = () => {
    history.replace('/actividad');
  };

  return (
    <Modal isOpen={true}>
      <Container>
        {loading && (
          <>
            <h4> {t('activities.download_certificate')} </h4>
            <Loader color="#007bff" size={40} speedMultiplier={0.55} />
          </>
        )}

        {!loading && !error && (
          <>
            <div>
              <h4> {t('activities.download_success')}</h4>
              <p>{t('activities.download_certificate_success')}</p>
            </div>
            <button className="btn btn-primary btn-sm" onClick={onClose}>
              {t('tour.close')}
            </button>
          </>
        )}

        {error === 'missing_data' && (
          <>
            <h4>{t('profile.missing_data')}</h4>
            <Link to="/profile" className="btn btn-primary btn-sm" onClick={onClose}>
              {t('profile.complete')}
            </Link>
          </>
        )}

        {error === 'server_error' && (
          <>
            <div>
              <h4> {t('tour.server_error')}</h4>
              <p>{t('tour.server_error_message')}</p>
            </div>
            <button className="btn btn-primary btn-sm" onClick={onClose}>
              {t('tour.close')}
            </button>
          </>
        )}
      </Container>
    </Modal>
  );
}
