import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Modal from '../Modal';
import ForgottenPassword from './components/ForgottenPassword';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';

const LoginModal = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/password/reset')) {
      setStep(4);
      setIsOpen(true);
    }
  }, [location.pathname, setIsOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {(() => {
        switch (step) {
          case 1:
            return <Login setStep={setStep} setIsOpen={setIsOpen} email={email} setEmail={setEmail} />;
          case 2:
            return <Register setStep={setStep} setIsOpen={setIsOpen} />;
          case 3:
            return <ForgottenPassword setStep={setStep} setIsOpen={setIsOpen} email={email} setEmail={setEmail} />;
          case 4:
            return <ResetPassword setStep={setStep} isOpen={isOpen} setIsOpen={setIsOpen} />;
          default:
            return null;
        }
      })()}
    </Modal>
  );
};
export default LoginModal;
