import { Button, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function getDownloadUrl(url: string, type = 'pdf') {
  const docRegex = /https:\/\/docs\.google\.com\/document\/d\/([\w-]+)\//;
  const sheetRegex = /https:\/\/docs\.google\.com\/spreadsheets\/d\/([\w-]+)\//;
  const slideRegex = /https:\/\/docs\.google\.com\/presentation\/d\/([\w-]+)\//;
  const driveRegex = /https:\/\/drive\.google\.com\/file\/d\/([\w-]+)\//;
  const newUrl = 'https://docs.google.com/';
  if (docRegex.test(url)) {
    const match = url.match(docRegex);

    if (match) {
      return `${newUrl}document/d/${match[1]}/export?format=${type}`;
    }
  } else if (sheetRegex.test(url)) {
    const match = url.match(sheetRegex);
    if (match) {
      return `${newUrl}spreadsheets/d/${match[1]}/export?format=${type}`;
    }
  } else if (slideRegex.test(url)) {
    const match = url.match(slideRegex);
    if (match) {
      return `${newUrl}presentation/d/${match[1]}/export/${type}`;
    }
  } else if (driveRegex.test(url)) {
    const match = url.match(driveRegex);
    if (match) {
      return `https://drive.google.com/uc?export=download&id=${match[1]}`;
    }
  }

  return null;
}

interface Props {
  url: string;
}

export function FileDownloader({ url }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const toast = useToast();

  if (url.includes('.md')) return <DownloadMD url={url} />;

  return (
    <Button
      colorScheme="gray"
      onClick={() => {
        const downloadUrl = getDownloadUrl(url);
        if (!downloadUrl) {
          toast({
            status: 'error',
            title: 'Error',
          });
          return;
        }
        window.open(downloadUrl, '_blank');
      }}
      textTransform="capitalize"
    >
      {t('download')}
    </Button>
  );
}

function DownloadMD({ url }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleClick = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(url, {
        responseType: 'blob',
      });

      const urlDownload = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', 'archivo.md');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button colorScheme="gray" onClick={handleClick} isLoading={loading} textTransform="capitalize">
      {t('download')}
    </Button>
  );
}
