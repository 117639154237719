import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MdFullscreen, MdPause, MdPlayArrow } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

import { PlayerState, Resource } from 'interfaces/guide.interface';
import { NextLesson } from 'modules/classroom/components/NextLesson';
import { useTranslation } from 'react-i18next';
import Played from './BarPlayed';
import Duration from './Duration';
import Volume from './Volume';

interface PlayerProps {
  url: string;
  resource_id: Resource['id'];
  completed_at: Resource['completed_at'];
  onEnd?: () => void;
}

export function Player({ url, resource_id, completed_at, onEnd }: PlayerProps) {
  const [showMenu, setShowMenu] = useState(true);
  const [state, setState] = useState<PlayerState>({
    url,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    seeking: false,
    visible_button_refresh: false,
    ended: false,
  });
  const player = useRef<ReactPlayer>(null);
  const handle = useFullScreenHandle();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  const handleSeekMouseDown = () => {
    setState({ ...state, seeking: true });
  };

  const handleSeekChange = (value: number) => {
    setState({ ...state, played: value });
  };

  const handleSeekMouseUp = (value: number) => {
    setState({ ...state, seeking: false });

    if (player?.current) {
      player.current.seekTo(value);
    }
  };

  const handlePlay = () => {
    setState({ ...state, playing: true });
    setTimeout(() => {
      setShowMenu(false);
    }, 3000);
  };

  const handlePause = () => {
    setShowMenu(true);
    setState({ ...state, playing: false });
  };

  const handleProgress = (stateIn: OnProgressProps) => {
    if (!state.seeking) {
      setState({ ...state, ...stateIn });
    }
  };

  const handlePlayPause = () => {
    if (state.playing) handlePause();
    else handlePlay();
  };

  const handleVolumeChange = (value: number) => {
    setState({ ...state, volume: value });
  };

  const handleSetPlaybackRate = (rate: number) => {
    setState({
      ...state,
      playbackRate: rate,
    });
  };

  const handleEnded = () => {
    setState({ ...state, playing: false, url: '', ended: true });
    if (onEnd) onEnd();
  };

  const handleDuration = (duration: number) => {
    setState({ ...state, duration });
  };

  const handleReset = () => {
    setState({ ...state, playing: true, ended: false, played: 0, loaded: 0, url });
  };

  return (
    <FullScreen handle={handle}>
      <Box position="relative" h={handle.active ? '100vh' : '75vh'} bg="black" role="group">
        <ReactPlayer
          ref={player}
          url={state.url}
          width="100%"
          height="100%"
          pip={state.pip}
          playing={state.playing}
          loop={state.loop}
          playbackRate={state.playbackRate}
          volume={state.volume}
          muted={state.muted}
          onStart={() => setState({ ...state, controls: true })}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onEnded={handleEnded}
        />
        <Flex
          position="absolute"
          zIndex={2}
          w="full"
          bottom={0}
          p={2}
          bg="linear-gradient(0deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 100%);"
          transition="opacity .3s ease"
          opacity={showMenu && !state.ended ? 1 : 0}
          _groupHover={{ opacity: state.ended ? 0 : 1 }}
          align="flex-end"
        >
          <Box w="full">
            <Flex alignItems="center" gap={2} px={2}>
              <Played
                played={state.played}
                handleSeekMouseDown={handleSeekMouseDown}
                handleSeekChange={handleSeekChange}
                handleSeekMouseUp={handleSeekMouseUp}
              />
              <Duration seconds={state.duration * (1 - state.played)} />
            </Flex>

            <Flex justify="space-between">
              <Flex gap={2} alignItems="center">
                <IconButton
                  aria-label="Play or Pause"
                  icon={state.playing ? <MdPause size={40} /> : <MdPlayArrow size={40} />}
                  onClick={handlePlayPause}
                  variant="unstyled"
                  color="white"
                />
                <Menu>
                  <MenuButton as={Button} colorScheme="gray" size="sm" fontSize="md" px={4}>
                    {state.playbackRate}x
                  </MenuButton>
                  <MenuList border="none" px="4px" minW="75px" zIndex="2">
                    {[0.5, 1, 1.5, 2].map((rate) => (
                      <MenuItem
                        width={100}
                        key={`playbackRate_${rate}`}
                        onClick={() => handleSetPlaybackRate(rate)}
                        justifyContent="center"
                        p={0}
                        border="none"
                        bg="transparent"
                      >
                        <Text fontWeight={600} size="sm">
                          {rate.toFixed(1)}x
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>

              <Flex gap={2}>
                <Volume value={state.volume} onChange={handleVolumeChange} />
                <IconButton
                  aria-label="Enter Full Screen"
                  onClick={() => {
                    if (handle.active) void handle.exit();
                    else void handle.enter();
                  }}
                  variant="unstyled"
                  color="white"
                  icon={<MdFullscreen size={30} />}
                />
              </Flex>
            </Flex>
          </Box>
        </Flex>

        {state.ended && (
          <Center position="absolute" w="full" h="full" zIndex={1} inset={0} bg="blackAlpha.400">
            <ButtonGroup size="sm">
              <Button colorScheme="gray" onClick={handleReset}>
                {t('repeat_lesson')}
              </Button>
              <NextLesson resource_id={resource_id} completed_at={completed_at}>
                {t('next_lesson')}
              </NextLesson>
            </ButtonGroup>
          </Center>
        )}
      </Box>
    </FullScreen>
  );
}
