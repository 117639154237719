import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import countriesJSON from 'components/pages/Private/Profile/MyPersonalData/hook/countries.json';
import statesJSON from 'assets/states-cessi.json';
import config from 'config';
import { Filter } from 'modules/talent/pages/Talents';
import { isCessi } from 'utils/talents.util';

interface Option {
  value: string;
  label: string;
}

interface State extends Option {
  localidades: Option[];
}

export function Filters({
  filter,
  setFilter,
}: {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}) {
  const [states, setStates] = useState<State[]>();
  const [skills, setSkills] = useState<Option[]>([]);
  const [state, setState] = useState<State>();
  const [locality, setLocality] = useState<Option>();
  const [selectedCountry, setSelectedCountry] = useState<Option>();

  useEffect(() => {
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/states`, null, 'GET')
      .then(({ data }) => {
        setStates(statesJSON.filter((state) => data.includes(parseInt(state.value))));
      })
      .catch(() => {
        setStates(statesJSON);
      });
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/skills`, null, 'GET').then(({ data }) => {
      const skills = data.data.map((s) => ({ value: s.id, label: s.title }));
      setSkills([{ value: 0, label: 'Verificados' }, ...skills]);
    });
  }, []);

  return (
    <HStack justify="space-between" flexDirection={['column', null, 'row']} gap={6}>
      <FormControl>
        <FormLabel htmlFor="name">Nombre</FormLabel>
        <Input
          variant="filled"
          bg="white"
          name="name"
          onChange={(e) => {
            const trimmedValue = e.target.value.trim();
            setFilter({ ...filter, name: trimmedValue });
          }}
          placeholder="Nombre"
        />
      </FormControl>
      {config.has_country && (
        <FormControl>
          <FormLabel htmlFor="country">País</FormLabel>
          <Select
            id="country"
            name="countries"
            options={countriesJSON}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder="Selecciona un país"
            value={selectedCountry}
            onChange={(input) => {
              setSelectedCountry(input);
              setFilter((prev) => ({
                ...prev,
                countries: input ? [String(input.value)] : [],
              }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      {!config.has_country && (
        <FormControl>
          <FormLabel htmlFor="state">Provincia</FormLabel>
          <Select
            id="state"
            name="states"
            options={states}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder="Selecciona una provincia"
            isLoading={!states}
            onChange={(input) => {
              setLocality(undefined);
              setState(states?.find((state) => state.value === input?.value));
              setFilter((prev) => ({ ...prev, states: input ? [input.value] : [], localities: [] }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      {isCessi && (
        <FormControl>
          <FormLabel htmlFor="state">Localidad</FormLabel>
          <Select
            id="localities"
            name="localities"
            options={state?.localidades}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder={!state ? 'Selecciona una provincia' : 'Seleciona una Localidad'}
            isDisabled={!state}
            value={locality}
            onChange={(data) => {
              console.log(data);
              setLocality(data);
              setFilter((prev) => ({ ...prev, localities: data ? [data.value] : [] }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel htmlFor="skill">Habilidades</FormLabel>
        <Select
          id="skill"
          isMulti
          name="skills"
          noOptionsMessage={() => 'No hay opciones'}
          placeholder="Seleccion Multiple..."
          isClearable
          options={skills}
          onChange={(values) => {
            setFilter((prev) => ({ ...prev, skills: values?.map((v) => v.value) || [] }));
          }}
        />
      </FormControl>
    </HStack>
  );
}
