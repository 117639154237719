import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './applyModal/Forms';
import './modal.scss';

const ApplyModal = ({ closeModal, title, send, forms }) => {
  const { t } = useTranslation();
  const [commentary, setCommentary] = useState('');
  const [formsSend, setFormsSend] = useState({});
  const [valid, setValid] = useState(false);
  const checkInputs = forms?.filter((form) => form.isRequired).map((form) => form.id);
  const [step, setStep] = useState(1);
  const [sentResponse, setSentResponse] = useState({});

  useEffect(() => {
    setValid(forms == null || checkInputs.length == 0);
  }, [forms]);

  useEffect(() => {
    if (forms == null) return;
    let aux = true;
    for (const id of checkInputs) {
      if (typeof formsSend[id] === 'object' || formsSend[id]) {
        aux = true;
      } else {
        aux = false;
        break;
      }
    }
    setValid(aux);
  }, [formsSend]);

  const onChange = (e) => {
    setCommentary(e.target.value);
  };

  const save = () => {
    const aux = [];
    for (const form in formsSend) {
      aux.push({ id: form, value: typeof formsSend[form] === 'object' ? formsSend[form].value : formsSend[form] });
    }

    // send(commentary, aux);
    setStep(step + 1);
    setSentResponse({ commentary: commentary, response: aux });
  };

  const close = () => {
    closeModal();
    setStep(1);
  };

  const setAnswers = (name) => (value) => {
    setFormsSend({ ...formsSend, [name]: value });
  };

  return (
    <div className="modal-content">
      {(() => {
        switch (step) {
          case 1:
            return (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t('home.apply_msg')} {title}
                  </h5>
                  <button type="button" className="close" onClick={closeModal} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      <span className="text-danger">*</span>
                      {t('home.apply_msg1')}:
                    </label>
                    <textarea
                      style={{ border: commentary.length ? '' : '1px solid #dc3545' }}
                      className="form-control"
                      id="message-text"
                      value={commentary}
                      onChange={onChange}
                    />
                  </div>
                  {forms &&
                    forms.map((form) => <Form key={`formApply${form.id}`} form={form} setAnswers={setAnswers} />)}
                  <small className="text-danger">Los campos con (*) son obligatorios.</small>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={close} data-dismiss="modal">
                    {t('button.button_msg8')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={save}
                    disabled={!(commentary.length && valid)}
                  >
                    {t('button.button_msg9')}
                  </button>
                </div>
              </div>
            );
          case 2:
            return (
              <div>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <strong>¡Gracias por sumarte a esta fuerza de Red!</strong>
                  </h5>
                  <button type="button" className="close" onClick={close} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <h5>Has seleccionado anotarte en: {title}</h5>
                    <b className="text-info">Tus respuestas:</b>
                    {forms &&
                      forms.map((form) => (
                        <div key={`formApply${form.id}`} className="mb-2">
                          <b className="text-muted">{form.name}</b>
                          {sentResponse.response.map((res) => {
                            return res.id == form.id ? <b className="text-info"> {res.value}</b> : '';
                          })}
                        </div>
                      ))}
                    <br />
                    <b className="text-muted">Comentario: </b>
                    <b className="text-info">{sentResponse.commentary}</b>
                  </div>
                  <span>Recibirás un mail confirmando tu participación en las próximas 72 hs hábiles.</span>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={close} data-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default ApplyModal;
