import { Box, chakra, useSlider } from '@chakra-ui/react';

interface BarPlayedProps {
  played: number;
  handleSeekMouseDown: () => void;
  handleSeekChange: (value: number) => void;
  handleSeekMouseUp: (value: number) => void;
}

export default function BarPlayed({
  played,
  handleSeekMouseDown,
  handleSeekChange,
  handleSeekMouseUp,
}: BarPlayedProps) {
  const { actions, getInnerTrackProps, getInputProps, getRootProps, getThumbProps, getTrackProps } = useSlider({
    min: 0,
    max: 1,
    step: 0.01,
    value: played,
    onChange: handleSeekChange,
    onChangeEnd: handleSeekMouseUp,
    onChangeStart: handleSeekMouseDown,
  });

  const { onKeyDown: onThumbKeyDown = () => {}, ...thumbProps } = getThumbProps();

  return (
    <chakra.div cursor="pointer" w="full" {...getRootProps()}>
      <input {...getInputProps()} hidden />

      <Box h="10px" bgColor="brand.100" {...getTrackProps()}>
        <Box h="full" bgColor="brand.500" {...getInnerTrackProps()} />
      </Box>
      <Box
        top="1%"
        bgColor="white"
        boxShadow="lg"
        _focusVisible={{
          outline: 'none',
        }}
        onKeyDown={(e) => {
          if (e.code === 'ArrowRight') actions.stepUp(1);
          else if (e.code === 'ArrowLeft') actions.stepDown(1);
          else onThumbKeyDown(e);
        }}
        {...thumbProps}
      />
    </chakra.div>
  );
}
