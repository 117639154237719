import config from '../../../../../../config';
import Select from '../../../../../molecules/Form/components/Select';

const CompanySelect = () => (
  <Select
    label="Empresa a la que perteneces"
    name="company"
    options={config.companyTypes}
    validations={[{ key: 'required', val: true }]}
  />
);

export default CompanySelect;
