import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    border: 'none',
    outline: 'none',
  },
  // Two sizes: sm and md
  sizes: {},
  variants: {},
  // The default size and variant values
  defaultProps: {
    colorScheme: 'brand',
  },
};
