import { useEffect, useState } from 'react';
import { apiCall } from '../../../../crud/api.crud';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { invercionMatriz } from '../../../../utils/helper';
import config from 'config';
import Loading from '../../../molecules/Loading';
import NewsCard from './components/CommunityCard';
import ModalNewCard from './components/CommunityModal';
import AboutGlobanteers from './components/AboutGlobanteers';
import { useTranslation } from 'react-i18next';

export type News = {
  entity_id: string | number;
  id: string | number;
  long_description: string;
  photo_path: string;
  short_description: string;
  title: string;
  user_id: string | number;
  created_at: string;
};

const Community = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataNew, setDataNew] = useState<null | News[]>(null);

  useEffect(() => {
    apiCall(`community`, null, 'GET').then(({ data }) => setDataNew(data.data));
  }, [showModal]);

  const colums = useBreakpoint() == 1 ? 1 : 2;
  if (!dataNew) return <Loading />;
  const newsColumns = invercionMatriz(dataNew, colums);

  return (
    <div>
      {config.profile_globant && <AboutGlobanteers />}
      <div className="container mt-4 shadow-sm bg-white p-5 rounded">
        <div className="w-100 d-flex justify-content-between ">
          <h2 className="text-dark font-weight-bold">{t('community.community_msg')}</h2>
          <button className="btn btn-link font-weight-bold text-primary" onClick={() => setShowModal(true)}>
            {t('community.community_btn')}
          </button>
        </div>
        <div className="card__container">
          {newsColumns.map((newsColumn, index) => (
            <div className="card__container__columns" key={`cardActivityColumn${index}`}>
              {newsColumn.map((data) => (
                <NewsCard data={data} key={`new${data.title}`} />
              ))}
            </div>
          ))}
        </div>
        {showModal && <ModalNewCard setShowModal={setShowModal} />}
      </div>
    </div>
  );
};

export default Community;
