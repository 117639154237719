export const ID_TYPES = [
  { label: 'DNI', value: 'DNI' },
  { label: 'LE', value: 'LE' },
  { label: 'PASAPORTE', value: 'PASAPORTE' },
  { label: 'CI', value: 'CI' },
  { label: 'CURP', value: 'CURP' },
  { label: 'DPI/DUI', value: 'DPI/DUI' },
  { label: "Carte nationale d'identité", value: "Carte nationale d'identité" },
];

export const isArcos = Number(process.env.REACT_APP_ID_ENTITY) === 8399;
export const isBisblick = Number(process.env.REACT_APP_ID_ENTITY) === 3885;
export const isEmpujar = Number(process.env.REACT_APP_ID_ENTITY) === 633;
export const isOsmia = Number(process.env.REACT_APP_ID_ENTITY) === 4755;
export const isAquiEstoy = Number(process.env.REACT_APP_ID_ENTITY) === 6341;
export const isGlobant = Number(process.env.REACT_APP_ID_ENTITY) === 71;
export const isAlmaCivica = Number(process.env.REACT_APP_ID_ENTITY) === 8601;

export const AUTO_REGISTER = [isArcos, isAlmaCivica, isAquiEstoy];
