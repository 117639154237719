import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Form from '../applyModal/Forms';
const StepOne = ({
  commentary,
  setCommentary,
  participated,
  setParticipated,
  closeModal,
  setStep,
  survey,
  setAnswers,
}) => {
  const { t } = useTranslation();

  const [count, setCount] = useState(180);

  const onChange = (e) => {
    const value = e.target.value;
    setCommentary(value);
    if (180 - value.length >= 0) setCount(180 - value.length);
    else {
      setCommentary(value.slice(0, 180));
      setCount(0);
    }
  };

  const onChangeSelect = (res) => {
    setParticipated(res);
    console.log(participated);
  };

  const selectOptions = [
    { label: t('home.apply_msg4'), value: true },
    { label: t('home.apply_msg5'), value: false },
  ];

  return (
    <>
      <div className="modal-body">
        <div className="form-group">
          <label htmlFor="message-text" className="col-form-label">
            {t('home.apply_msg2')} {count}/180:
          </label>
          <textarea
            style={{ border: commentary.length ? '' : '1px solid #dc3545' }}
            className="form-control"
            id="message-text"
            value={commentary}
            onChange={onChange}
          />
          <label htmlFor="message-text" className="col-form-label mt-3">
            {t('home.apply_msg3')}
          </label>
          <Select
            menuPortalTarget={document.getElementById('root')}
            options={selectOptions}
            onChange={(res) => onChangeSelect(res)}
            className="mb-3"
          ></Select>
          {survey &&
            survey.fields.map((form) => <Form key={`commentary${form.id}`} form={form} setAnswers={setAnswers} />)}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeModal} data-dismiss="modal">
          {t('button.button_msg8')}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!commentary.length}
          onClick={() => {
            setStep(1);
          }}
        >
          {t('button.button_msg2')}
        </button>
      </div>
    </>
  );
};

export default StepOne;
