import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOsmia } from 'utils/contants';
import { ButtonPrimary } from '../../../../molecules/Button';
import LoginModal from '../../../LoginModal';

const LoginButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const { t } = useTranslation();
  return (
    <>
      <ButtonPrimary onClick={openModal} type="button" className="button-reactour">
        {isOsmia ? t('button.register') : t('button.apply')}
      </ButtonPrimary>
      <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
export default LoginButton;
