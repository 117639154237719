import { useState } from 'react';
import Tour from 'reactour';
import config from '../../../../config';
import { apiCall } from '../../../../crud/api.crud';
import useGetUserId from '../../../../hooks/api/useGetUserId';
import useStore from '../../../../store/zustand/store';

const Reactour = ({ steps, checked, section }) => {
  // trae el valor del estado del tour, (lectura)
  const showTour = useStore((state) => state.user?.api_tour);
  const checkedTour = useStore((state) => state.checkedTour);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const user_id = useGetUserId();

  const close = () => {
    setIsTourOpen(false);

    // actualización del estado del tour
    if (checked) {
      const api_tour = `${showTour},${section}`;
      apiCall('checkUserTour', { user_id, api_tour }, 'POST');
      checkedTour(api_tour);
    }
  };

  // si el estado es verdadero el tour ya no se va a mostrar
  if ((showTour && showTour.search(section) != -1) || !config.has_tour) return null;

  return <Tour steps={steps} isOpen={isTourOpen} onRequestClose={close} />;
};

export default Reactour;
