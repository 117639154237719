import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import nsAr from './es-AR/index';
import nsMX from './es-MX/index';
import nsGlb from './es-GLB/index';
import en from './en/index';
import br from './br/index';
import fr from './fr/index';
import config from '../config';

export const resources = {
  'es-AR': {
    translation: nsAr,
  },
  'es-MX': {
    translation: nsMX,
  },
  es: {
    translation: nsGlb,
  },
  en: {
    translation: en,
  },
  br: {
    translation: br,
  },
  fr: {
    translation: fr,
  },
} as const;

const navigatorLng =
  Object.keys(resources).find((lng) => lng === navigator.language) ||
  (navigator.language.slice(0, 2) === 'es' && config.idiom);

const fixLng = navigatorLng || config.idiom;

const lng = localStorage.getItem('i18nextLng') || fixLng;

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  lng,
  resources,
});
