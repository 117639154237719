import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';

import config from 'config';
import { useMemo } from 'react';
import { NextLesson } from 'modules/classroom/components/NextLesson';
import { useTranslation } from 'react-i18next';

interface QuizResultProps {
  score: number;
  onReset?: () => void;
}

export function QuizResult({ score, onReset }: QuizResultProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const isApproved = useMemo(() => score >= Number(config.classroom?.min_approved_score), [score]);

  return (
    <Center minH="75vh">
      <Stack spacing={8} textAlign="center" align="center">
        <Heading size="2xl">{t(`quiz.${isApproved ? 'approved' : 'disapproved'}.title`)}</Heading>
        <Text fontWeight="semibold" fontSize="xl">
          {t(`quiz.${isApproved ? 'approved' : 'disapproved'}.description`)}
        </Text>
        <Box py={12}>
          <Text fontWeight="semibold" fontSize="3xl">
            {t('result')}:
            <Text as="span" color={isApproved ? 'green.500' : 'red.500'} ml={2}>
              {score.toFixed()}%
            </Text>
          </Text>
        </Box>

        {!isApproved && onReset && <Button onClick={onReset}>{t('quiz.replay')}</Button>}
        {isApproved && <NextLesson>{t('quiz.next')}</NextLesson>}
      </Stack>
    </Center>
  );
}
