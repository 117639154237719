import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { actions as userActivitiesAction } from '../../../../store/ducks/user/activities.duck';

import useGetUserId from '../../../../hooks/api/useGetUserId';

import { ActivitiesData, ActivityParse, Filter } from '../../../../interfaces/activity';
import ActivitiesReactour from '../../../main/components/Reactour/ActivitiesReactour';
import Loading from '../../../molecules/Loading';
import ActivityFilter from './components/ActivityFilter';
import ActivityModal from './components/ActivityModal';
import ActivityTable from './components/ActivityTable';
import { SimplePagination } from 'components/SimplePagination';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr;
  header {
    padding: 0;
  }
  @media (min-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
    .data-table-activities {
      grid-column: 1 / 4;
    }
  }
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface props {
  isOpenTour: boolean;
}

interface state {
  userActivities: Activities;
  data: any[];
}
interface userActivities {
  userActivities: ActivitiesData;
  userActivitiesLoading: boolean;
}

interface Activities {
  activities: ActivitiesData;
  loading: boolean;
}
const Activities = ({ isOpenTour }: props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [activityData, setActivityData] = useState<ActivityParse | null>(null);
  const query = useQuery();

  const filterStorage = localStorage.getItem('activity_filter');
  const initialFilter: Filter =
    typeof filterStorage === 'string'
      ? JSON.parse(filterStorage)
      : {
          actions: query.get('actions') || null,
          status: query.get('status'),
          status_postulation: query.get('status_postulation'),
        };

  const [filter, setFilter] = useState<Filter>(initialFilter);
  const dispatch = useDispatch();

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const user_id = useGetUserId();
  const { userActivities, userActivitiesLoading }: userActivities = useSelector((state: state) => ({
    userActivities: state.userActivities.activities,
    userActivitiesLoading: state.userActivities.loading,
  }));

  useEffect(() => {
    dispatch(userActivitiesAction.getActivities(user_id, page));
  }, [dispatch, user_id, page]);
  if (userActivitiesLoading) {
    return <Loading />;
  }

  if (userActivities) {
    let dataAux = userActivities.data
      .filter((activity) => !filter.status || activity.status == filter.status)
      .filter((activity) => !filter.status_postulation || activity.status_postulation == filter.status_postulation)
      .filter((activity) => !filter.actions || activity.status_postulation == 2);

    const data: ActivityParse[] = dataAux.map((a) => {
      const hours_individual = Math.floor(a.minutesIndividual / 60) + a.hoursIndividual; // Obtener las horas completas
      const minutes_individual = a.minutesIndividual % 60;

      return {
        id: a.id,
        status: t(`activities.status.${a.status}`),
        activity: a.title,
        description: a.short_description,
        deadline: a.deadline.slice(0, 10),
        selection_status: t(`activities.status_postulation.${a.status_postulation}`),
        url: `/detail/${a.id}`,
        from_entity: a.fromEntity?.bussiness_name,
        to_entity: a.toEntity?.bussiness_name,
        hours_total: a.hoursTotal,
        hours_individual,
        minutes_individual,
        sdg: a.sdg_id,
        commentary: a.commentary,
        entity_to: a.fromEntity?.bussiness_name,
        entity_from: a.toEntity?.bussiness_name,
        survey: a.survey,
        estimated_hours: a.estimated_hours,
        automate_hours_calculation: a.automate_hours_calculation,
        status_postulation: a.status_postulation,
      };
    });

    const handleClick = (id: number) => {
      const activity: ActivityParse | null = data.find((activity) => activity.id === id) || null;
      if (activity) {
        setActivityData(activity);
        openModal();
        return;
      }
      console.warn('there is no activity with that id');
    };

    return (
      <div className="container mt-4 p-0">
        {isOpenTour && <ActivitiesReactour />}
        <div className="card shadow  bg-white rounded p-2">
          <h2 className="mt-2 mb-3">{t('activities.activities_msg')}</h2>
          <div className="card-block">
            <Content>
              <ActivityFilter filter={filter} setFilter={setFilter} />
              <ActivityTable data={data} handleClick={handleClick} filter={filter} setFilter={setFilter} />
            </Content>
            <div className="py-4">
              <SimplePagination total={data.length} setPage={setPage} page={page} />
            </div>
          </div>
        </div>
        <ActivityModal activityData={activityData} closeModal={closeModal} isOpen={isOpen} />
      </div>
    );
  }
  return null;
};

export default Activities;
