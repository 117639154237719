import { ButtonPrimary } from 'components/molecules/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const GoActivityButton = ({ activity }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/detail/${activity?.id}`} className="button-reactour">
      <ButtonPrimary>{t('button.go_activity')}</ButtonPrimary>
    </Link>
  );
};

export default GoActivityButton;
