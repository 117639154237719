import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { SimplePagination } from 'components/SimplePagination';
import { ActivityRoom } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { VscListFilter } from 'react-icons/vsc';
import { useSocketContext } from '../../../context/SocketContext';
import { SidebartItem } from './SidebartItem';

export function Sidebar({ activities }: { activities: ActivityRoom[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { page, setPage } = useSocketContext();
  const { t } = useTranslation('translation', { keyPrefix: 'activities' });

  return (
    <>
      <HStack display={{ base: 'flex', lg: 'none' }} w="100%" justify="space-between">
        <Heading size="lg">Chat</Heading>
        <Button onClick={onOpen} size="sm" gap={2}>
          <VscListFilter size={18} />
          {t('activities_msg5')}
        </Button>
      </HStack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('activities_msg5')}</DrawerHeader>

          <DrawerBody>
            {activities?.map((activity) => (
              <SidebartItem key={activity.id} activity={activity} />
            ))}
            {activities.length > 30 && <SimplePagination total={activities.length} page={page} setPage={setPage} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
