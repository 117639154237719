import styled from 'styled-components';
import config from '../../../../../config';

import Idiom from '../../../../organisms/Navbar/components/Idiom';
import './words.css';

const Img = styled.img`
  width: ${(props) => props.width || '300px'};
  margin-bottom: 3rem;
  display: block;
`;

const Globant = () => {
  return (
    <>
      {config.auth.logo_extra && <Img src={config.auth.logo_extra} width={150} alt="logo extra" />}
      <div className="words">
        <span>Bienvenidos</span>
        <span>Welcome</span>
        <span>Bem-vindo</span>
        <span>Welcome</span>
        <span>Bienvenidos</span>
        <span>Bem-vindo</span>
      </div>
      <div className="d-flex justify-content-around w-100">
        <a href="https://app.fonselp.com/saml/globant/login" className="button mt-3 mb-5">
          Log in
        </a>
      </div>

      <div className="d-flex justify-content-between w-100 align-items-center">
        <Idiom />
      </div>
    </>
  );
};
export default Globant;
