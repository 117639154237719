import { Heading, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function EmptyGuide() {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  return (
    <Stack h="80vh" align="center" justify="center">
      <Heading>{t('not_found.title')}</Heading>
      <Text>{t('not_found.description')}</Text>
    </Stack>
  );
}
