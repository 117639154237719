import { useEffect, useState } from 'react';
import { apiCall } from '../../../../../../crud/api.crud';
import useStore from '../../../../../../store/zustand/store';

const useChageProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [change, setChange] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const updateUser = useStore((state) => state.updateUser);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setChange(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [change]);

  const update = async (payload) => {
    setIsLoading(true);
    setBirthdateError(false);
    try {
      const response = await apiCall('profile', payload, 'POST');
      updateUser(response.data.data[0]);
      setChange(true);
    } catch (e) {
      setError(true);
    }
    setIsLoading(false);
  };

  return { change, isLoading, error, update, birthdateError, setBirthdateError };
};

export default useChageProfile;
