import { Text } from '@chakra-ui/react';

const pad = (string: number) => `0${string}`.slice(-2);

const format = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

interface DurationProps {
  seconds: number;
}

export default function Duration({ seconds }: DurationProps) {
  return (
    <Text as="time" dateTime={`P${Math.round(seconds)}S`} variant="unstyled" color="white">
      {format(seconds)}
    </Text>
  );
}
