import { useState } from 'react';
import Globant from './Globant';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const Login = () => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');

  const stepComponent = [
    <StepOne key={`stepOne-${step}`} next={setStep} email={email} setEmail={setEmail} />,
    <StepTwo key={`stepTwo-${step}`} previous={setStep} email={email} />,
  ];

  if (process.env.REACT_APP_ID_ENTITY == 71) {
    return <Globant />;
  }
  return stepComponent[step];
};

export default Login;
