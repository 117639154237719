import { useState } from 'react';
import styled from 'styled-components';
import { LuCircleDot } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  background-color: #fff;
  border: 1px solid #bfbfbf;
  padding: 20px;
  cursor: pointer;
  border-radius: 20px;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  padding: 10px;
  padding-left: 40px;
`;

const InitiativeTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InitiativeTitle = styled.h2`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  padding-left: 5px;
  margin: 0;
`;

const ActiveSection = styled.div`
  font-size: 32px;
  line-height: 24px;
  font-weight: 400;
`;

const ReadMore = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
  color: #656565;
`;

const StyledA = styled.a`
  text-decoration: underline;
  font-weight: 700;
`;

const AboutGlobanteers = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (index) => {
    if (activeSection === index) {
      setActiveSection(null);
    } else {
      setActiveSection(index);
    }
  };

  return (
    <div className="container">
      <div className="w-100">
        <h2 className="text-dark font-weight-bold">{t('community.title')}</h2>
        <p className="py-3 text-16 line-height-24">
          {t('community.community_desc')} <strong>{t('community.community_desc_strong')}</strong>
          <br />
          <br />
          {t('community.community_desc2')}
          <strong>{t('community.community_desc_strong2')}</strong>
          {t('community.community_desc2_1')} 
          <br />
          <br />
          {t('community.community_desc3')}
          <br />
          <br />
          {t('community.community_desc4')}
          <strong>{t('community.community_desc_strong4')}</strong>
          {t('community.community_desc4_1')}
        </p>
      </div>
      <AccordionWrapper>
        <Section onClick={() => toggleSection(0)}>
          <SectionTitleContainer>
            <InitiativeTitleContainer>
              <LuCircleDot size={35} style={{ color: '#BED731', marginRight: '8px' }} />
              <InitiativeTitle>{t('community.initiatives.initiative1.title')}</InitiativeTitle>
            </InitiativeTitleContainer>
            <ActiveSection>{activeSection === 0 ? '-' : '+'}</ActiveSection>
          </SectionTitleContainer>
          <Content expanded={activeSection === 0}>
            <p>{t('community.initiatives.initiative1.description')}</p>
            <ReadMore>
              {t('community.initiatives.initiative1.read_more')}{' '}
              <StyledA
                href="https://stayrelevant.globant.com/en/be-kind/be-kind-humanity/moving-together-by-globant/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more2')}{' '}
              <StyledA
                href="https://www.globant.com/es/programa-code-your-future-construye-paz"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link2')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more2')}{' '}
              <StyledA
                href="https://stayrelevant.globant.com/en/technology/sustainable-business/show-faith-sena-students/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link3')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more4')}{' '}
              <StyledA
                href="https://www.youtube.com/watch?v=eaciZcEQX8g&t=6s"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link4')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more5')}{' '}
              <StyledA href="https://www.youtube.com/watch?v=gMKf6pkY7F8" target="_blank" rel="noopener noreferrer">
                {t('community.initiatives.initiative1.link5')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more6')}{' '}
              <StyledA
                href="https://investors.globant.com/2022-12-22-Globant-and-NPower-Grow-Partnership-to-Support-Veterans-and-Other-Underrepresented-Groups-in-Technology-Nationwide"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link6')}
              </StyledA>
              {t('community.initiatives.initiative1.read_more6')}{' '}
              <StyledA
                href="https://www.globant.com/news/globant-joins-tent-partnership-refugees-accelerate-economic-integration-refugees-worldwide"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative1.link7')}
              </StyledA>
              .
            </ReadMore>
          </Content>
        </Section>
        <Section onClick={() => toggleSection(1)}>
          <SectionTitleContainer>
            <InitiativeTitleContainer>
              <LuCircleDot size={35} style={{ color: '#BED731', marginRight: '8px' }} />
              <InitiativeTitle>{t('community.initiatives.initiative2.title')}</InitiativeTitle>
            </InitiativeTitleContainer>
            <ActiveSection>{activeSection === 1 ? '-' : '+'}</ActiveSection>
          </SectionTitleContainer>
          <Content expanded={activeSection === 1}>
            <p>{t('community.initiatives.initiative2.description')}</p>
            <ReadMore>
              {t('community.initiatives.initiative2.read_more')}{' '}
              <StyledA href="https://theinspiregarage.com/" target="_blank" rel="noopener noreferrer">
                {t('community.initiatives.initiative2.link')}
              </StyledA>
              ,{' '}
              <StyledA href="https://www.youtube.com/watch?v=DMOxQx6wYXU" target="_blank" rel="noopener noreferrer">
                {t('community.initiatives.initiative2.link2')}
              </StyledA>
              {t('community.initiatives.initiative2.read_more2')}{' '}
              <StyledA
                href="https://drive.google.com/file/d/14xZkUugNSB_HXQkQkN58HLwf49QR4KQS/view?resourcekey"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('community.initiatives.initiative2.link3')}
              </StyledA>
              .
            </ReadMore>
          </Content>
        </Section>
        <Section onClick={() => toggleSection(2)}>
          <SectionTitleContainer>
            <InitiativeTitleContainer>
              <LuCircleDot size={35} style={{ color: '#BED731', marginRight: '8px' }} />
              <InitiativeTitle>{t('community.initiatives.initiative3.title')}</InitiativeTitle>
            </InitiativeTitleContainer>
            <ActiveSection>{activeSection === 2 ? '-' : '+'}</ActiveSection>
          </SectionTitleContainer>
          <Content expanded={activeSection === 2}>
            <p>{t('community.initiatives.initiative3.description')}</p>
            <ReadMore>
              {t('community.initiatives.initiative3.read_more')}{' '}
              <StyledA href="http://Labs.globant.com" target="_blank" rel="noopener noreferrer">
                {t('community.initiatives.initiative3.link')}
              </StyledA>
              {t('community.initiatives.initiative3.read_more2')}
            </ReadMore>
          </Content>
        </Section>
        <Section onClick={() => toggleSection(3)}>
          <SectionTitleContainer>
            <InitiativeTitleContainer>
              <LuCircleDot size={35} style={{ color: '#BED731', marginRight: '8px' }} />
              <InitiativeTitle>{t('community.initiatives.initiative4.title')}</InitiativeTitle>
            </InitiativeTitleContainer>
            <ActiveSection>{activeSection === 3 ? '-' : '+'}</ActiveSection>
          </SectionTitleContainer>
          <Content expanded={activeSection === 3}>{t('community.initiatives.initiative4.description')}</Content>
        </Section>
        <Section onClick={() => toggleSection(4)}>
          <SectionTitleContainer>
            <InitiativeTitleContainer>
              <LuCircleDot size={35} style={{ color: '#BED731', marginRight: '8px' }} />
              <InitiativeTitle>{t('community.initiatives.initiative5.title')}</InitiativeTitle>
            </InitiativeTitleContainer>
            <ActiveSection>{activeSection === 4 ? '-' : '+'}</ActiveSection>
          </SectionTitleContainer>
          <Content expanded={activeSection === 4}>{t('community.initiatives.initiative5.description')}</Content>
        </Section>
      </AccordionWrapper>
    </div>
  );
};

export default AboutGlobanteers;
