import { BsChatLeftText } from 'react-icons/bs';
import { MdClose, MdMenu } from 'react-icons/md';
import { Link, NavLink } from 'react-router-dom';
import { AuthMenu } from './AuthMenu';
import Idiom from './Idiom';
import Notification from './Notification';
import styled from 'styled-components';

import '../navbar.scss';
const Img = styled.img`
  width: ${(props) => props.width || '120px'};
`;

const Incubate = ({ isOpen, onToggle, t, messages, user, config }) => {
  return (
    <nav className="navbar-app navbar-expand-lg navbar-light bg-light p-2">
      <div className={`collapse navbar-collapse mr-auto ${isOpen && 'show'} md-show  bg-light`}>
        <ul className="navbar-nav flex-lg-row mr-auto  p-4 p-lg-2">
          {/* Public Links */}
          <li className="nav-item my-lg-0">
            <NavLink exact activeClassName="selected inicio-reactour" className="newStyle" to="/">
              {t('header.header_msg')}
            </NavLink>
          </li>
          {/* Authenticated links */}
          {user && (
            <>
              <li className="nav-item my-2 my-lg-0">
                <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/horas">
                  {t('header.header_msg3')}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/community">
                  {t('header.header_msg8')}
                </NavLink>
              </li>
            </>
          )}

          <li className="nav-item">
            <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/talents">
              {t('header.talents')}
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink activeClassName="selected" className="act-reactour newStyle" to="/actividad">
              {t('header.header_msg2')}
            </NavLink>
          </li>
          <li className="nav-item d-lg-none">{config.translation && <Idiom />}</li>
        </ul>
      </div>
      {/* Logo */}
      <div className="logo navbar-brand m-auto">
        <Link to="/">
          {config.alternative_logo ? (
            config.alternative_logo.title
          ) : (
            <Img
              src={config.logo}
              width={config.logo_width}
              className="d-inline-block align-top logo-reactour"
              alt="logo"
            />
          )}
        </Link>
      </div>
      <ul className="navbar-nav  ml-auto  flex-row align-items-center">
        {/* Chat link */}
        {user && (
          <>
            <li className="nav-item">
              <Link to="/chat" className="navbar-brand p-0">
                <button type="button" className="btn btn-ghost btn-sm p-0">
                  <BsChatLeftText size={18} />
                  {!!messages.length && (
                    <>
                      <span className="badge badge-primary">{messages.length}</span>
                      <span className="sr-only">mensajes sin leer</span>
                    </>
                  )}
                </button>
              </Link>
            </li>
            <li className="nav-item flex">
              <Notification />
            </li>
          </>
        )}
        <li className="nav-item">
          <AuthMenu />
        </li>
        <li className="nav-item d-none d-lg-block">{config.translation && <Idiom />}</li>
        {/* Responsive Menu */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-label="Toggle navigation"
          onClick={onToggle}
        >
          {isOpen ? <MdClose /> : <MdMenu />}
        </button>
      </ul>
    </nav>
  );
};

export default Incubate;
