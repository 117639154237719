import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import config from '../../config';
import './auth.scss';

import Login from '../pages/Public/Login';
import MagicLink from '../pages/Public/MagicLink';
import Register from '../pages/Public/Register/index';
import ResetPass from '../pages/Public/ResetPass';
import ResetUser from '../pages/Public/ResetUser';

const Img = styled.img`
  width: ${(props) => props.width || '300px'};
  margin-bottom: 3rem;
  display: block;
`;

const AlternativeLogo = () => (
  <div className="alternativeLogo">
    <Img src={config.alternative_logo.img1} width={config.logo_width_auth} alt="logo" />
    {/* <h1>{config.alternative_logo.title}</h1>
    <div className="alternativeLogo-logo">
      <Img src={config.alternative_logo.img1} className="alternativeLogo-logo-img" />
      <Img src={config.alternative_logo.img2} className="alternativeLogo-logo-img" />
    </div> */}
  </div>
);

const Auth = () => (
  <div className="row body">
    <div className="col-12 col-md-6">
      <div className="login">
        {config.alternative_logo ? (
          <AlternativeLogo />
        ) : (
          <Img src={config.logo} width={config.logo_width_auth} alt="logo" />
        )}

        <Switch>
          <Route exact path="/reset" component={ResetUser} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/password/reset/:id" component={ResetPass} />
          <Route exact path="/authentication/:id" component={MagicLink} />
          <Route path="/" component={Login} />
        </Switch>
        {config.logo_login && <Img className="mt-5" src={config.logo_login} alt="logo" />}
      </div>
    </div>
    <div className="col-6 bg-primary" />
  </div>
);

export default Auth;
